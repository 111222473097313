import PropTypes from "prop-types";
// material-ui
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// project import
import NavItem from "./NavItem";
import { useGetMenuMaster } from "../../../../../api/menu";
import { useSelector } from "react-redux";
import CollapseNavItem from "./CollapseNavItem";
import { useEffect, useState } from "react";

export default function NavGroup({ item }) {
  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;
  const [openCollapseId, setOpenCollapseId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(null);

  const login = useSelector((state) => state?.login || []);

  const userRole = login.data ? login.data.role?.role?.toLowerCase() : "";

  const userRoleRouters = useSelector((state) => state.userRoleRouters.route);

  const navCollapse = item.children?.map((menuItem, index) => {
    switch (menuItem.type) {
      case "collapse":
        return (
          <CollapseNavItem
            key={index}
            setOpenCollapseId={setOpenCollapseId}
            openCollapseId={openCollapseId}
            item={menuItem}
            level={1}
            userRoleRouters={userRoleRouters}
            userRole={userRole}
            open={open}
            setOpen={setOpen}
            openDropDown={openDropDown}
            setOpenDropDown={setOpenDropDown}
          />
        );
      case "item":
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={index} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  const [componentData, setComponentData] = useState(navCollapse);
  useEffect(() => {
    setComponentData(navCollapse);
  }, [open]);

  return (
    <>
      {userRoleRouters?.some((userNav) => userNav?.routeGroup == item?.id) && (
        <List
          subheader={
            item.title &&
            drawerOpen && (
              <Box sx={{ pl: 3, mb: 1.5 }}>
                {/* <Typography variant="subtitle2" color="textSecondary">
                  {item.title}
                </Typography> */}
              </Box>
            )
          }
          sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
        >
          {componentData}
        </List>
      )}
    </>
  );
}

NavGroup.propTypes = { item: PropTypes.object };

import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Slide,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  OrganizationCaseDetailsAction,
  allCaseDetailsAction,
  allCaseTypeAction,
  caseDetailsBulkDeleteAction,
  filterColumnAction,
} from "../../../redux/actions/Case/caseAction";
import { MdOutlineBackupTable } from "react-icons/md";
import { animateCss } from "../../TosterAlert";
import { UpdateCaseFilterAction } from "../../../redux/actions/UserAction/usersAction";
import { allOrganizationAction } from "../../../redux/actions/OrganizationAction/OrganizationAction";
import { Typography } from "antd";
import { FaRegFilePdf } from "react-icons/fa";
import { BsFiletypeCsv } from "react-icons/bs";
import { CiCircleInfo } from "react-icons/ci";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { alpha } from "@material-ui/core";
import { exportWordAction } from "../../../redux/actions/hooks/exportDocumentAction";
import { exportCSV, exportPDF, exportXLSX } from "../../../helpers/helperTool";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import {
  getOrganizationDocumentAction,
  organizationDocumentBulkDeleteAction,
} from "../../../redux/actions/OrganizationAction/organizationDocumentAction";
import { bulkCaseUpdateReducer } from "../../../redux/slices/Case/caseSlice";
import { format } from "date-fns";

const tableStyle = {
  // WebkitAlignItems: "flex-start",
  // WebkitBoxAlign: "flex-start",
  // MsFlexAlign: "flex-start",
  alignItems: "flex-start",
  // boxSizing: "border-box",
  // display: "-webkit-box",
  // display: "-webkit-flex",
  // display: "-ms-flexbox",
  display: "flex",
  // gap: "0.5rem",
  // WebkitBoxPack: "justify",
  // WebkitJustifyContent: "space-between",
  // justifyContent: "space-between",
  // padding: "0.5rem",
  // position: "relative",
  // right: 0,
  // top: 0,
  // width: "100%",
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrganizationCaseDetails = () => {
  const [newData, setNewData] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filterOrganizationName, setFilterOrganizationName] = useState();
  const navigate = useNavigate();
  const [filterCaseType, setFilterCaseType] = useState();
  const [columnFilters, setColumnFilters] = useState([]);
  const [rowSelection, setRowSelection] = useState(true);

  const user = useSelector((state) => state.login.data);

  const casefilter = useSelector((state) => state?.case?.caseFilter) ?? {};

  const columnArrange =
    useSelector((state) => state?.case?.columnArrange) ?? [];

  const [columnVisibility, setColumnVisibility] = useState(casefilter);

  const dispatch = useDispatch();

  const AllotmentCase = useSelector((state) => state.case.caseDetails.allData);

  const isLoading = useSelector((state) => state.case.loading);
  const organizationName = useSelector((state) => state?.organization?.profile);
  const caseType = useSelector((state) => state?.case?.settings);
  const columnHelper = createMRTColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor("CaseNect Number", {
        header: "CaseNect Number",
        filterVariant: "autocomplete",
        Cell: ({ row }) => {
          return (
            <Link
              to={
                // window?.edit
                //   ?
                `/case/caseDetails/${row.original?.["CaseNect Number"]}`
                // : `/case/caseDetails/`
              }
              onClick={() => {
                if (!window?.edit) {
                  animateCss("info", "You are not authorized to update");
                } else {
                  setNewData(row.original);
                }
              }}
              style={{ textDecoration: "none" }}
            >
              {row.original?.["CaseNect Number"]}
            </Link>
          );
        },
      }),
      columnHelper.accessor("Case Name", {
        header: "Case Name",
        size: 200,
      }),
      columnHelper.accessor("Case Number", {
        header: "Case Number",
        size: 200,
      }),

      columnHelper.accessor("Organization Name", {
        header: "Organization Name",
      }),

      columnHelper.accessor("Case Status", {
        header: "Case Status",
      }),
      columnHelper.accessor("Case Opened On", {
        header: "Case Opened On",
      }),
      columnHelper.accessor("Case Closed On", {
        header: "Case Closed On",
      }),
      columnHelper.accessor("Case Closing Reason", {
        header: "Case Closing Reason",
      }),
      columnHelper.accessor("Case Closing Description", {
        header: "Case Closing Description",
      }),

      columnHelper.accessor("Priority Level", {
        header: "Priority Level",
      }),
      columnHelper.accessor("Case Manager", {
        header: "Case Manager",
      }),
      columnHelper.accessor("Case Administrator", {
        header: "Case Administrator",
      }),

      columnHelper.accessor("Agreement/Contract/Account Number", {
        header: "Agreement/Contract/Account Number",
        size: 280,
      }),

      columnHelper.accessor("Plaintiff /Claimant / Petitioner / Appellant", {
        header: "Plaintiff /Claimant / Petitioner / Appellant",
        size: 300,
      }),
      columnHelper.accessor(
        "Legal Representative of Plaintiff /Claimant / Petitioner / Appellant",
        {
          header:
            "Legal Representative of Plaintiff /Claimant / Petitioner / Appellant",
          size: 350,
        }
      ),
      columnHelper.accessor("Counsel for Defendant / Respondent", {
        header: "Counsel for Defendant / Respondent",
        size: 300,
      }),

      columnHelper.accessor("Vakalat by Claimant Counsel Filed", {
        header: "Vakalat by Claimant Counsel Filed",
        size: 300,
      }),
      columnHelper.accessor("Vakalat By Claimant Counsel Filed On", {
        header: "Vakalat By Claimant Counsel Filed On",
        size: 340,
      }),
      columnHelper.accessor("Defendant / Respondent", {
        header: "Defendant / Respondent",
        size: 280,
      }),
      columnHelper.accessor("Legal Representative of Defendant / Respondent", {
        header: "Legal Representative of Defendant / Respondent",
        size: 280,
      }),
      columnHelper.accessor(
        "Counsel for Plaintiff /Claimant / Petitioner / Appellant",
        {
          header: "Counsel for Plaintiff /Claimant / Petitioner / Appellant",
          size: 350,
        }
      ),
      columnHelper.accessor("Vakalat by Respondent Counsel Filed", {
        header: "Vakalat by Respondent Counsel Filed",
        size: 300,
      }),
      columnHelper.accessor("Vakalat by Respondent Counsel Filed On", {
        header: "Vakalat by Respondent Counsel Filed On",
        size: 300,
      }),

      columnHelper.accessor("Practice Area", {
        header: "Practice Area",
      }),
      columnHelper.accessor("Case Type", {
        header: "Case Type",
      }),

      columnHelper.accessor("Arbitration Status", {
        header: "Arbitration Status",
      }),

      columnHelper.accessor("Case Sub Type", {
        header: "Case Sub Type",
      }),
      columnHelper.accessor("Previous Stage", {
        header: "Previous Stage",
      }),
      columnHelper.accessor("Current Stage", {
        header: "Current Stage",
      }),
      columnHelper.accessor("Next Stage", {
        header: "Next Stage",
      }),
      columnHelper.accessor("Previous Hearing Date", {
        header: "Previous Hearing Date",
      }),

      columnHelper.accessor("Next Hearing Date", {
        header: "Next Hearing Date",
      }),
      columnHelper.accessor("Court/Forum", {
        header: "Court/Forum",
      }),
      columnHelper.accessor("Jurisdiction Type", {
        header: "Jurisdiction Type",
      }),
      columnHelper.accessor("Dispute Value", {
        header: "Dispute Value",
      }),
      columnHelper.accessor("Cause of Action On", {
        header: "Cause of Action On",
      }),
      columnHelper.accessor(
        "Is the dispute/claim within the Statute of Limitations?",
        {
          header: "Is the dispute/claim within the Statute of Limitations?",
          size: 400,
        }
      ),

      columnHelper.accessor("Case Description", {
        header: "Case Description",
      }),
      columnHelper.accessor("Mode of Communications", {
        header: "Mode of Communications",
      }),
      columnHelper.accessor("City / Taluk / Village", {
        header: "City / Taluk / Village",
      }),
      columnHelper.accessor("District", {
        header: "District",
      }),
      columnHelper.accessor("State", {
        header: "State",
      }),
      columnHelper.accessor("Country", {
        header: "Country",
      }),
      columnHelper.accessor("Case Contested", {
        header: "Case Contested",
      }),
      columnHelper.accessor("Complexity", {
        header: "Complexity",
      }),
      columnHelper.accessor("Risk – Monetary", {
        header: "Risk – Monetary",
      }),
      columnHelper.accessor("Risk – Reputation", {
        header: "Risk – Reputation",
      }),
      columnHelper.accessor("Risk - Regulatory", {
        header: "Risk - Regulatory",
      }),
      columnHelper.accessor("Created Date", {
        header: "Created Date",
      }),
      columnHelper.accessor("Last Modified Date", {
        header: "Last Modified Date",
      }),
      columnHelper.accessor("Created By", {
        header: "Created By",
      }),
      columnHelper.accessor("Last Modified By", {
        header: "Last Modified By",
      }),
    ],
    [AllotmentCase, pagination]
  );

  const [columnOrder, setColumnOrder] = useState(columnArrange);

  useEffect(
    () => {
      setColumnOrder(columnArrange);
      setColumnVisibility(casefilter);
    },
    [],
    [columnArrange, casefilter]
  );

  function isValidJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    dispatch(filterColumnAction());
    dispatch(allCaseTypeAction());
    dispatch(allOrganizationAction());
  }, []);
  useEffect(
    () => {
      setColumnVisibility(casefilter);
    },
    [casefilter],
    []
  );

  useEffect(() => {
    const value = { pagination, user };
    dispatch(allCaseDetailsAction(value));
  }, []);

  useEffect(() => {
    const values = { user, columnVisibility };
    const trueKeys = Object.keys(columnVisibility).filter(
      (key) => columnVisibility[key]
    );

    if (trueKeys.length <= 20) {
      if (
        columnVisibility !== null &&
        Object.keys(columnVisibility).length > 0
      ) {
        dispatch(UpdateCaseFilterAction(values));
      }
    } else {
      animateCss(
        "error",
        `You have try selecte ${trueKeys.length} columns. The maximum limit is 20 columns Only.`,
        2500
      );
      setColumnVisibility(casefilter);
    }
  }, [columnVisibility]);

  useEffect(() => {
    const values = {
      organization: filterOrganizationName,
      caseType: filterCaseType,
      user,
    };
    const value = { values, pagination, user };

    // filterOrganizationName &&
    //   dispatch(allCaseDetailsAction(caseDetailsFetchData));
    // ? dispatch(OrganizationCaseDetailsAction(value))
    // : dispatch(allCaseDetailsAction(caseDetailsFetchData));
  }, [filterOrganizationName]);

  const caseDetailsFetchData = {
    pagination,
    user,
  };

  const [alertVisible, setAlertVisible] = useState(false);

  const handleColumnVisibilityChange = (updatedColumnVisibility) => {
    const visibleColumns = Object.values(updatedColumnVisibility).filter(
      Boolean
    ).length;

    if (visibleColumns > 20) {
      if (!alertVisible) {
        setAlertVisible(true);
      }

      return;
    } else if (visibleColumns < 20) {
      setColumnVisibility(updatedColumnVisibility);
    }
  };

  const handleOrder = (newColumnOrder) => {
    setColumnOrder(newColumnOrder);

    const arrangedFilterColumn = {
      "mrt-row-select": true,
      "mrt-row-numbers": true,
    };
    newColumnOrder?.forEach((key) => {
      if (casefilter.hasOwnProperty(key)) {
        arrangedFilterColumn[key] = casefilter[key];
      } else {
        arrangedFilterColumn[key] = false;
      }
    });

    if (newColumnOrder?.length > 2) {
      const values = {
        user,
        columnVisibility: arrangedFilterColumn,
      };

      setColumnVisibility(arrangedFilterColumn);
    }
  };

  const table = useMaterialReactTable(
    {
      columns,
      data: AllotmentCase ?? [],
      state: {
        columnFilters,
        pagination,
        columnOrder: columnOrder ?? Object.keys(casefilter),
        columnVisibility,
        isLoading,
        rowSelection,
      },
      isMultiSortEvent: () => true,

      icons: {
        FilterListIcon: (props) => (
          <FilterAltIcon
            className="text-primary cursor-pointer"
            // {...props}
            // onClick={() => {
            //   setColumnFilters([]);
            // }}
          />
        ),
        FilterListOffIcon: (props) => (
          <FilterAltOffIcon
            {...props}
            onClick={() => {
              setColumnFilters([]);
            }}
          />
        ),
        ViewColumnIcon: () => <SettingsIcon className="text-primary" />,
        // SortIcon: () => <SortIcon />,
        // VisibilityOffIcon: () => <VisibilityOffIcon />,
        // FullscreenIcon: () => <FullscreenIcon />,
        // FullscreenExitIcon: () => <FullscreenExitIcon />,
        // DragHandleIcon: () => <DragIndicatorIcon />,
        // DensityMediumIcon: () => <DensityMediumIcon />,
      },

      muiPaginationProps: {
        rowsPerPageOptions: [5, 10, 20, 100, 500, 1000, 2000, 5000],
        color: "primary",
        shape: "rounded",
        showRowsPerPage: true,
        variant: "outlined",
      },

      onPaginationChange: setPagination,
      displayColumnDefOptions: {
        "#": {
          enableHiding: true,
        },
        "mrt-row-actions": {
          visibleInShowHideMenu: true,
        },
        "mrt-row-numbers": {
          enableHiding: false,
        },
        // "mrt-row-select": {
        //   enableHiding: false,
        // },
      },

      renderBottomToolbarCustomActions: ({ table }) => {
        const record = table?.getPrePaginationRowModel()?.rows;

        const totalRowCount = table?.getRowModel()?.rows?.length || 0;

        return (
          <Typography className="d-flex row">
            <b>
              Total Records:{" "}
              <span className="text-primary">{record?.length}</span>
            </b>
          </Typography>
        );
      },

      enableRowSelection: true,
      onRowSelectionChange: setRowSelection,
      enableRowNumbers: true,
      rowNumberDisplayMode: "original",
      enableFullScreenToggle: false,
      enableColumnOrdering: true,
      enableColumnResizing: true,
      paginationDisplayMode: "pages",
      positionToolbarAlertBanner: "bottom",
      enableStickyHeader: false,
      enableGlobalFilter: false,
      enableDensityToggle: false,
      enableFacetedValues: true,
      enableGlobalFilterModes: true,
      enableFilterMatchHighlighting: true,
      enableColumnFilterModes: true,
      enableRowActions: false,
      enableResizing: true,
      columnFilterDisplayMode: "subheader",
      enableRowVirtualization: false,
      onColumnFiltersChange: setColumnFilters,

      // rowNumberDisplayMode: "static",
      initialState: {
        columnVisibility,
        showColumnFilters: false,
        columnFilterDisplayMode: "contains",
        density: "compact",
        showProgressBars: isLoading ?? true,
        rowSelection: {
          // "#": false,
          // "mrt-row-actions": false,
          // "mrt-row-numbers": false,
          // "mrt-row-select": true,
        },
      },
      muiCircularProgressProps: {
        color: "secondary",
        thickness: 5,
        size: 55,
      },
      muiSkeletonProps: {
        animation: "pulse",
        height: 28,
      },
      onColumnVisibilityChange: handleColumnVisibilityChange,
      onColumnOrderChange: handleOrder,
      renderTopToolbarCustomActions: ({ column, table }) => {
        return (
          <>
            {" "}
            <div>
              <Formik
                initialValues={{
                  organization: "",
                  caseType: "",
                }}
                validationSchema={Yup.object().shape({
                  organization: Yup.string().required(
                    "Organization Name is required"
                  ),
                  caseType: Yup.string().required("Case Type is required"),
                })}
                onSubmit={(values, actions) => {
                  try {
                    setFilterOrganizationName(values?.organization);
                    setFilterCaseType(values?.caseType);

                    const value = { values, pagination, user };

                    dispatch(allCaseDetailsAction(value));
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        flexWrap: "wrap",
                      }}
                    >
                      <FormControl
                        style={{
                          minWidth: "200px",
                          marginRight: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="organization">
                          Organisation Name
                        </InputLabel>
                        <Select
                          labelId="organization"
                          placeholder="Organization Name"
                          id="organization"
                          label="Organization Name"
                          name="organization"
                          value={values.organization}
                          onChange={async (event) => {
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.organization && errors.organization
                          )}
                        >
                          {organizationName?.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.organizationName}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.organization && errors.organization && (
                          <FormHelperText error>
                            {errors.organization}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        style={{
                          minWidth: "200px",
                          marginRight: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="caseType">Case Type</InputLabel>
                        <Select
                          labelId="caseType"
                          id="caseType"
                          label="Case Type"
                          name="caseType"
                          value={values.caseType}
                          onChange={async (event) => {
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.caseType && errors.caseType)}
                        >
                          {caseType?.map((item, index) => (
                            <MenuItem
                              key={item.generalSettingsID}
                              value={item.generalSettingsID}
                            >
                              {item.categoryName}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.caseType && errors.caseType && (
                          <FormHelperText error>
                            {errors.caseType}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <Button
                        className="p-0 mx-2"
                        style={{
                          padding: "10px",
                          height: "40px",
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Search
                      </Button>
                      <Button
                        className="p-0"
                        style={{ padding: "10px", height: "40px" }}
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          resetForm();
                          setFilterOrganizationName(null);
                          const value = { pagination };

                          dispatch(allCaseDetailsAction(value));
                          // setFilterCaseType(null);
                        }}
                        type="button"
                      >
                        Clear
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <Box style={tableStyle}>
              {/* <Button
              onClick={async () => {
                try {
                  exportToExcel();
                } catch (error) {
                  console.error("Export failed:", error);
                }
              }}
              startIcon={<MdOutlineBackupTable className="text-success" />}
            >
              Export
            </Button> */}

              <CustomizedMenus
                visibleCount={columnVisibility}
                excel={() => {
                  exportToExcel(
                    table.getSelectedRowModel().rows?.map((item, i) => {
                      return item.original?.["CaseNect Number"];
                    })?.length > 0
                      ? table.getSelectedRowModel().rows
                      : table.getPrePaginationRowModel().rows
                  );
                }}
                PDF={() => {
                  exportToPDF(
                    table.getSelectedRowModel().rows?.map((item, i) => {
                      return item.original?.["CaseNect Number"];
                    })?.length > 0
                      ? table.getSelectedRowModel().rows
                      : table.getPrePaginationRowModel().rows
                  );
                }}
                CSV={() => {
                  exportToCSV(
                    table.getSelectedRowModel().rows?.map((item, i) => {
                      return item.original?.["CaseNect Number"];
                    })?.length > 0
                      ? table.getSelectedRowModel().rows
                      : table.getPrePaginationRowModel().rows
                  );
                }}
                WORD={() => {
                  exportToWORD(
                    table.getSelectedRowModel().rows?.map((item, i) => {
                      return item.original?.["CaseNect Number"];
                    })?.length > 0
                      ? table.getSelectedRowModel().rows
                      : table.getPrePaginationRowModel().rows
                  );
                }}
              />

              <Button
                component={Link}
                className="mx-2 p-2"
                // to={"/case/caseDetails/update"}
                id="demo-customized-button"
                variant="contained"
                disabled={
                  table.getSelectedRowModel().rows?.map((item, i) => {
                    return item.original?.["CaseNect Number"];
                  })?.length <= 0
                }
                onClick={() => {
                  const caseType = table
                    .getSelectedRowModel()
                    .rows?.map((item) => item.original["Case Type"])
                    .filter(
                      (type, index, self) => self.indexOf(type) === index
                    );
                  const uniqueCaseTypes = [...new Set(caseType)];

                  if (
                    uniqueCaseTypes?.length === 1 ||
                    (uniqueCaseTypes[0].includes("Arbitration") &&
                      uniqueCaseTypes?.length === (1 || 2))
                  ) {
                    navigate("/case/caseDetails/update");
                  } else {
                    animateCss(
                      "message",
                      "<b>Bulk update failed:</b> Selected cases belong to different case types.<br/> Please ensure all cases are of the same type."
                    );
                  }

                  function groupCasesByOrganizationAndType(cases) {
                    return cases.reduce((acc, caseItem) => {
                      const orgName = caseItem["Organization Name"];
                      const caseType = caseItem["Case Type"];

                      // Find if an entry with the same Organization Name & Case Type already exists
                      let group = acc.find(
                        (item) =>
                          item["Organization Name"] === orgName &&
                          item["Case Type"] === caseType
                      );

                      if (!group) {
                        // If not found, create a new group
                        group = {
                          "Organization Name": orgName,
                          "Case Type": caseType,
                          data: [],
                        };
                        acc.push(group);
                      }

                      // Push case details inside `data` array
                      group.data.push(caseItem);

                      return acc;
                    }, []);
                  }

                  const groupedCases = groupCasesByOrganizationAndType(
                    table.getSelectedRowModel().rows?.map((item, i) => {
                      return item.original;
                    })
                  );

                  dispatch(bulkCaseUpdateReducer(groupedCases));
                }}
              >
                Bulk Update
              </Button>
              <Button
                variant="contained"
                className=" p-2"
                color="error"
                disabled={
                  table.getSelectedRowModel().rows?.map((item, i) => {
                    return item.original?.["CaseNect Number"];
                  })?.length <= 0
                }
                onClick={() => {
                  const selectValue = table
                    .getSelectedRowModel()
                    .rows?.map((item, i) => {
                      return item.original?.["CaseNect Number"];
                    });
                  if (!window.delete) {
                    animateCss("info", "You are not authorized to delete");
                  } else if (
                    !table.getIsSomeRowsSelected() &&
                    !table.getIsAllRowsSelected()
                  ) {
                    animateCss(
                      "warn",
                      "You haven't selected a row, so unable to delete.",
                      2500
                    );
                  } else if (window.delete && selectValue?.length > 0) {
                    enqueueSnackbar(
                      <>
                        <div>
                          Are you sure you want to delete the selected Case
                          Details records and associated documents?
                        </div>
                      </>,
                      {
                        variant: "warning",
                        onClose: () => {},
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        action: (key) => (
                          <>
                            <StyledButton
                              onClick={async () => {
                                dispatch(
                                  caseDetailsBulkDeleteAction(selectValue)
                                )
                                  .unwrap()
                                  .then((result) => {
                                    if (result) {
                                      animateCss(
                                        "success",
                                        "Document Deleted Successfully"
                                      );
                                      dispatch(
                                        allCaseDetailsAction(
                                          caseDetailsFetchData
                                        )
                                      );
                                      setRowSelection(true);
                                    }
                                  })
                                  .catch((error) => {
                                    console.error(
                                      "Action rejected with error:",
                                      error
                                    );
                                  });

                                closeSnackbar(key);
                              }}
                            >
                              Confirm
                            </StyledButton>
                            <StyledButton
                              onClick={() => {
                                closeSnackbar(key);
                              }}
                            >
                              Cancel
                            </StyledButton>
                          </>
                        ),
                        autoHideDuration: null,
                        style: {
                          backgroundColor: "#f44336",
                          width: "600px",
                          color: "#fff",
                        },
                      }
                    );
                  }
                }}
                // startIcon={<FileDownloadIcon />}
              >
                Delete
              </Button>
            </Box>
          </>
        );
      },
    },
    [AllotmentCase, columnVisibility, casefilter]
  );

  const truncateString = (str, maxLength) => {
    return str && str.length > maxLength ? str.slice(0, maxLength) : str;
  };

  const exportToExcel = async (rows) => {
    const tableData = rows.map((row, index) => {
      const originalData = row.original;
      const reorderedData = {};

      reorderedData["S No"] = index + 1;

      columnOrder.forEach((column) => {
        if (columnVisibility[column]) {
          if (column !== "mrt-row-select") {
            if (column === "mrt-row-numbers") {
              reorderedData["S No"] = index + 1;
            } else {
              let value = originalData[column] || "";

              // Check if the value is a valid date
              if (value instanceof Date) {
                value = format(value, "dd-MM-yyyy"); // Format date as "12-03-2025"
              } else if (!isNaN(Date.parse(value))) {
                value = format(new Date(value), "dd-MM-yyyy"); // Convert and format
              }

              reorderedData[column] = value;
            }
          }
        }
      });

      return reorderedData;
    });

    exportXLSX(tableData, "CaseDetails", "CaseDetails");
  };

  const exportToPDF = async (rows) => {
    const tableData = rows.map((row, index) => {
      const originalData = row.original;
      const reorderedData = {};

      reorderedData["S No"] = index + 1;

      columnOrder.forEach((column) => {
        if (columnVisibility[column]) {
          if (column === "mrt-row-numbers") {
            reorderedData["S No"] = index + 1;
          } else {
            reorderedData[column] = originalData[column] || "";
          }
        }
      });

      return reorderedData;
    });

    exportPDF(tableData, "CaseDetails", "CaseDetails");
  };

  const exportToCSV = async (rows) => {
    const tableData = rows.map((row, index) => {
      const originalData = row.original;
      const reorderedData = {};

      reorderedData["S No"] = index + 1;

      columnOrder.forEach((column) => {
        if (columnVisibility[column]) {
          if (column === "mrt-row-numbers") {
            reorderedData["S No"] = index + 1;
          } else {
            reorderedData[column] = originalData[column] || "";
          }
        }
      });

      return reorderedData;
    });

    exportCSV(tableData, "CaseDetails", "CaseDetails");
  };

  const exportToWORD = () => {
    const filterColumns = Object.keys(columnVisibility).filter(
      (key) => columnVisibility[key]
    );

    const matchColumns = columns
      ?.filter((item) => filterColumns.includes(item.id))
      .map((item) => item.header);

    const dataToExport = AllotmentCase.map((item) => {
      const row = {};

      matchColumns.forEach((col) => {
        const cellValue = item[col];

        let extractedValue = null;

        if (typeof cellValue === "string") {
          try {
            const parsedValue = JSON.parse(cellValue);

            if (typeof parsedValue === "object" && parsedValue !== null) {
              extractedValue =
                parsedValue.categoryName ||
                parsedValue.organizationName ||
                parsedValue.name ||
                null;
            }
          } catch (e) {
            extractedValue = cellValue;
          }
        }

        row[col] = truncateString(extractedValue || cellValue, 32767);
      });

      return row;
    });

    dispatch(exportWordAction(dataToExport));
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="p-0 m-0">
        <>
          <MaterialReactTable table={table} />
        </>
      </Box>
    </>
  );
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

function CustomizedMenus({ excel, PDF, CSV, WORD, visibleCount }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const trueKeys = Object.keys(visibleCount).filter((key) => visibleCount[key]);

  return (
    <HtmlTooltip
      title={
        <>
          <Typography color="inherit" className="text-info">
            <CiCircleInfo className="text-warn" /> Export Info
          </Typography>
          <Typography variant="body2" className="text-black">
            <strong>Note:</strong> You have selected{" "}
            <b>{trueKeys?.length || 0} Columns</b>. Only a maximum of{" "}
            <b>20 columns</b> will be exported.
          </Typography>
        </>
      }
    >
      <div className="p-0  m-0">
        <Button
          id="demo-customized-button"
          className="p-2"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Export
        </Button>

        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={excel} disableRipple>
            <MdOutlineBackupTable className="text-success mx-2" />
            Excel
          </MenuItem>
          <MenuItem onClick={PDF} disableRipple>
            <FaRegFilePdf className="text-danger mx-2" />
            PDF
          </MenuItem>

          <MenuItem onClick={CSV} disableRipple>
            <BsFiletypeCsv className="text-black mx-2" />
            CSV
          </MenuItem>
          {/* <MenuItem onClick={WORD} disableRipple>
          <BsFiletypeCsv className="text-black mx-2" />
          Word
        </MenuItem> */}
        </StyledMenu>
      </div>
    </HtmlTooltip>
  );
}

export default OrganizationCaseDetails;

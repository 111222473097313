import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  checklistDocumentUplodOptions,
  createChecklistDocuments,
  createChecklistDocumentsPDFSplit,
  deleteCaseChecklistDocument,
  findCaseChecklistDocument,
  organizationDocumentBulkUpdate,
} from "../../../api/checklistDocuments_api";

export const createChecklistDocumentAction = createAsyncThunk(
  "checklistDocuments/createChecklistDocumentAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = {
        excelData: values?.excelAlterData,
        mappingData: values?.values,
      };
      const formData = new FormData();

      if (values.uploadedFiles) {
        for (const file of values.uploadedFiles) {
          formData.append("files", file);
        }
      }

      formData.append("data", JSON.stringify(data));

      const result = await createChecklistDocuments(formData);

      const serializedHeaders = {
        contentType: result.headers["content-type"],
      };
      return { data: result.data, headers: serializedHeaders };
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const createSplitChecklistDocumentAction = createAsyncThunk(
  "checklistDocuments/createSplitChecklistDocumentAction",
  async (data, { rejectWithValue, getState }) => {
    try {
      let startingId = data.pdfData?.values.startingId;

      const formData = new FormData();

      if (data.blobs && data.blobs.length > 0) {
        for (const fileObj of data.blobs) {
          const ID = `${data.pdfData?.values.clientCode}_${
            data.pdfData?.values.year
          }_${String(startingId).padStart(3, "0")}`;

          formData.append("files", fileObj.blob, fileObj.name);
          formData.append("data", JSON.stringify({ data: data.pdfData }));
          startingId += 1;
        }
      }

      const result = await createChecklistDocumentsPDFSplit(formData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const findCaseChecklistDocumentAction = createAsyncThunk(
  "checklistDocuments/findCaseChecklistDocumentAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await findCaseChecklistDocument(values);

      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const checklistDocumentUplodOptionsAction = createAsyncThunk(
  "checklistDocuments/checklistDocumentUplodOptionsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await checklistDocumentUplodOptions(values);

      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const deleteCaseChecklistDocumentAction = createAsyncThunk(
  "checklistDocuments/deleteCaseChecklistDocumentAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await deleteCaseChecklistDocument(values);

      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const organizationDocumentBulkUpdateAction = createAsyncThunk(
  "checklistDocuments/organizationDocumentBulkUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await organizationDocumentBulkUpdate(values);

      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

import { Card, Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { singleCaseDetailsAction } from "../../../redux/actions/Case/caseAction";
import React, { useEffect, useRef, useState } from "react";
import ArbitrationDocumentList from "./ArbitrationDocumentList";
import DocumentDrawer from "../../Drawer";
import PreTrialApplication from "./TrialProceedings/PreTrialApplication";
import ClaimantsListOfEvidence from "./TrialProceedings/ClaimantsListOfEvidence";
import RespondentsListOfEvidence from "./TrialProceedings/RespondentsListOfEvidence";
import ClaimantSideWitness from "./TrialProceedings/ClaimantSideWitness";
import RespondentSideWitness from "./TrialProceedings/RespondentSideWitness";
import ClaimantSideArguments from "./TrialProceedings/ClaimantSideArguments";
import RespondentSideArguments from "./TrialProceedings/RespondentSideArguments";

const TrialProceedings = () => {
  const [open, setOpen] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [trialProceedings, setTrialProceedings] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [openModel, setOpenModel] = useState(false);
  const expandedAccordionRef = useRef(null);
  const [preTrialApplicationsData, setPreTrialApplicationsData] = useState([]);
  const [claimantsListOfEvidence, setClaimantsListOfEvidence] = useState([]);
  const [formValue, setFormValue] = useState([]);
  const [viewDocument, setViewDocument] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleAccordionChange = (index) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
    expandedAccordionRef.current = index;
  };

  const dispatch = useDispatch();

  const { id } = useParams();
  const params = { caseID: id };
  const rowData = useSelector((state) => state?.case?.editCaseDetails);
  const preTrailCaseDetailsDocuments = useSelector(
    (state) => state?.case?.preTrailCaseDetailsDocuments
  );

  const preTrialData = rowData.preTrialApplications
    ? JSON.parse(rowData.preTrialApplications)
    : "[]";

  const preTrialJSONData = preTrialData ? JSON.parse(preTrialData) : [];

  const claimantsListOfEvidenceData = rowData.ClaimantsListOfEvidence
    ? JSON.parse(rowData.ClaimantsListOfEvidence)
    : "[]";
  const claimantsListOfEvidenceJSONData = claimantsListOfEvidenceData
    ? JSON.parse(claimantsListOfEvidenceData)
    : [];

  useEffect(() => {
    dispatch(singleCaseDetailsAction(params));
    setPreTrialApplicationsData(preTrialJSONData);
    setClaimantsListOfEvidence(claimantsListOfEvidenceJSONData);
  }, [openModel]);

  return (
    <>
      <ArbitrationDocumentList
        open={open}
        setOpen={setOpen}
        checklistDocuments={{}}
      />
      <Card
        variant="outlined"
        className=" mt-3 md-6 xl-6 xxl-6"
        sx={{ maxWidth: "100%" }}
        xs={12}
        sm={12}
        md={6}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="m-0 px-2  text-secondary"
        >
          Trial Proceedings
        </Typography>

        <div
          className="d-flex row"
          spacing={2}
          style={{ backgroundColor: "#fbfbfc" }}
        >
          {/* {editArbitrationWitnessEvidenceAndExpertReport &&
          expanded === "Arbitration Witness, Evidence and Expert Report" ? (
            <Formik
              initialValues={{
                claimantsListOfEvidence: !rowData?.caseID
                  ? null
                  : rowData?.ClaimantsListOfEvidence,
                respondentsListOfEvidence: !rowData?.caseID
                  ? null
                  : rowData?.RespondentsListOfEvidence,
                claimantWitness: !rowData?.caseID
                  ? null
                  : rowData?.ClaimantWitness,
                respondentWitness: !rowData?.caseID
                  ? null
                  : rowData?.RespondentWitness,
                expertReportFiledOn: !rowData?.caseID
                  ? null
                  : rowData?.ExpertReportFiledOn,
                params,
                user,
              }}
              onSubmit={async (values, actions) => {
                try {
                  dispatch(
                    updateArbitrationWitnessEvidenceAndExpertReportAction(
                      values
                    )
                  );
                  setEditArbitrationWitnessEvidenceAndExpertReport(false);
                  setTimeout(() => {
                    dispatch(singleCaseDetailsAction(params));
                  }, 1000);
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                return (
                  <div className="d-flex row  col-12 mt-2">
                    <form onSubmit={handleSubmit} xs={12} sm={12} md={12}>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="m-0 "
                            >
                              Arbitration Witness, Evidence and Expert Report
                            </Typography>

                            <div>
                              <Button
                                className="mx-2"
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEditArbitrationWitnessEvidenceAndExpertReport(
                                    false
                                  );
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                // color="primary"
                                variant="contained"
                                onClick={async () => {}}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <Box
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                backgroundColor: "white",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Claimant's List of Evidence
                                  </Typography>

                                  <OutlinedInput
                                    id="claimantsListOfEvidence"
                                    name="claimantsListOfEvidence"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={values?.claimantsListOfEvidence}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Respondent's List of Evidence
                                  </Typography>

                                  <OutlinedInput
                                    id="respondentsListOfEvidence"
                                    name="respondentsListOfEvidence"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={values?.respondentsListOfEvidence}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Claimant Witness
                                  </Typography>

                                  <OutlinedInput
                                    id="claimantWitness"
                                    name="claimantWitness"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={values?.claimantWitness}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Respondent Witness
                                  </Typography>

                                  <OutlinedInput
                                    id="respondentWitness"
                                    name="respondentWitness"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={values?.respondentWitness}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                              </LocalizationProvider>
                            </Box>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <>
              <Accordion
                className="mt-2"
                expanded={
                  expanded === "Arbitration Witness, Evidence and Expert Report"
                }
                onChange={handleChangeAccordian(
                  "Arbitration Witness, Evidence and Expert Report"
                )}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0 "
                    >
                      Arbitration Witness, Evidence and Expert Report
                    </Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              event.stopPropagation();
                              // resetForm();
                              setEditArbitrationWitnessEvidenceAndExpertReport(
                                true
                              );
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Claimant's List of Evidence"
                          secondary={rowData?.ClaimantsListOfEvidence}
                        />
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Respondent's List of Evidence"
                          secondary={rowData?.RespondentsListOfEvidence}
                        />
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Claimant Witness"
                          secondary={rowData?.ClaimantWitness}
                        />
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Respondent Witness"
                          secondary={rowData?.RespondentWitness}
                        />
                      </List>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )} */}
          <PreTrialApplication
            // open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            preTrialApplications={trialProceedings}
            setPreTrialApplications={setTrialProceedings}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            openModel={openModel}
            setOpenModel={setOpenModel}
            expandedAccordionRef={expandedAccordionRef}
            preTrialApplicationsData={preTrialApplicationsData}
            setPreTrialApplicationsData={setPreTrialApplicationsData}
            viewDocument={viewDocument}
            setViewDocument={setViewDocument}
            handleAccordionChange={handleAccordionChange}
          />

          <ClaimantsListOfEvidence
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            viewAccordian={trialProceedings}
            setPreTrialApplications={setTrialProceedings}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            openModel={openModel}
            setOpenModel={setOpenModel}
            expandedAccordionRef={expandedAccordionRef}
            claimantsListOfEvidence={claimantsListOfEvidence}
            setClaimantsListOfEvidence={setClaimantsListOfEvidence}
            viewDocument={viewDocument}
            setViewDocument={setViewDocument}
            handleAccordionChange={handleAccordionChange}
          />
          <RespondentsListOfEvidence
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            viewAccordian={trialProceedings}
            openModel={openModel}
            setOpenModel={setOpenModel}
            setViewDocument={setViewDocument}
            setEditIndex={setEditIndex}
            editIndex={editIndex}
            viewDocument={viewDocument}
            handleAccordionChange={handleAccordionChange}
            expandedAccordionRef={expandedAccordionRef}
            formValue={formValue}
            setFormValue={setFormValue}
          />

          <ClaimantSideWitness
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            viewAccordian={trialProceedings}
            openModel={openModel}
            setOpenModel={setOpenModel}
            setViewDocument={setViewDocument}
            setEditIndex={setEditIndex}
            editIndex={editIndex}
            viewDocument={viewDocument}
            handleAccordionChange={handleAccordionChange}
            expandedAccordionRef={expandedAccordionRef}
            formValue={formValue}
            setFormValue={setFormValue}
          />

          <RespondentSideWitness
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            viewAccordian={trialProceedings}
            openModel={openModel}
            setOpenModel={setOpenModel}
            setViewDocument={setViewDocument}
            setEditIndex={setEditIndex}
            editIndex={editIndex}
            viewDocument={viewDocument}
            handleAccordionChange={handleAccordionChange}
            expandedAccordionRef={expandedAccordionRef}
            formValue={formValue}
            setFormValue={setFormValue}
          />

          <ClaimantSideArguments
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            viewAccordian={trialProceedings}
            openModel={openModel}
            setOpenModel={setOpenModel}
            setViewDocument={setViewDocument}
            setEditIndex={setEditIndex}
            editIndex={editIndex}
            viewDocument={viewDocument}
            handleAccordionChange={handleAccordionChange}
            expandedAccordionRef={expandedAccordionRef}
            formValue={formValue}
            setFormValue={setFormValue}
          />

          <RespondentSideArguments
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            viewAccordian={trialProceedings}
            openModel={openModel}
            setOpenModel={setOpenModel}
            setViewDocument={setViewDocument}
            setEditIndex={setEditIndex}
            editIndex={editIndex}
            viewDocument={viewDocument}
            handleAccordionChange={handleAccordionChange}
            expandedAccordionRef={expandedAccordionRef}
            formValue={formValue}
            setFormValue={setFormValue}
          />

          {/* <Accordion className="mt-2" expanded={expanded === 1}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="m-0"
                  sx={{
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={() => handleAccordionChange(1)}
                >
                  Additional Applications
                </Typography>
                {window?.edit && (
                  <div>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      type="button"
                      color="primary"
                    >
                      File Pre-Trial Application
                    </Button>
                  </div>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={6}>
                  <List sx={{ bgcolor: "background.paper" }}>
                    <ListItemText
                      primary="Extra List 1"
                      secondary="Sample data for extra list 1"
                    />
                  </List>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <List sx={{ bgcolor: "background.paper" }}>
                    <ListItemText
                      primary="Extra List 2"
                      secondary="Sample data for extra list 2"
                    />
                  </List>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion> */}
        </div>
      </Card>
      <DocumentDrawer
        open={viewDocument}
        setOpen={setViewDocument}
        checklistDocuments={preTrailCaseDetailsDocuments}
      />
    </>
  );
};

export default TrialProceedings;

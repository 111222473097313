import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  dispositionsCreateAction,
  dispositionsDeleteAction,
  dispositionsUpdateAction,
  preTrailCaseDetailsDocumentsAction,
  singleCaseDetailsAction,
} from "../../../../redux/actions/Case/caseAction";
import { animateCss } from "../../../TosterAlert";
import { IoIosOpen, IoMdAttach } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DatePicker from "react-datepicker";
import { formatDate } from "../../../../helpers/helperTool";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dispositions = ({
  open,
  setOpen,
  expanded,
  setExpanded,
  settings,
  params,
  user,
  dispatch,
  handleChange,
  viewDocument,
  setViewDocument,
  rowData,
}) => {
  const [formValue, setFormValue] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [hovered, setHovered] = useState({});

  const { id } = useParams();
  let data;
  data = rowData.dispositions
    ? JSON.parse(JSON.parse(rowData.dispositions))
    : [];

  return (
    <>
      <div className="d-flex row p-0 m-0  col-12 mt-2">
        <form xs={12} sm={12} md={12}>
          <Accordion
            onClick={(event) => {
              event.stopPropagation();
              handleChange(1);
              setOpen(true);
            }}
            expanded={expanded === 1 && open === true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  width: "100%",
                  backgroundColor: "white",
                }}
                xs={12}
                sm={12}
                md={12}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="m-0"
                  // sx={{
                  //   "&:hover": {
                  //     color: "primary.main",
                  //   },
                  // }}
                >
                  Dispositions
                </Typography>
                <div className=" justify-content-end">
                  {window?.edit && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(event) => {
                        event.stopPropagation();
                        setEditIndex(null);
                        setExpanded(1);
                        setOpenModel(true);
                      }}
                      disabled={data?.length >= 10}
                      className="justify-content-center"
                    >
                      Add Disposition Record
                    </Button>
                  )}
                </div>{" "}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      // alignItems: "end",
                      backgroundColor: "white",
                      flexWrap: "wrap",
                      alignItems: "stretch",
                    }}
                  >
                    {open && openModel && (
                      <ModelForm
                        data={data}
                        settings={settings}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        setOpenModel={setOpenModel}
                        openModel={openModel}
                        editIndex={editIndex}
                        setEditIndex={setEditIndex}
                        dispatch={dispatch}
                        params={params}
                      />
                    )}

                    <Box
                      sx={{
                        display: "grid",
                        // px: 2,
                        gridTemplateColumns: {
                          xs: "1fr",
                          sm: "1fr",
                          md: "1fr",
                        },
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                        },
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Box
                        className="d-flex col-12"
                        // style={{
                        //   overflowX: "auto",
                        //   width: "100%",
                        // }}
                      >
                        <table
                          style={{ overflowWrap: "anywhere" }}
                          className="my-2 table table-striped table-bordered"
                        >
                          <thead
                            style={{
                              fontSize: "14px",
                              fontWeight: 800,
                            }}
                          >
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Record
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Disposition Date
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Supporting Document
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                  width: "65px",
                                }}
                                className="border"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {data?.map((order, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Typography
                                      component="a"
                                      className="m-0"
                                      sx={{
                                        ...(window.edit && {
                                          color: "primary.main",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          fontWeight: 800,
                                        }),
                                        ...(!window.edit && {
                                          color: "secondary.main",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          fontWeight: 800,
                                        }),
                                      }}
                                      onClick={() => {
                                        if (window.edit) {
                                          setOpenModel(true);
                                          setEditIndex(index);
                                        } else {
                                          animateCss(
                                            "info",
                                            "You are not authorized to update"
                                          );
                                        }

                                        const uniqueDocumentId = [
                                          ...(data[index]?.attachDisposition ||
                                            []),
                                        ];

                                        const document = {
                                          uniqueDocumentId,
                                        };
                                        dispatch(
                                          preTrailCaseDetailsDocumentsAction(
                                            document
                                          )
                                        );
                                      }}
                                    >
                                      Disposition Type {index + 1}
                                    </Typography>{" "}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {formatDate(order?.dispositionDate)}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "start",
                                    }}
                                  >
                                    {order?.attachDisposition?.length > 0 && (
                                      <Button
                                        variant="text"
                                        onClick={() => {
                                          setViewDocument(true);
                                          const documentId = data?.[index]
                                            ? [
                                                ...(data[index]
                                                  ?.attachDisposition || []),
                                              ]
                                            : [];
                                          const uniqueDocumentId = [
                                            ...new Set(documentId),
                                          ];
                                          const document = {
                                            uniqueDocumentId,
                                            id,
                                          };
                                          dispatch(
                                            preTrailCaseDetailsDocumentsAction(
                                              document
                                            )
                                          );
                                        }}
                                        aria-label="attach"
                                        onMouseEnter={() =>
                                          setHovered({
                                            id: index,
                                            open: true,
                                            key: "Attachment",
                                          })
                                        }
                                        onMouseLeave={() =>
                                          setHovered({
                                            id: index,
                                            open: false,
                                            key: "Attachment",
                                          })
                                        }
                                      >
                                        <IoIosOpen className="fs-4 text-primary" />{" "}
                                        <span
                                          className={`transition-opacity duration-500 ${
                                            hovered.id === index &&
                                            hovered.open &&
                                            hovered.key === "Attachment"
                                              ? "opacity-100"
                                              : "opacity-0"
                                          }`}
                                        >
                                          {" "}
                                          View Attachment
                                        </span>
                                      </Button>
                                    )}
                                  </td>
                                  <td
                                    className={
                                      data?.length - 1 === index ? "d-flex" : ""
                                    }
                                  >
                                    {/* {[...(data || [])]?.length - 1 ===
                                        index && ( */}
                                    <IconButton
                                      aria-label="delete"
                                      color="error"
                                      style={{
                                        marginTop: "4%",
                                      }}
                                      onClick={() => {
                                        if (window.delete) {
                                          enqueueSnackbar(
                                            <>
                                              <div>
                                                Are you sure you want to delete
                                                the record and its associated
                                                document for Dispositions Type{" "}
                                                {index + 1} ?
                                              </div>
                                            </>,
                                            {
                                              variant: "warning",
                                              onClose: () => {},
                                              anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "right",
                                              },
                                              action: (key) => (
                                                <>
                                                  <StyledButton
                                                    onClick={async () => {
                                                      const value = {
                                                        caseID: params,
                                                        index,
                                                      };
                                                      dispatch(
                                                        dispositionsDeleteAction(
                                                          value
                                                        )
                                                      )
                                                        .unwrap()
                                                        .then((result) => {
                                                          if (result) {
                                                            dispatch(
                                                              singleCaseDetailsAction(
                                                                params
                                                              )
                                                            );
                                                          }
                                                        })
                                                        .catch((error) => {
                                                          console.error(
                                                            "Action rejected with error:",
                                                            error
                                                          );
                                                        });

                                                      closeSnackbar(key);
                                                    }}
                                                  >
                                                    Confirm
                                                  </StyledButton>
                                                  <StyledButton
                                                    onClick={() => {
                                                      closeSnackbar(key);
                                                    }}
                                                  >
                                                    Cancel
                                                  </StyledButton>
                                                </>
                                              ),
                                              autoHideDuration: null,
                                              style: {
                                                backgroundColor: "#f44336",
                                                width: "600px",
                                                color: "#fff",
                                              },
                                            }
                                          );
                                        } else {
                                          animateCss(
                                            "info",
                                            "You are not authorized to delete"
                                          );
                                        }
                                      }}
                                    >
                                      <MdDelete
                                        style={{
                                          fontSize: "24px",
                                        }}
                                      />
                                    </IconButton>
                                    {/* )} */}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  </Box>

                  {/* Dynamice Data */}
                </LocalizationProvider>
              </>
            </AccordionDetails>
          </Accordion>
        </form>
      </div>
    </>
  );
};

const ModelForm = ({
  formValue,
  setFormValue,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
  dispatch,
  params,
  data,
  rowData,
}) => {
  const document = useSelector(
    (state) => state?.case?.preTrailCaseDetailsDocuments
  );

  const editValue = data?.[editIndex];

  const { id } = useParams();

  let initialValues = {
    dispositionType: editValue?.dispositionType ?? "",
    dispositionOtherDesc: editValue?.dispositionOtherDesc ?? "",
    dispositionDate: editValue?.dispositionDate ?? "",
    dispositionAuthority: editValue?.dispositionAuthority ?? "",
    detailsOfDisposition: editValue?.detailsOfDisposition ?? "",
    outcome: editValue?.outcome ?? "",
    reliefGranted: editValue?.reliefGranted ?? "",
    attachDisposition: editIndex !== null ? document : [],
    editIndex,
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            try {
              const form = new FormData();
              form.append("caseID", id);
              form.append("dispositionType", values.dispositionType);
              form.append("dispositionOtherDesc", values.dispositionOtherDesc);
              form.append("dispositionDate", values.dispositionDate);
              form.append("dispositionAuthority", values.dispositionAuthority);
              form.append("detailsOfDisposition", values.detailsOfDisposition);
              form.append("outcome", values.outcome);
              form.append("reliefGranted", values.reliefGranted);

              if (values.attachDisposition) {
                values.attachDisposition.forEach((file) => {
                  if (file?.caseID) {
                    form.append(`attachDispositionOld`, file?.checkListDocID);
                  } else {
                    form.append(`Disposition Attachment`, file?.file);
                  }
                });
              }

              if (editIndex == null) {
                setOpenModel(false);
                dispatch(dispositionsCreateAction(form))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();

                    if (result) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                setOpenModel(false);
                const updatedData = [...data];
                updatedData[editIndex] = values;
                setFormValue(updatedData);
                form.append("editIndex", editIndex);

                dispatch(dispositionsUpdateAction(form))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();
                    if (result) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="dispositionType">
                  Disposition Type
                </InputLabel>
                <Select
                  labelId="dispositionType"
                  id="dispositionType"
                  name="dispositionType"
                  value={values?.dispositionType || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Disposition Type")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.dispositionType && errors.dispositionType && (
                  <Typography color="error">
                    {errors.dispositionType}
                  </Typography>
                )}
              </Box>

              {settings
                ?.find((item) => item?.categoryName === "Disposition Type")
                ?.Children?.find(
                  (child) =>
                    String(child.generalSettingsID) ===
                    String(values.dispositionType)
                )?.categoryName === "Other" && (
                <Box sx={{ display: "grid", mb: 2 }}>
                  <InputLabel htmlFor="dispositionOtherDesc">
                    Disposition Other Desc
                  </InputLabel>
                  <OutlinedInput
                    id="dispositionOtherDesc"
                    name="dispositionOtherDesc"
                    value={values?.dispositionOtherDesc}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.dispositionOtherDesc &&
                    errors.dispositionOtherDesc && (
                      <Typography color="error">
                        {errors.dispositionOtherDesc}
                      </Typography>
                    )}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Disposition Date
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="dispositionDate"
                  selected={values?.dispositionDate}
                  onChange={(date) => setFieldValue("dispositionDate", date)}
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.dispositionDate && errors.dispositionDate && (
                  <Typography color="error">
                    {errors.dispositionDate}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="dispositionAuthority">
                  Disposition Authority
                </InputLabel>
                <Select
                  labelId="dispositionAuthority"
                  id="dispositionAuthority"
                  name="dispositionAuthority"
                  value={values?.dispositionAuthority || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) => item?.categoryName === "Disposition Authority"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.dispositionAuthority &&
                  errors.dispositionAuthority && (
                    <Typography color="error">
                      {errors.dispositionAuthority}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="detailsOfDisposition">
                  Details of Disposition
                </InputLabel>
                <OutlinedInput
                  id="detailsOfDisposition"
                  name="detailsOfDisposition"
                  value={values?.detailsOfDisposition}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.detailsOfDisposition &&
                  errors.detailsOfDisposition && (
                    <Typography color="error">
                      {errors.detailsOfDisposition}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="outcome">Outcome</InputLabel>
                <Select
                  labelId="outcome"
                  id="outcome"
                  name="outcome"
                  value={values?.outcome || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) => item?.categoryName === "Disposition Outcome"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.outcome && errors.outcome && (
                  <Typography color="error">{errors.outcome}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="reliefGranted">Relief Granted</InputLabel>
                <OutlinedInput
                  id="reliefGranted"
                  name="reliefGranted"
                  value={values?.reliefGranted}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.reliefGranted && errors.reliefGranted && (
                  <Typography color="error">{errors.reliefGranted}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Attach Disposition
                </Typography>
                <Button
                  className="col-12 col-sm-12 col-md-6 col-xl-3"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    // accept="application/pdf"
                    onChange={(event) => {
                      const existingFiles = values?.attachDisposition || [];
                      const newFiles = Array.from(event.target.files);

                      const updatedFiles = [
                        ...new Map(
                          [...existingFiles, ...newFiles].map((file) => [
                            file.name || file?.actualDocName,
                            {
                              file: file?.file ?? file,
                              checkListDocID: file?.checkListDocID || null,
                              caseRowID: file?.caseRowID || null,
                              caseID: file?.caseID || null,
                              checklistID: file?.checklistID || null,
                              genSetID: file?.genSetID || null,
                              checklistDate: null,
                              actualDocName: file.name || file?.actualDocName,
                              uploadDocName: file.name || file?.uploadDocName,
                              documentType: file.type || file.documentType,
                              documentSize:
                                file.documentSize || file.size?.toString(),
                              documentClassification: null,
                              documentService: null,
                              serviceCount: null,
                              comments: null,
                              createdBy: file?.createdBy || 1,
                              updatedBy: file?.updatedBy || 1,
                              isActive: 1,
                              createdAt:
                                file?.createdAt || new Date()?.toISOString(),
                              updatedAt:
                                file?.updatedAt || new Date()?.toISOString(),
                            },
                          ])
                        ).values(),
                      ];

                      setFieldValue("attachDisposition", updatedFiles);
                    }}
                  />
                </Button>
                {touched.attachDisposition && errors.attachDisposition && (
                  <Typography color="error">
                    {errors.attachDisposition}
                  </Typography>
                )}
              </Box>

              {values.attachDisposition.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  {values.attachDisposition?.map((file, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={file?.actualDocName || file.name}
                          onDelete={() => {
                            if (window.delete) {
                              const updatedFiles =
                                values.attachDisposition.filter(
                                  (_, fileIndex) => fileIndex !== index
                                );
                              setFieldValue("attachDisposition", updatedFiles);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to delete"
                              );
                            }
                          }}
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                  variant="outlined"
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default Dispositions;

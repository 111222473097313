import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  arbitrationStatusTrackingCreateAction,
  arbitrationStatusTrackingDeleteAction,
  arbitrationStatusTrackingUpdateAction,
  preTrailCaseDetailsDocumentsAction,
  singleCaseDetailsAction,
  updateArbitrationEnforcementAndAppealsAction,
} from "../../../../redux/actions/Case/caseAction";
import { animateCss } from "../../../TosterAlert";
import { IoIosOpen, IoMdAttach } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DatePicker from "react-datepicker";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ArbitrationStatusTracking = ({
  rowData,
  accordionOpen,
  setAccordionOpen,
  id,
  user,
  settings,
  handleAccordion,
  editForm,
  setEditForm,
  openDocument,
  setOpenDocument,
}) => {
  const [formValue, setFormValue] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [hovered, setHovered] = useState({});

  const dispatch = useDispatch();

  const params = { caseID: id };

  let data;
  data = rowData.arbitrationStatusTracking
    ? JSON.parse(JSON.parse(rowData.arbitrationStatusTracking))
    : [];

  return (
    <>
      <Formik
        initialValues={{
          params,
          user,
        }}
        onSubmit={async (values, actions) => {
          try {
            dispatch(updateArbitrationEnforcementAndAppealsAction(values));

            setTimeout(() => {
              dispatch(singleCaseDetailsAction(params));
            }, 1000);
          } catch (error) {}
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <div className="d-flex row p-0 m-0  col-12 mt-2">
              <form onSubmit={handleSubmit} xs={12} sm={12} md={12}>
                <Accordion expanded={accordionOpen == 2}>
                  <AccordionSummary
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAccordion(2);
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0"
                        onClick={(event) => {
                          event.stopPropagation();
                          setEditForm(2);
                        }}
                      >
                        Arbitration Status Tracking
                      </Typography>

                      {window?.edit && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(event) => {
                            event.stopPropagation();
                            setAccordionOpen(2);
                            setEditIndex(null);
                            setOpenModel(true);
                          }}
                          disabled={data?.length >= 5}
                          className="justify-content-center col-2"
                        >
                          Add Status
                        </Button>
                      )}

                      {/* <div>
                        {editForm === 2 && accordionOpen == 2 && (
                          <Button
                            className="mx-2"
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleAccordion(2);
                              setEditForm(false);
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                        {(accordionOpen !== 2 ||
                          (editForm !== 2 && window?.edit)) && (
                          <Button
                            onClick={(event) => {
                              event.stopPropagation();
                              if (window.edit) {
                                handleAccordion(2);
                                setEditForm(2);
                              } else {
                                animateCss(
                                  "info",
                                  "You are not authorized to update"
                                );
                              }
                            }}
                            type="button"
                            color="primary"
                            variant="contained"
                            className="mx-2"
                          >
                            Edit
                          </Button>
                        )}
                      </div> */}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end",
                            backgroundColor: "white",
                            flexWrap: "wrap",
                            alignItems: "stretch",
                          }}
                        >
                          {openModel && (
                            <ModelForm
                              data={data}
                              settings={settings}
                              formValue={formValue}
                              setFormValue={setFormValue}
                              setOpenModel={setOpenModel}
                              openModel={openModel}
                              editIndex={editIndex}
                              setEditIndex={setEditIndex}
                              dispatch={dispatch}
                              params={params}
                            />
                          )}

                          <Box
                            sx={{
                              display: "grid",
                              // px: 2,
                              gridTemplateColumns: {
                                xs: "1fr",
                                sm: "1fr",
                                md: "1fr",
                              },
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "100%",
                              },
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Box
                              className="d-flex col-12"
                              // style={{
                              //   overflowX: "auto",
                              //   width: "100%",
                              // }}
                            >
                              <table
                                style={{ overflowWrap: "anywhere" }}
                                className="my-2 table table-striped table-bordered"
                              >
                                <thead
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 800,
                                  }}
                                >
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Record
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Status
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Appeal Document
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                        width: "65px",
                                      }}
                                      className="border"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {data?.map((order, index) => {
                                    return (
                                      <tr key={index}>
                                        <td
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: 800,
                                          }}
                                        >
                                          <Typography
                                            gutterBottom
                                            variant="p"
                                            component="div"
                                            className="m-0"
                                            sx={
                                              window.edit
                                                ? {
                                                    color: "primary.main",
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    color: "secondary.main",
                                                    cursor: "pointer",
                                                    textDecoration: "none",
                                                    fontWeight: 800,
                                                  }
                                            }
                                            onClick={() => {
                                              if (window.edit) {
                                                setOpenModel(true);
                                                setEditIndex(index);
                                              } else {
                                                animateCss(
                                                  "info",
                                                  "You are not authorized to update"
                                                );
                                              }

                                              const uniqueDocumentId = [
                                                ...(data[index]
                                                  ?.supportingDocuments || []),
                                              ];

                                              const document = {
                                                uniqueDocumentId,
                                              };
                                              dispatch(
                                                preTrailCaseDetailsDocumentsAction(
                                                  document
                                                )
                                              );
                                            }}
                                          >
                                            Arbitration{" "}
                                            {
                                              settings
                                                ?.find(
                                                  (item) =>
                                                    item?.categoryName ===
                                                    "Arbitration Status"
                                                )
                                                ?.Children?.find(
                                                  (child) =>
                                                    String(
                                                      child.generalSettingsID
                                                    ) ===
                                                    String(
                                                      order?.arbitrationStatus
                                                    )
                                                )?.categoryName
                                            }{" "}
                                            {index + 1}
                                          </Typography>{" "}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {
                                            settings
                                              ?.find(
                                                (item) =>
                                                  item?.categoryName ===
                                                  "Arbitration Status"
                                              )
                                              ?.Children?.find(
                                                (child) =>
                                                  String(
                                                    child.generalSettingsID
                                                  ) ===
                                                  String(
                                                    order?.arbitrationStatus
                                                  )
                                              )?.categoryName
                                          }
                                        </td>

                                        <td
                                          style={{
                                            fontSize: "12px",
                                            textAlign: "start",
                                          }}
                                        >
                                          {order?.supportingDocuments?.length >
                                            0 && (
                                            <Button
                                              onClick={() => {
                                                setOpenDocument(true);
                                                const documentId = data?.[index]
                                                  ? [
                                                      ...(data[index]
                                                        ?.supportingDocuments ||
                                                        []),
                                                    ]
                                                  : [];
                                                const uniqueDocumentId = [
                                                  ...new Set(documentId),
                                                ];
                                                const document = {
                                                  uniqueDocumentId,
                                                  id,
                                                };
                                                dispatch(
                                                  preTrailCaseDetailsDocumentsAction(
                                                    document
                                                  )
                                                );
                                              }}
                                              className="p-0"
                                              style={{
                                                height: "25px",
                                              }}
                                              aria-label="attach"
                                              onMouseEnter={() =>
                                                setHovered({
                                                  id: index,
                                                  open: true,
                                                  key: "Attachment",
                                                })
                                              }
                                              onMouseLeave={() =>
                                                setHovered({
                                                  id: index,
                                                  open: false,
                                                  key: "Attachment",
                                                })
                                              }
                                            >
                                              <IoIosOpen className="fs-4 text-primary" />{" "}
                                              <span
                                                className={`transition-opacity duration-500 ${
                                                  hovered.id === index &&
                                                  hovered.open &&
                                                  hovered.key === "Attachment"
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                                }`}
                                              >
                                                {" "}
                                                View Attachment
                                              </span>
                                            </Button>
                                          )}
                                        </td>
                                        <td
                                          className={
                                            data?.length - 1 === index
                                              ? "d-flex"
                                              : ""
                                          }
                                        >
                                          <IconButton
                                            aria-label="delete"
                                            color="error"
                                            style={{
                                              marginTop: "4%",
                                            }}
                                            onClick={() => {
                                              if (window.delete) {
                                                enqueueSnackbar(
                                                  <>
                                                    <div>
                                                      Are you sure you want to
                                                      delete the record and its
                                                      associated document for
                                                      Arbitration{" "}
                                                      {
                                                        settings
                                                          ?.find(
                                                            (item) =>
                                                              item?.categoryName ===
                                                              "Arbitration Status"
                                                          )
                                                          ?.Children?.find(
                                                            (child) =>
                                                              String(
                                                                child.generalSettingsID
                                                              ) ===
                                                              String(
                                                                order?.arbitrationStatus
                                                              )
                                                          )?.categoryName
                                                      }{" "}
                                                      {index + 1} ?
                                                    </div>
                                                  </>,
                                                  {
                                                    variant: "warning",
                                                    onClose: () => {},
                                                    anchorOrigin: {
                                                      vertical: "top",
                                                      horizontal: "right",
                                                    },
                                                    action: (key) => (
                                                      <>
                                                        <StyledButton
                                                          onClick={async () => {
                                                            const output =
                                                              formValue.filter(
                                                                (_, i) =>
                                                                  i !== index
                                                              );

                                                            const value = {
                                                              caseID: params,
                                                              index,
                                                            };
                                                            dispatch(
                                                              arbitrationStatusTrackingDeleteAction(
                                                                value
                                                              )
                                                            )
                                                              .unwrap()
                                                              .then(
                                                                (result) => {
                                                                  if (result) {
                                                                    dispatch(
                                                                      singleCaseDetailsAction(
                                                                        params
                                                                      )
                                                                    );
                                                                  }
                                                                }
                                                              )
                                                              .catch(
                                                                (error) => {
                                                                  console.error(
                                                                    "Action rejected with error:",
                                                                    error
                                                                  );
                                                                }
                                                              );

                                                            closeSnackbar(key);
                                                          }}
                                                        >
                                                          Confirm
                                                        </StyledButton>
                                                        <StyledButton
                                                          onClick={() => {
                                                            closeSnackbar(key);
                                                          }}
                                                        >
                                                          Cancel
                                                        </StyledButton>
                                                      </>
                                                    ),
                                                    autoHideDuration: null,
                                                    style: {
                                                      backgroundColor:
                                                        "#f44336",
                                                      width: "600px",
                                                      color: "#fff",
                                                    },
                                                  }
                                                );
                                              } else {
                                                animateCss(
                                                  "info",
                                                  "You are not authorized to delete"
                                                );
                                              }
                                            }}
                                          >
                                            <MdDelete
                                              style={{
                                                fontSize: "24px",
                                              }}
                                            />
                                          </IconButton>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Box>
                          </Box>
                        </Box>

                        {/* Dynamice Data */}
                      </LocalizationProvider>
                    </>
                  </AccordionDetails>
                </Accordion>
              </form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

const ModelForm = ({
  formValue,
  setFormValue,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
  dispatch,
  params,
  data,
  rowData,
}) => {
  const document = useSelector(
    (state) => state?.case?.preTrailCaseDetailsDocuments
  );

  const loading = useSelector((state) => state?.case.loading);

  const editValue = data?.[editIndex];

  const { id } = useParams();

  let initialValues = {
    //Hold
    arbitrationStatus: editValue?.arbitrationStatus ?? "",
    reasonForArbitrationHold: editValue?.reasonForArbitrationHold ?? "",
    partyRequestingHold: editValue?.partyRequestingHold ?? "",
    partyRequestingHoldOtherDesc: editValue?.partyRequestingHoldOtherDesc ?? "",
    arbitrationHoldStartDate: editValue?.arbitrationHoldStartDate ?? "",
    arbitrationHoldReleaseDate: editValue?.arbitrationHoldReleaseDate ?? "",
    arbitrationResumedOn: editValue?.arbitrationResumedOn ?? "",
    currentStatus: editValue?.currentStatus ?? "",
    suspendingAuthority: editValue?.suspendingAuthority ?? "",
    //withdrawal
    partyRequestingWithdrawal: editValue?.partyRequestingWithdrawal ?? "",
    partyRequestingWithdrawalOtherDesc:
      editValue?.partyRequestingWithdrawalOtherDesc ?? "",
    reasonForWithdrawal: editValue?.reasonForWithdrawal ?? "",
    arbitrationWithdrawnOn: editValue?.arbitrationWithdrawnOn ?? "",
    //settled
    settlementNegotiationsInitiatedOn:
      editValue?.settlementNegotiationsInitiatedOn ?? "",
    mediatorFacilitatorForSettlement:
      editValue?.mediatorFacilitatorForSettlement ?? "",
    termsOfSettlementAgreedOn: editValue?.termsOfSettlementAgreedOn ?? "",
    finalSettlementAmount: editValue?.finalSettlementAmount ?? "",
    complianceDeadlineForSettlementAgreement:
      editValue?.complianceDeadlineForSettlementAgreement ?? "",
    acknowledgmentOfSettlementByAllPartiesOn:
      editValue?.acknowledgmentOfSettlementByAllPartiesOn ?? "",
    enforcementOfSettlementAgreementFiledOn:
      editValue?.enforcementOfSettlementAgreementFiledOn ?? "",
    closureOfArbitrationProceedingsDueToSettlementOn:
      editValue?.closureOfArbitrationProceedingsDueToSettlementOn ?? "",
    //Document
    supportingDocuments: editIndex !== null ? document : [],
    //exit

    editIndex,
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            try {
              const form = new FormData();
              form.append("caseID", id);
              //Hold
              form.append("arbitrationStatus", values.arbitrationStatus);

              form.append(
                "reasonForArbitrationHold",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Hold"
                  ? values.reasonForArbitrationHold
                  : ""
              );
              form.append(
                "partyRequestingHold",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Hold"
                  ? values.partyRequestingHold
                  : ""
              );

              // settings
              // ?.find(
              //   (item) => item?.categoryName === "Party Requesting"
              // )
              // ?.Children?.find(
              //   (child) =>
              //     String(child.generalSettingsID) ===
              //     String(values.arbitrationStatus)
              // )?.categoryName === "Other"
              form.append(
                "partyRequestingHoldOtherDesc",
                settings
                  ?.find((item) => item?.categoryName === "Party Requesting")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.partyRequestingHold)
                  )?.categoryName === "Other"
                  ? values.partyRequestingHoldOtherDesc
                  : ""
              );
              form.append(
                "arbitrationHoldStartDate",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Hold"
                  ? values.arbitrationHoldStartDate
                  : ""
              );
              form.append(
                "arbitrationHoldReleaseDate",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Hold"
                  ? values.arbitrationHoldReleaseDate
                  : ""
              );
              form.append(
                "arbitrationResumedOn",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Hold"
                  ? values.arbitrationResumedOn
                  : ""
              );
              form.append(
                "currentStatus",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Hold"
                  ? values.currentStatus
                  : ""
              );
              form.append(
                "suspendingAuthority",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Hold"
                  ? values.suspendingAuthority
                  : ""
              );

              // Withdrawal

              form.append(
                "partyRequestingWithdrawal",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Withdrawn"
                  ? values.partyRequestingWithdrawal
                  : ""
              );
              form.append(
                "partyRequestingWithdrawalOtherDesc",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Withdrawn"
                  ? settings
                      ?.find(
                        (item) => item?.categoryName === "Party Requesting"
                      )
                      ?.Children?.find(
                        (child) =>
                          String(child.generalSettingsID) ===
                          String(values.partyRequestingWithdrawal)
                      )?.categoryName === "Other"
                    ? values.partyRequestingWithdrawalOtherDesc
                    : ""
                  : ""
              );
              form.append(
                "reasonForWithdrawal",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Withdrawn"
                  ? values.reasonForWithdrawal
                  : ""
              );
              form.append(
                "arbitrationWithdrawnOn",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Withdrawn"
                  ? values.arbitrationWithdrawnOn
                  : ""
              );
              //settled
              form.append(
                "settlementNegotiationsInitiatedOn",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Settled"
                  ? values.settlementNegotiationsInitiatedOn
                  : ""
              );
              form.append(
                "mediatorFacilitatorForSettlement",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Settled"
                  ? values.mediatorFacilitatorForSettlement
                  : ""
              );
              form.append(
                "termsOfSettlementAgreedOn",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Settled"
                  ? values.termsOfSettlementAgreedOn
                  : ""
              );
              form.append(
                "finalSettlementAmount",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Settled"
                  ? values.finalSettlementAmount
                  : ""
              );
              form.append(
                "complianceDeadlineForSettlementAgreement",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Settled"
                  ? values.complianceDeadlineForSettlementAgreement
                  : ""
              );
              form.append(
                "acknowledgmentOfSettlementByAllPartiesOn",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Settled"
                  ? values.acknowledgmentOfSettlementByAllPartiesOn
                  : ""
              );
              form.append(
                "enforcementOfSettlementAgreementFiledOn",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Settled"
                  ? values.enforcementOfSettlementAgreementFiledOn
                  : ""
              );
              form.append(
                "closureOfArbitrationProceedingsDueToSettlementOn",
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Settled"
                  ? values.closureOfArbitrationProceedingsDueToSettlementOn
                  : ""
              );

              if (
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Hold" ||
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Withdrawn" ||
                settings
                  ?.find((item) => item?.categoryName === "Arbitration Status")
                  ?.Children?.find(
                    (child) =>
                      String(child.generalSettingsID) ===
                      String(values.arbitrationStatus)
                  )?.categoryName === "Settled"
              ) {
                if (values.supportingDocuments) {
                  values.supportingDocuments.forEach((file) => {
                    if (file?.caseID) {
                      form.append(
                        `supportingDocumentsOld`,
                        file?.checkListDocID
                      );
                    } else {
                      form.append(
                        `Arbitration Status Tracking Attachment`,
                        file?.file
                      );
                    }
                  });
                }
              } else {
                form.append(`supportingDocumentsOld`, "");
              }
              if (editIndex == null) {
                setOpenModel(false);
                dispatch(arbitrationStatusTrackingCreateAction(form))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();

                    if (result) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                setOpenModel(false);
                const updatedData = [...data];
                updatedData[editIndex] = values;
                setFormValue(updatedData);
                form.append("editIndex", editIndex);

                dispatch(arbitrationStatusTrackingUpdateAction(form))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();
                    if (result) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="arbitrationStatus">
                  Arbitration Status
                </InputLabel>
                <Select
                  labelId="arbitrationStatus"
                  id="arbitrationStatus"
                  name="arbitrationStatus"
                  value={values?.arbitrationStatus || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) => item?.categoryName === "Arbitration Status"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.arbitrationStatus && errors.arbitrationStatus && (
                  <Typography color="error">
                    {errors.arbitrationStatus}
                  </Typography>
                )}
              </Box>

              {settings
                ?.find((item) => item?.categoryName === "Arbitration Status")
                ?.Children?.find(
                  (child) =>
                    String(child.generalSettingsID) ===
                    String(values.arbitrationStatus)
                )?.categoryName == "Hold" && (
                <>
                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="reasonForArbitrationHold">
                      Reason for Arbitration Hold
                    </InputLabel>
                    <OutlinedInput
                      id="reasonForArbitrationHold"
                      name="reasonForArbitrationHold"
                      value={values?.reasonForArbitrationHold}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.reasonForArbitrationHold &&
                      errors.reasonForArbitrationHold && (
                        <Typography color="error">
                          {errors.reasonForArbitrationHold}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="partyRequestingHold">
                      Party Requesting Hold
                    </InputLabel>
                    <Select
                      labelId="partyRequestingHold"
                      id="partyRequestingHold"
                      name="partyRequestingHold"
                      value={values?.partyRequestingHold || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    >
                      {settings
                        ?.find(
                          (item) => item?.categoryName === "Party Requesting"
                        )
                        ?.Children?.map((data, index) => (
                          <MenuItem key={index} value={data?.generalSettingsID}>
                            {data?.categoryName}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.partyRequestingHold &&
                      errors.partyRequestingHold && (
                        <Typography color="error">
                          {errors.partyRequestingHold}
                        </Typography>
                      )}
                  </Box>

                  {settings
                    ?.find((item) => item?.categoryName === "Party Requesting")
                    ?.Children?.find(
                      (child) =>
                        String(child.generalSettingsID) ===
                        String(values.partyRequestingHold)
                    )?.categoryName === "Other" && (
                    <Box sx={{ display: "grid", mb: 2 }}>
                      <InputLabel htmlFor="partyRequestingHoldOtherDesc">
                        Party Requesting Hold Other Desc
                      </InputLabel>
                      <OutlinedInput
                        id="partyRequestingHoldOtherDesc"
                        name="partyRequestingHoldOtherDesc"
                        value={values?.partyRequestingHoldOtherDesc}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.partyRequestingHoldOtherDesc &&
                        errors.partyRequestingHoldOtherDesc && (
                          <Typography color="error">
                            {errors.partyRequestingHoldOtherDesc}
                          </Typography>
                        )}
                    </Box>
                  )}

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Arbitration Hold Start Date
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="arbitrationHoldStartDate"
                      selected={values?.arbitrationHoldStartDate}
                      onChange={(date) =>
                        setFieldValue("arbitrationHoldStartDate", date)
                      }
                      // minDate={minDate}
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.arbitrationHoldStartDate &&
                      errors.arbitrationHoldStartDate && (
                        <Typography color="error">
                          {errors.arbitrationHoldStartDate}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Arbitration Hold Release Date
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="arbitrationHoldReleaseDate"
                      selected={values?.arbitrationHoldReleaseDate}
                      onChange={(date) =>
                        setFieldValue("arbitrationHoldReleaseDate", date)
                      }
                      // minDate={minDate}
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.arbitrationHoldReleaseDate &&
                      errors.arbitrationHoldReleaseDate && (
                        <Typography color="error">
                          {errors.arbitrationHoldReleaseDate}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Arbitration Resumed On
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="arbitrationResumedOn"
                      selected={values?.arbitrationResumedOn}
                      onChange={(date) =>
                        setFieldValue("arbitrationResumedOn", date)
                      }
                      // minDate={minDate}
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.arbitrationResumedOn &&
                      errors.arbitrationResumedOn && (
                        <Typography color="error">
                          {errors.arbitrationResumedOn}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="currentStatus">
                      Current Status
                    </InputLabel>
                    <Select
                      labelId="currentStatus"
                      id="currentStatus"
                      name="currentStatus"
                      value={values?.currentStatus || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    >
                      {settings
                        ?.find(
                          (item) => item?.categoryName === "Current Status"
                        )
                        ?.Children?.map((data, index) => (
                          <MenuItem key={index} value={data?.generalSettingsID}>
                            {data?.categoryName}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.currentStatus && errors.currentStatus && (
                      <Typography color="error">
                        {errors.currentStatus}
                      </Typography>
                    )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="suspendingAuthority">
                      Suspending Authority
                    </InputLabel>
                    <OutlinedInput
                      id="suspendingAuthority"
                      name="suspendingAuthority"
                      value={values?.suspendingAuthority}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.suspendingAuthority &&
                      errors.suspendingAuthority && (
                        <Typography color="error">
                          {errors.suspendingAuthority}
                        </Typography>
                      )}
                  </Box>
                </>
              )}

              {settings
                ?.find((item) => item?.categoryName === "Arbitration Status")
                ?.Children?.find(
                  (child) =>
                    String(child.generalSettingsID) ===
                    String(values.arbitrationStatus)
                )?.categoryName == "Withdrawn" && (
                <>
                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="partyRequestingWithdrawal">
                      Party Requesting Withdrawal
                    </InputLabel>
                    <Select
                      labelId="partyRequestingWithdrawal"
                      id="partyRequestingWithdrawal"
                      name="partyRequestingWithdrawal"
                      value={values?.partyRequestingWithdrawal || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    >
                      {settings
                        ?.find(
                          (item) => item?.categoryName === "Party Requesting"
                        )
                        ?.Children?.map((data, index) => (
                          <MenuItem key={index} value={data?.generalSettingsID}>
                            {data?.categoryName}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.partyRequestingWithdrawal &&
                      errors.partyRequestingWithdrawal && (
                        <Typography color="error">
                          {errors.partyRequestingWithdrawal}
                        </Typography>
                      )}
                  </Box>

                  {settings
                    ?.find((item) => item?.categoryName === "Party Requesting")
                    ?.Children?.find(
                      (child) =>
                        String(child.generalSettingsID) ===
                        String(values.partyRequestingWithdrawal)
                    )?.categoryName === "Other" && (
                    <Box sx={{ display: "grid", mb: 2 }}>
                      <InputLabel htmlFor="partyRequestingWithdrawalOtherDesc">
                        Party Requesting Withdrawal Other Desc
                      </InputLabel>
                      <OutlinedInput
                        id="partyRequestingWithdrawalOtherDesc"
                        name="partyRequestingWithdrawalOtherDesc"
                        value={values?.partyRequestingWithdrawalOtherDesc}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.partyRequestingWithdrawalOtherDesc &&
                        errors.partyRequestingWithdrawalOtherDesc && (
                          <Typography color="error">
                            {errors.partyRequestingWithdrawalOtherDesc}
                          </Typography>
                        )}
                    </Box>
                  )}

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="reasonForWithdrawal">
                      Reason for Withdrawal
                    </InputLabel>
                    <OutlinedInput
                      id="reasonForWithdrawal"
                      name="reasonForWithdrawal"
                      value={values?.reasonForWithdrawal}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.reasonForWithdrawal &&
                      errors.reasonForWithdrawal && (
                        <Typography color="error">
                          {errors.reasonForWithdrawal}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Arbitration Withdrawn On
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="arbitrationWithdrawnOn"
                      selected={values?.arbitrationWithdrawnOn}
                      onChange={(date) =>
                        setFieldValue("arbitrationWithdrawnOn", date)
                      }
                      // minDate={minDate}
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.arbitrationWithdrawnOn &&
                      errors.arbitrationWithdrawnOn && (
                        <Typography color="error">
                          {errors.arbitrationWithdrawnOn}
                        </Typography>
                      )}
                  </Box>
                </>
              )}

              {settings
                ?.find((item) => item?.categoryName === "Arbitration Status")
                ?.Children?.find(
                  (child) =>
                    String(child.generalSettingsID) ===
                    String(values.arbitrationStatus)
                )?.categoryName == "Settled" && (
                <>
                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Settlement Negotiations Initiated On
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="settlementNegotiationsInitiatedOn"
                      selected={values?.settlementNegotiationsInitiatedOn}
                      onChange={(date) =>
                        setFieldValue("settlementNegotiationsInitiatedOn", date)
                      }
                      // minDate={minDate}
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.settlementNegotiationsInitiatedOn &&
                      errors.settlementNegotiationsInitiatedOn && (
                        <Typography color="error">
                          {errors.settlementNegotiationsInitiatedOn}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="mediatorFacilitatorForSettlement">
                      Mediator/Facilitator for Settlement
                    </InputLabel>
                    <OutlinedInput
                      id="mediatorFacilitatorForSettlement"
                      name="mediatorFacilitatorForSettlement"
                      value={values?.mediatorFacilitatorForSettlement}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.mediatorFacilitatorForSettlement &&
                      errors.mediatorFacilitatorForSettlement && (
                        <Typography color="error">
                          {errors.mediatorFacilitatorForSettlement}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Terms of Settlement Agreed On
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="termsOfSettlementAgreedOn"
                      selected={values?.termsOfSettlementAgreedOn}
                      onChange={(date) =>
                        setFieldValue("termsOfSettlementAgreedOn", date)
                      }
                      // minDate={minDate}
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.termsOfSettlementAgreedOn &&
                      errors.termsOfSettlementAgreedOn && (
                        <Typography color="error">
                          {errors.termsOfSettlementAgreedOn}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="finalSettlementAmount">
                      Final Settlement Amount
                    </InputLabel>
                    <OutlinedInput
                      id="finalSettlementAmount"
                      name="finalSettlementAmount"
                      value={values?.finalSettlementAmount}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        handleChange({
                          target: {
                            name: e.target.name,
                            value: numericValue,
                          },
                        });
                      }}
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                    />
                    {touched.finalSettlementAmount &&
                      errors.finalSettlementAmount && (
                        <Typography color="error">
                          {errors.finalSettlementAmount}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Compliance Deadline for Settlement Agreement
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="complianceDeadlineForSettlementAgreement"
                      selected={
                        values?.complianceDeadlineForSettlementAgreement
                      }
                      onChange={(date) =>
                        setFieldValue(
                          "complianceDeadlineForSettlementAgreement",
                          date
                        )
                      }
                      // minDate={minDate}
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.complianceDeadlineForSettlementAgreement &&
                      errors.complianceDeadlineForSettlementAgreement && (
                        <Typography color="error">
                          {errors.complianceDeadlineForSettlementAgreement}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Acknowledgment Of Settlement by All Parties On
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="acknowledgmentOfSettlementByAllPartiesOn"
                      selected={
                        values?.acknowledgmentOfSettlementByAllPartiesOn
                      }
                      onChange={(date) =>
                        setFieldValue(
                          "acknowledgmentOfSettlementByAllPartiesOn",
                          date
                        )
                      }
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.acknowledgmentOfSettlementByAllPartiesOn &&
                      errors.acknowledgmentOfSettlementByAllPartiesOn && (
                        <Typography color="error">
                          {errors.acknowledgmentOfSettlementByAllPartiesOn}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Enforcement of Settlement Agreement Filed On
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="enforcementOfSettlementAgreementFiledOn"
                      selected={values?.enforcementOfSettlementAgreementFiledOn}
                      onChange={(date) =>
                        setFieldValue(
                          "enforcementOfSettlementAgreementFiledOn",
                          date
                        )
                      }
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.enforcementOfSettlementAgreementFiledOn &&
                      errors.enforcementOfSettlementAgreementFiledOn && (
                        <Typography color="error">
                          {errors.enforcementOfSettlementAgreementFiledOn}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Closure of Arbitration Proceedings Due to Settlement On
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="closureOfArbitrationProceedingsDueToSettlementOn"
                      selected={
                        values?.closureOfArbitrationProceedingsDueToSettlementOn
                      }
                      onChange={(date) =>
                        setFieldValue(
                          "closureOfArbitrationProceedingsDueToSettlementOn",
                          date
                        )
                      }
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.closureOfArbitrationProceedingsDueToSettlementOn &&
                      errors.closureOfArbitrationProceedingsDueToSettlementOn && (
                        <Typography color="error">
                          {
                            errors.closureOfArbitrationProceedingsDueToSettlementOn
                          }
                        </Typography>
                      )}
                  </Box>
                </>
              )}

              {settings
                ?.find((item) => item?.categoryName === "Arbitration Status")
                ?.Children?.find(
                  (child) =>
                    String(child.generalSettingsID) ===
                    String(values.arbitrationStatus)
                )?.categoryName === ("Hold" || "Withdrawn" || "Settled") && (
                <>
                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Supporting Documents
                    </Typography>
                    <Button
                      className="col-12 col-sm-12 col-md-6 col-xl-3"
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload files
                      <VisuallyHiddenInput
                        type="file"
                        multiple
                        // accept="application/pdf"
                        onChange={(event) => {
                          const existingFiles =
                            values?.supportingDocuments || [];
                          const newFiles = Array.from(event.target.files);

                          const updatedFiles = [
                            ...new Map(
                              [...existingFiles, ...newFiles].map((file) => [
                                file.name || file?.actualDocName,
                                {
                                  file: file?.file ?? file,
                                  checkListDocID: file?.checkListDocID || null,
                                  caseRowID: file?.caseRowID || null,
                                  caseID: file?.caseID || null,
                                  checklistID: file?.checklistID || null,
                                  genSetID: file?.genSetID || null,
                                  checklistDate: null,
                                  actualDocName:
                                    file.name || file?.actualDocName,
                                  uploadDocName:
                                    file.name || file?.uploadDocName,
                                  documentType: file.type || file.documentType,
                                  documentSize:
                                    file.documentSize || file.size?.toString(),
                                  documentClassification: null,
                                  documentService: null,
                                  serviceCount: null,
                                  comments: null,
                                  createdBy: file?.createdBy || 1,
                                  updatedBy: file?.updatedBy || 1,
                                  isActive: 1,
                                  createdAt:
                                    file?.createdAt ||
                                    new Date()?.toISOString(),
                                  updatedAt:
                                    file?.updatedAt ||
                                    new Date()?.toISOString(),
                                },
                              ])
                            ).values(),
                          ];

                          setFieldValue("supportingDocuments", updatedFiles);
                        }}
                      />
                    </Button>
                    {touched.supportingDocuments &&
                      errors.supportingDocuments && (
                        <Typography color="error">
                          {errors.supportingDocuments}
                        </Typography>
                      )}
                  </Box>

                  {!loading && values.supportingDocuments?.length > 0 ? (
                    <Box sx={{ mt: 2 }}>
                      {values.supportingDocuments?.map((file, index) => (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          sx={{ mb: 1 }}
                        >
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label={file?.actualDocName || file.name}
                              onDelete={() => {
                                if (window.delete) {
                                  const updatedFiles =
                                    values.supportingDocuments.filter(
                                      (_, fileIndex) => fileIndex !== index
                                    );
                                  setFieldValue(
                                    "supportingDocuments",
                                    updatedFiles
                                  );
                                } else {
                                  animateCss(
                                    "info",
                                    "You are not authorized to delete"
                                  );
                                }
                              }}
                            />
                          </Stack>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    loading && (
                      <>
                        {" "}
                        <Skeleton
                          variant="rectangular"
                          width="60%"
                          height={30}
                        />
                        <br />
                        <Skeleton
                          variant="rectangular"
                          width="60%"
                          height={30}
                        />
                        <br />
                        <Skeleton
                          variant="rectangular"
                          width="60%"
                          height={30}
                        />{" "}
                      </>
                    )
                  )}
                </>
              )}

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                  variant="outlined"
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default ArbitrationStatusTracking;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  partiesAndContactsArbitratorDetailsCreateAction,
  partiesAndContactsArbitratorDetailsDeleteAction,
  partiesAndContactsArbitratorDetailsUpdateAction,
  singleCaseDetailsAction,
} from "../../../../redux/actions/Case/caseAction";
import { animateCss } from "../../../TosterAlert";
import { MdDelete } from "react-icons/md";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ArbitratorDetails = ({
  open,
  setOpen,
  expanded,
  setExpanded,
  settings,
  params,

  dispatch,
  handleChange,
}) => {
  const [formValue, setFormValue] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [editForm, setEditForm] = useState(false);

  const rowData = useSelector((state) => state?.case?.editCaseDetails);

  let data = rowData.partiesAndContacts_ArbitratorDetails
    ? JSON.parse(JSON.parse(rowData.partiesAndContacts_ArbitratorDetails))
    : [];

  return (
    <>
      <div className="d-flex row p-0 m-0  col-12 mt-2">
        <form xs={12} sm={12} md={12}>
          <Accordion
            onClick={(event) => {
              event.stopPropagation();
              handleChange(3);
              setOpen(true);
            }}
            expanded={expanded === 3 && open === true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  width: "100%",
                  backgroundColor: "white",
                }}
                xs={12}
                sm={12}
                md={12}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="m-0"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleChange(3);
                    setOpen(false);
                  }}
                >
                  Arbitrator Details
                </Typography>

                {window.edit && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setEditIndex(null);
                      setExpanded(3);
                      setOpenModel(true);
                    }}
                    disabled={data?.length >= 10}
                    className="justify-content-center"
                  >
                    Add Arbitrator Details
                  </Button>
                )}

                {/* <div>
                  {editForm && expanded === 3 && open === true && (
                    <Button
                      className="mx-2"
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={(event) => {
                        event.stopPropagation();
                        setEditForm(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {window?.edit && !editForm && (
                    <div>
                      <Button
                        onClick={(event) => {
                          event.stopPropagation();
                          if (window.edit) {
                            setEditForm(true);
                            setExpanded(3);
                            setOpen(true);
                          } else {
                            animateCss(
                              "info",
                              "You are not authorized to update"
                            );
                          }
                        }}
                        type="button"
                        color="primary"
                        variant="contained"
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div> */}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // alignItems: "end",
                      backgroundColor: "white",
                      flexWrap: "wrap",
                      alignItems: "stretch",
                    }}
                  >
                    {openModel && expanded == 3 && (
                      <ModelForm
                        data={data}
                        settings={settings}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        setOpenModel={setOpenModel}
                        openModel={openModel}
                        editIndex={editIndex}
                        setEditIndex={setEditIndex}
                        dispatch={dispatch}
                        params={params}
                      />
                    )}

                    <Box
                      sx={{
                        display: "grid",
                        // px: 2,
                        gridTemplateColumns: {
                          xs: "1fr",
                          sm: "1fr",
                          md: "1fr",
                        },
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                        },
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Box
                        className="d-flex col-12"
                        // style={{
                        //   overflowX: "auto",
                        //   width: "100%",
                        // }}
                      >
                        <table
                          style={{ overflowWrap: "anywhere" }}
                          className="my-2 table table-striped table-bordered"
                        >
                          <thead
                            style={{
                              fontSize: "14px",
                              fontWeight: 800,
                            }}
                          >
                            <tr>
                              <th scope="col">Arbitrator Number</th>
                              <th scope="col">Arbitrator Name</th>

                              <th scope="col">Arbitrator Type</th>

                              <th scope="col">
                                Arbitrator Address for Communication
                              </th>
                              <th scope="col">Arbitrator Phone Number</th>

                              <th scope="col">Arbitrator Email</th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                  width: "65px",
                                }}
                                className="border"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {data?.map((order, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="col">
                                    <Typography
                                      component="a"
                                      className="m-0"
                                      sx={{
                                        ...(window.edit && {
                                          color: "primary.main",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          fontWeight: 800,
                                        }),
                                        ...(!window.edit && {
                                          color: "secondary.main",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          fontWeight: 800,
                                        }),
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        // if (editForm)
                                        if (window.edit) {
                                          setOpenModel(true);
                                          setEditIndex(index);
                                        } else {
                                          animateCss(
                                            "info",
                                            "You are not authorized to update"
                                          );
                                        }
                                      }}
                                    >
                                      {" "}
                                      Arbitrator {index + 1}
                                    </Typography>
                                  </th>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.arbitratorName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {settings
                                      ?.find(
                                        (item) =>
                                          item?.categoryName ===
                                          "Arbitrator Type"
                                      )
                                      ?.Children?.find(
                                        (child) =>
                                          String(child.generalSettingsID) ===
                                          String(order?.arbitratorType)
                                      )?.categoryName ?? ""}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.arbitratorAddressForCommunication}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.arbitratorPhoneNumber}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.arbitratorEmail}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      color="error"
                                      style={{
                                        marginTop: "4%",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();

                                        if (window.delete) {
                                          enqueueSnackbar(
                                            <>
                                              <div>
                                                Are you sure you want to delete
                                                the record for Arbitrator
                                                Details
                                                {index + 1} ?
                                              </div>
                                            </>,
                                            {
                                              variant: "warning",
                                              onClose: () => {},
                                              anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "right",
                                              },
                                              action: (key) => (
                                                <>
                                                  <StyledButton
                                                    onClick={async () => {
                                                      const value = {
                                                        caseID: params,
                                                        index,
                                                      };
                                                      dispatch(
                                                        partiesAndContactsArbitratorDetailsDeleteAction(
                                                          value
                                                        )
                                                      )
                                                        .unwrap()
                                                        .then((result) => {
                                                          if (result) {
                                                            dispatch(
                                                              singleCaseDetailsAction(
                                                                params
                                                              )
                                                            );
                                                          }
                                                        })
                                                        .catch((error) => {
                                                          console.error(
                                                            "Action rejected with error:",
                                                            error
                                                          );
                                                        });

                                                      closeSnackbar(key);
                                                    }}
                                                  >
                                                    Confirm
                                                  </StyledButton>
                                                  <StyledButton
                                                    onClick={() => {
                                                      closeSnackbar(key);
                                                    }}
                                                  >
                                                    Cancel
                                                  </StyledButton>
                                                </>
                                              ),
                                              autoHideDuration: null,
                                              style: {
                                                backgroundColor: "#f44336",
                                                width: "600px",
                                                color: "#fff",
                                              },
                                            }
                                          );
                                        } else {
                                          animateCss(
                                            "info",
                                            "You are not authorized to delete"
                                          );
                                        }
                                      }}
                                    >
                                      <MdDelete
                                        style={{
                                          fontSize: "24px",
                                        }}
                                      />
                                    </IconButton>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  </Box>

                  {/* Dynamice Data */}
                </LocalizationProvider>
              </>
            </AccordionDetails>
          </Accordion>
        </form>
      </div>
    </>
  );
};

const ModelForm = ({
  formValue,
  setFormValue,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
  dispatch,
  params,
  data,
}) => {
  const editValue = data?.[editIndex] ?? null;

  const { id } = useParams();

  const initialValues = {
    arbitratorName: editValue?.arbitratorName ?? null,
    arbitratorType: editValue?.arbitratorType ?? null,
    arbitratorPhoneNumber: editValue?.arbitratorPhoneNumber ?? null,
    arbitratorEmail: editValue?.arbitratorEmail ?? null,
    arbitratorAddressForCommunication:
      editValue?.arbitratorAddressForCommunication ?? null,
    arbitratorAddressLandmark: editValue?.arbitratorAddressLandmark ?? null,
    villageTaluk: editValue?.villageTaluk ?? null,
    districtCity: editValue?.districtCity ?? null,
    state: editValue?.state ?? null,
    country: editValue?.country ?? null,
    postalCode: editValue?.postalCode ?? null,
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={false}
          onSubmit={async (values, actions) => {
            try {
              const data = {
                arbitratorName: values?.arbitratorName ?? null,
                arbitratorType: values?.arbitratorType ?? null,
                arbitratorPhoneNumber: values?.arbitratorPhoneNumber ?? null,
                arbitratorEmail: values?.arbitratorEmail ?? null,
                arbitratorAddressForCommunication:
                  values?.arbitratorAddressForCommunication ?? null,
                arbitratorAddressLandmark:
                  values?.arbitratorAddressLandmark ?? null,
                villageTaluk: values?.villageTaluk ?? null,
                districtCity: values?.districtCity ?? null,
                state: values?.state ?? null,
                country: values?.country ?? null,
                postalCode: values?.postalCode ?? null,
                caseID: id,
                editIndex,
              };

              if (editIndex == null) {
                dispatch(partiesAndContactsArbitratorDetailsCreateAction(data))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();
                    setOpenModel(false);

                    dispatch(singleCaseDetailsAction(params));
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                dispatch(partiesAndContactsArbitratorDetailsUpdateAction(data))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();
                    setOpenModel(false);

                    dispatch(singleCaseDetailsAction(params));
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            isSubmitting,
          }) => (
            <form
              onChange={(event) => {
                event.stopPropagation();
              }}
              onSubmit={handleSubmit}
            >
              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="arbitratorName">
                  Arbitrator Name
                </InputLabel>
                <OutlinedInput
                  id="arbitratorName"
                  name="arbitratorName"
                  value={values?.arbitratorName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.arbitratorName && errors.arbitratorName && (
                  <Typography color="error">{errors.arbitratorName}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="arbitratorType">
                  Arbitrator Type
                </InputLabel>
                <Select
                  labelId="arbitratorType"
                  id="arbitratorType"
                  name="arbitratorType"
                  value={values?.arbitratorType || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Arbitrator Type")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.arbitratorType && errors.arbitratorType && (
                  <Typography color="error">{errors.arbitratorType}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="arbitratorPhoneNumber">
                  Arbitrator Phone Number
                </InputLabel>
                <OutlinedInput
                  id="arbitratorPhoneNumber"
                  name="arbitratorPhoneNumber"
                  value={values?.arbitratorPhoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.arbitratorPhoneNumber &&
                  errors.arbitratorPhoneNumber && (
                    <Typography color="error">
                      {errors.arbitratorPhoneNumber}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="arbitratorEmail">
                  Arbitrator Email
                </InputLabel>
                <OutlinedInput
                  id="arbitratorEmail"
                  name="arbitratorEmail"
                  value={values?.arbitratorEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.arbitratorEmail && errors.arbitratorEmail && (
                  <Typography color="error">
                    {errors.arbitratorEmail}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="arbitratorAddressForCommunication">
                  Arbitrator Address for Communication
                </InputLabel>
                <OutlinedInput
                  id="arbitratorAddressForCommunication"
                  name="arbitratorAddressForCommunication"
                  value={values?.arbitratorAddressForCommunication}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.arbitratorAddressForCommunication &&
                  errors.arbitratorAddressForCommunication && (
                    <Typography color="error">
                      {errors.arbitratorAddressForCommunication}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="arbitratorAddressLandmark">
                  Arbitrator Address Landmark
                </InputLabel>
                <OutlinedInput
                  id="arbitratorAddressLandmark"
                  name="arbitratorAddressLandmark"
                  value={values?.arbitratorAddressLandmark}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.arbitratorAddressLandmark &&
                  errors.arbitratorAddressLandmark && (
                    <Typography color="error">
                      {errors.arbitratorAddressLandmark}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="villageTaluk">Village/Taluk</InputLabel>
                <OutlinedInput
                  id="villageTaluk"
                  name="villageTaluk"
                  value={values?.villageTaluk}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.villageTaluk && errors.villageTaluk && (
                  <Typography color="error">{errors.villageTaluk}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="districtCity">District/City</InputLabel>
                <OutlinedInput
                  id="districtCity"
                  name="districtCity"
                  value={values?.districtCity}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.districtCity && errors.districtCity && (
                  <Typography color="error">{errors.districtCity}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="state">State</InputLabel>
                <OutlinedInput
                  id="state"
                  name="state"
                  value={values?.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.state && errors.state && (
                  <Typography color="error">{errors.state}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="country">Country</InputLabel>
                <OutlinedInput
                  id="country"
                  name="country"
                  value={values?.country}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.country && errors.country && (
                  <Typography color="error">{errors.country}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="postalCode">Postal Code (PIN)</InputLabel>
                <OutlinedInput
                  id="postalCode"
                  name="postalCode"
                  value={values?.postalCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.postalCode && errors.postalCode && (
                  <Typography color="error">{errors.postalCode}</Typography>
                )}
              </Box>

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                  variant="outlined"
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default ArbitratorDetails;

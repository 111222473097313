// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  DownOutlined,
  UserOutlined,
  UserSwitchOutlined,
  ApartmentOutlined,
  FileDoneOutlined,
  CodepenOutlined,
  AppstoreAddOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const application = {
  id: "Applications",
  title: "Applications",
  type: "group",
  children: [
    {
      id: "Applications",
      title: "Applications",
      type: "collapse",
      url: "/case",
      icon: AppstoreAddOutlined,
      children: [
        {
          id: "Applications",
          title: "Cases",
          type: "item",
          url: "/case/caseDetails",
          icon: CodepenOutlined,
        },
        {
          id: "Applications",
          title: "View Case",
          type: "",
          url: "/case/caseDetails/:Id",
          icon: CodepenOutlined,
          parant: "Cases",
          parantUrl: "/case/caseDetails",
          breadcrumbs: true,
        },
        {
          id: "Applications",
          title: "Case Bulk Update",
          type: "",
          url: "/case/caseDetails/update",
          icon: CodepenOutlined,
          parant: "Cases",
          parantUrl: "/case/caseDetails",
          breadcrumbs: true,
        },
        {
          id: "Applications",
          title: "Tasks",
          type: "item",
          url: "/case/tasks",
          icon: CodepenOutlined,
        },
        {
          id: "Applications",
          title: "Notifications",
          type: "item",
          url: "/case/notifications",
          icon: CodepenOutlined,
        },
        // {
        //   id: "Application",
        //   title: "Case Template",
        //   type: "item",
        //   url: "/case/caseTemplate",
        //   icon: CodepenOutlined,
        // },
        {
          id: "Applications",
          title: "Documents",
          type: "item",
          url: "/case/caseDocuments",
          icon: CodepenOutlined,
        },
        {
          id: "Applications",
          title: "Case Details Update",
          type: "item",
          url: "/case/caseDetailsUpdate",
          icon: CodepenOutlined,
        },
        {
          id: "Applications",
          title: "Allotments",
          type: "item",
          url: "/case/caseallotments",
          icon: CodepenOutlined,
        },
        {
          id: "Applications",
          title: "Create Allotments",
          type: "",
          url: "/case/caseallotments/id",
          icon: CodepenOutlined,
          parant: "Allotments",
          breadcrumbs: true,
        },
        {
          id: "Applications",
          title: "Users List",
          type: "item",
          url: "/case/users",
          icon: CodepenOutlined,
        },
        {
          id: "Applications",
          title: "Reports",
          type: "item",
          url: "/case/reports",
          icon: CodepenOutlined,
        },

        {
          id: "Applications",
          title: "Billing & Payments",
          type: "item",
          url: "/case/billingandpayments",
          icon: CodepenOutlined,
        },

        {
          id: "Applications",
          title: " IT Support ",
          type: "item",
          url: "/case/itsupport",
          icon: CodepenOutlined,
        },

        // {
        //   id: "Case Info",
        //   title: "Notice Template",
        //   type: "item",
        //   url: "/case/caseTemplate",
        //   icon: CodepenOutlined,
        // },
        {
          id: "Application",
          title: "Case Document",
          type: "item",
          url: "/case/caseDocuments",
          icon: CodepenOutlined,
        },
        // {
        //   id: "Application",
        //   title: "PDF Document",
        //   type: "item",
        //   url: "/case/pdfdocument",
        //   icon: CodepenOutlined,
        // },
      ],
    },
  ],
};

export default application;

import axios from "axios";
import { api } from "./api_route";

export const caseMapping = async (values) => {
  try {
    const result = await axios.post(`${api}/casemapping/upload`, values);

    // return result;
    return {
      data: result.data,
      status: result.status,
    };
  } catch (error) {
    return [];
  }
};

export const allCaseMappingDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseMapping/allDetails`, values);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const selectedCaseMappingDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseMapping/selectDetails`, values);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const caseMappingDetailUpdate = async (values) => {
  try {
    const result = await axios.post(`${api}/caseMapping/update`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const caseMappingDetailisActive = async (values) => {
  try {
    const result = await axios.post(`${api}/caseMapping/isActive`, values);
    return result;
  } catch (error) {
    return [];
  }
};

export const organizationActiveCaseTemplate = async (values) => {
  try {
    const result = await axios.post(`${api}/casemapping/caseTemplate`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const allCaseDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/`, values);
    return result;
  } catch (error) {
    return [];
  }
};

export const singleCaseDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/singleCase`, {
      values,
    });

    return JSON.parse(result?.data?.data?.[0]?.result);
  } catch (error) {
    return [];
  }
};

export const caseDetailsBulkDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/caseDetailsBulkDelete`,
      {
        values,
      }
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const allCaseType = async (values) => {
  try {
    const result = await axios.get(`${api}/caseDetails/caseTypes`);

    return result.data;
  } catch (error) {
    return [];
  }
};

// export const organizationCaseDetails = async (values) => {
//   try {
//     const result = await axios.post(
//       `${api}/caseDetails/organzationCase`,
//       values
//     );

//     return result;
//   } catch (error) {
//     return [];
//   }
// };

export const updateCaseDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/update`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const caseDetailHistory = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/caseHistory`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const caseDetailsFindCaseID = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/findCaseID`, values);
    return result;
  } catch (error) {
    return [];
  }
};

export const caseTrackingUpdate = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/casetracker`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const updateUserCaseFilter = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateUserCaseFilter`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateCasePrimaryInformation = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateCasePrimaryInformation`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateInitiationOfArbitration = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateInitiationOfArbitration`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateAppointmentOfArbitratorSec11and12 = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateAppointmentOfArbitratorSec11and12`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationInterimOrdersReliefsAwards = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationInterimOrdersReliefsAwards`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationWitnessEvidenceAndExpertReport = async (
  values
) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationWitnessEvidenceAndExpertReport`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationHoldWithdrawalAndSettlement = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationHoldWithdrawalAndSettlement`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationEnforcementAndAppeals = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationEnforcementAndAppeals`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationFeePaymentStatus = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationFeePaymentStatus`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationNoticesKeyDates = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationNoticesKeyDates`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationDurationAndTimelineManagement = async (
  values
) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationDurationAndTimelineManagement`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationClausAndAgreement = async (values) => {
  try {
    var files = new FormData();

    values?.uploadedFiles?.map((document, i) => {
      files.append("file", document);
    });

    files.append("caseDetails", JSON.stringify(values));
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationClausAndAgreement`,
      files
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateSec17InterimOrders = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateSec17InterimOrders`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateSec9InterimOrders = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateSec9InterimOrders`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateInterimOrders = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateInterimOrders`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateFinalAward = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateFinalAward`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const allAgreementDocumentList = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/allAgreementDocumentList`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const allAgreementDocumentDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/allAgreementDocumentDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

//Case Column Filter

export const filterColumn = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/filterColumn`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const caseBulkFilter = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/caseBulkFilter`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const pdfIDMatching = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/pdfIDMatching`, values);

    return result;
  } catch (error) {
    return [];
  }
};

//Dashboard Case Count

export const dashboardCaseDetailsCounts = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/dashboardCaseDetailsCountslogin`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const preTrailCaseDetailsCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/createPreTrialApplication`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};
export const preTrailCaseDetailsUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updatePreTrialApplication`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const preTrailCaseDetailsDocuments = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/preTrailCaseDetailsDocuments`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const preTrailCaseDetailsDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/preTrailCaseDetailsDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const claimantsListOfEvidenceCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/claimantsListOfEvidenceCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const claimantsListOfEvidenceDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/claimantsListOfEvidenceDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const claimantsListOfEvidenceUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/claimantsListOfEvidenceUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const respondentsListOfEvidenceCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/respondentsListOfEvidenceCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const respondentsListOfEvidenceDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/respondentsListOfEvidenceDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const respondentsListOfEvidenceUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/respondentsListOfEvidenceUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const claimantSideWitnessCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/claimantSideWitnessCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const claimantSideWitnessUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/claimantSideWitnessUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const claimantSideWitnessDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/claimantSideWitnessDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const respondentWitnessCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/respondentWitnessCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const respondentWitnessUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/respondentWitnessUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const respondentWitnessDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/respondentWitnessDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const claimantSideArgumentsCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/claimantSideArgumentsCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const claimantSideArgumentsUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/claimantSideArgumentsUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const claimantSideArgumentsDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/claimantSideArgumentsDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const respondentSideArgumentsCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/respondentSideArgumentsCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const respondentSideArgumentsUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/respondentSideArgumentsUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const respondentSideArgumentsDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/respondentSideArgumentsDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const dispositionsCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/dispositionsCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const dispositionsUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/dispositionsUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const dispositionsDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/dispositionsDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const enforcementsCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/enforcementsCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const enforcementsUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/enforcementsUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const enforcementsDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/enforcementsDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const appealCreate = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/appealCreate`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const appealUpdate = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/appealUpdate`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const appealDelete = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/appealDelete`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const arbitrationStatusTrackingCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/arbitrationStatusTrackingCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const arbitrationStatusTrackingUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/arbitrationStatusTrackingUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const arbitrationStatusTrackingDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/arbitrationStatusTrackingDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const arbitrationDurationAndTimelineMonitoring = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/arbitrationDurationAndTimelineMonitoring`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const partiesAndContactsClaimantDetailsCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/partiesAndContactsClaimantDetailsCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const partiesAndContactsClaimantDetailsUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/partiesAndContactsClaimantDetailsUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const partiesAndContactsClaimantDetailsDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/partiesAndContactsClaimantDetailsDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const partiesAndContactsRespondentDetailsCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/partiesAndContactsRespondentDetailsCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};
export const partiesAndContactsRespondentDetailsUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/partiesAndContactsRespondentDetailsUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const partiesAndContactsRespondentDetailsDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/partiesAndContactsRespondentDetailsDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const partiesAndContactsArbitratorDetailsCreate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/partiesAndContactsArbitratorDetailsCreate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const partiesAndContactsArbitratorDetailsUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/partiesAndContactsArbitratorDetailsUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const partiesAndContactsArbitratorDetailsDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/partiesAndContactsArbitratorDetailsDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const caseDetailsBulkUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/caseDetailsBulkUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

// project import
import { handlerActiveItem, useGetMenuMaster } from "../../../../../api/menu";
import { useDispatch } from "react-redux";
import { OpenNavGroup } from "../../../../../redux/slices/User/usersSlice";

export default function NavItem({ item, level }) {
  const theme = useTheme();

  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;
  const openItem = menuMaster.openedItem;

  let itemTarget = "_self";
  if (item?.target) {
    itemTarget = "_blank";
  }
  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item?.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item?.url, target: itemTarget };
  }

  const Icon = item?.icon;
  const itemIcon = item?.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  const { pathname } = useLocation();
  const isSelected =
    !!matchPath({ path: item?.url, end: false }, pathname) ||
    openItem === item?.id;

  // active menu item on page load
  useEffect(() => {
    if (pathname === item?.url) handlerActiveItem(item?.id);
    // eslint-disable-next-line
  }, [pathname]);
  const dispatch = useDispatch();

  const textColor = theme.palette.common.white;
  const iconSelectedColor = theme.palette.common.white;

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item?.disabled}
      onClick={() => {
        handlerActiveItem(item?.id);
        dispatch(OpenNavGroup(item?.id));
      }}
      selected={item?.url === window.location.pathname}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        ...(drawerOpen && {
          "&:hover": {
            bgcolor: "#0b30f087",
            //  "primary.lighter",
          },
          "&.Mui-selected": {
            bgcolor: "#0b30f087",
            // "primary.lighter",
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: "white",
            // iconSelectedColor,
            "&:hover": {
              color: "white",
              //  iconSelectedColor,
              bgcolor: "#0b30f087",
              //  "primary.lighter",
            },
          },
        }),
        ...(!drawerOpen && {
          "&:hover": {
            bgcolor: "transparent",
          },
          "&.Mui-selected": {
            "&:hover": {
              bgcolor: "transparent",
            },
            bgcolor: "transparent",
          },
        }),
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color:
              item?.url === window.location.pathname
                ? iconSelectedColor
                : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                bgcolor: "secondary.lighter",
              },
            }),
            ...(!drawerOpen &&
              item?.url === window.location.pathname && {
                bgcolor: "primary.lighter",
                "&:hover": {
                  bgcolor: "primary.lighter",
                },
              }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{
                color:
                  item?.url === window.location.pathname
                    ? iconSelectedColor
                    : textColor,
              }}
            >
              {item?.title}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item?.chip && (
        <Chip
          color={item?.chip?.color}
          variant={item?.chip?.variant}
          size={item?.chip?.size}
          label={item?.chip?.label}
          avatar={item?.chip?.avatar && <Avatar>{item?.chip?.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
}

NavItem.propTypes = { item: PropTypes.object, level: PropTypes.number };

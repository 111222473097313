import { Card, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import DocumentDrawer from "../../Drawer";
import ClaimantDetails from "./PartiesAndContacts/ClaimantDetails";
import RespondentDetails from "./PartiesAndContacts/RespondentDetails";
import ArbitratorDetails from "./PartiesAndContacts/ArbitratorDetails";

const PartiesAndContacts = () => {
  const [open, setOpen] = useState(false);
  const [viewDocument, setViewDocument] = useState(false);

  let formValue;

  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => {
    setExpanded(panel);
    setOpen(false);
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const params = { caseID: id };
  const user = useSelector((state) => state.login.data);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);
  const rowData = useSelector((state) => state?.case?.editCaseDetails);

  const document = useSelector(
    (state) => state?.case?.preTrailCaseDetailsDocuments
  );

  // useEffect(() => {
  //   dispatch(
  //     allAgreementDocumentListAction({
  //       value: "Arbitration Agreement",
  //       caseID: id,
  //     })
  //   );
  // }, []);

  return (
    <>
      <Card
        variant="outlined"
        className=" mt-3 md-6 xl-6 xxl-6"
        sx={{ maxWidth: "100%" }}
        xs={12}
        sm={12}
        md={6}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="m-0 px-2  text-secondary"
        >
          Parties & Contacts
        </Typography>

        <div
          className="d-flex row"
          spacing={2}
          style={{ backgroundColor: "#fbfbfc" }}
        >
          <ClaimantDetails
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            rowData={rowData}
            params={params}
            user={user}
            dispatch={dispatch}
            formValue={formValue}
            settings={settings}
            handleChange={handleChange}
            viewDocument={viewDocument}
            setViewDocument={setViewDocument}
          />

          <RespondentDetails
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            rowData={rowData}
            params={params}
            user={user}
            dispatch={dispatch}
            formValue={formValue}
            settings={settings}
            handleChange={handleChange}
            viewDocument={viewDocument}
            setViewDocument={setViewDocument}
          />

          <ArbitratorDetails
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
            rowData={rowData}
            params={params}
            user={user}
            dispatch={dispatch}
            formValue={formValue}
            settings={settings}
            handleChange={handleChange}
            viewDocument={viewDocument}
            setViewDocument={setViewDocument}
          />
        </div>
      </Card>
      <DocumentDrawer
        open={viewDocument}
        setOpen={setViewDocument}
        checklistDocuments={document}
      />
    </>
  );
};

export default PartiesAndContacts;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Slide,
  Stack,
  styled,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  appealCreateAction,
  appealDeleteAction,
  appealUpdateAction,
  preTrailCaseDetailsDocumentsAction,
  singleCaseDetailsAction,
  updateArbitrationEnforcementAndAppealsAction,
} from "../../../../redux/actions/Case/caseAction";
import { animateCss } from "../../../TosterAlert";
import { IoIosOpen } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DatePicker from "react-datepicker";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Appeals = ({
  open,
  setOpen,
  expanded,
  setExpanded,
  settings,
  params,
  user,
  dispatch,
  handleChange,
  viewDocument,
  setViewDocument,
  rowData,
}) => {
  const [formValue, setFormValue] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [hovered, setHovered] = useState({});

  const { id } = useParams();
  let data;
  data = rowData.appeal ? JSON.parse(JSON.parse(rowData.appeal)) : [];

  return (
    <>
      <Formik
        initialValues={{
          enforcementAppFiledOn: !rowData?.caseID
            ? null
            : rowData?.EnforcementAppFiledOn,
          enforcementStatus: !rowData?.caseID
            ? null
            : rowData?.EnforcementStatus,
          appealFiledOn: !rowData?.caseID ? null : rowData?.AppealFiledOn,
          appealStatus: !rowData?.caseID ? null : rowData?.AppealStatus,
          params,
          user,
        }}
        onSubmit={async (values, actions) => {
          try {
            dispatch(updateArbitrationEnforcementAndAppealsAction(values));

            setTimeout(() => {
              dispatch(singleCaseDetailsAction(params));
            }, 1000);
          } catch (error) {}
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <div className="d-flex row p-0 m-0  col-12 mt-2">
              <form onSubmit={handleSubmit} xs={12} sm={12} md={12}>
                <Accordion expanded={expanded == 3 && open === true}>
                  <AccordionSummary
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpen(true);
                      setExpanded(3);
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0"
                      >
                        Appeals
                      </Typography>

                      {window.edit && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setEditIndex(null);
                            setExpanded(3);
                            setOpenModel(true);
                          }}
                          disabled={data?.length >= 5}
                          className="justify-content-center"
                        >
                          Add Appeal Record
                        </Button>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end",
                            backgroundColor: "white",
                            flexWrap: "wrap",
                            justifyContent: "end",
                          }}
                        >
                          {openModel && (
                            <ModelForm
                              data={data}
                              settings={settings}
                              formValue={formValue}
                              setFormValue={setFormValue}
                              setOpenModel={setOpenModel}
                              openModel={openModel}
                              editIndex={editIndex}
                              setEditIndex={setEditIndex}
                              dispatch={dispatch}
                              params={params}
                            />
                          )}

                          <Box
                            sx={{
                              display: "grid",
                              // px: 2,
                              gridTemplateColumns: {
                                xs: "1fr",
                                sm: "1fr",
                                md: "1fr",
                              },
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "100%",
                              },
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Box
                              className="d-flex col-12"
                              // style={{
                              //   overflowX: "auto",
                              //   width: "100%",
                              // }}
                            >
                              <table
                                style={{ overflowWrap: "anywhere" }}
                                className="my-2 table table-striped table-bordered"
                              >
                                <thead
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 800,
                                  }}
                                >
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Record
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Status
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Appeal Document
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                        width: "65px",
                                      }}
                                      className="border"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {data?.map((order, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <Typography
                                            component="a"
                                            className="m-0"
                                            sx={{
                                              ...(window.edit && {
                                                color: "primary.main",
                                                cursor: "pointer",
                                                textDecoration: "none",
                                                fontWeight: 800,
                                              }),
                                              ...(!window.edit && {
                                                color: "secondary.main",
                                                cursor: "pointer",
                                                textDecoration: "none",
                                                fontWeight: 800,
                                              }),
                                            }}
                                            onClick={() => {
                                              if (window.edit) {
                                                setOpenModel(true);
                                                setEditIndex(index);
                                              } else {
                                                animateCss(
                                                  "info",
                                                  "You are not authorized to update"
                                                );
                                              }

                                              const uniqueDocumentId = [
                                                ...(data[index]
                                                  ?.attachAppealDocument || []),
                                              ];

                                              const document = {
                                                uniqueDocumentId,
                                              };
                                              dispatch(
                                                preTrailCaseDetailsDocumentsAction(
                                                  document
                                                )
                                              );
                                            }}
                                          >
                                            Appeal Type {index + 1}
                                          </Typography>{" "}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {settings
                                            ?.find(
                                              (item) =>
                                                item?.categoryName ===
                                                "EP Status"
                                            )
                                            ?.Children?.find(
                                              (child) =>
                                                String(
                                                  child.generalSettingsID
                                                ) ===
                                                String(order?.appealStatus)
                                            )?.categoryName ?? ""}
                                        </td>

                                        <td
                                          style={{
                                            fontSize: "12px",
                                            textAlign: "start",
                                          }}
                                        >
                                          {order?.attachAppealDocument?.length >
                                            0 && (
                                            <Button
                                              onClick={() => {
                                                setViewDocument(true);
                                                const documentId = data?.[index]
                                                  ? [
                                                      ...(data[index]
                                                        ?.attachAppealDocument ||
                                                        []),
                                                    ]
                                                  : [];
                                                const uniqueDocumentId = [
                                                  ...new Set(documentId),
                                                ];
                                                const document = {
                                                  uniqueDocumentId,
                                                  id,
                                                };
                                                dispatch(
                                                  preTrailCaseDetailsDocumentsAction(
                                                    document
                                                  )
                                                );
                                              }}
                                              aria-label="attach"
                                              onMouseEnter={() =>
                                                setHovered({
                                                  id: index,
                                                  open: true,
                                                  key: "Attachment",
                                                })
                                              }
                                              onMouseLeave={() =>
                                                setHovered({
                                                  id: index,
                                                  open: false,
                                                  key: "Attachment",
                                                })
                                              }
                                            >
                                              <IoIosOpen className="fs-4 text-primary" />{" "}
                                              <span
                                                className={`transition-opacity duration-500 ${
                                                  hovered.id === index &&
                                                  hovered.open &&
                                                  hovered.key === "Attachment"
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                                }`}
                                              >
                                                {" "}
                                                View Attachment
                                              </span>
                                            </Button>
                                          )}
                                        </td>
                                        <td
                                          className={
                                            data?.length - 1 === index
                                              ? "d-flex"
                                              : ""
                                          }
                                        >
                                          <IconButton
                                            aria-label="delete"
                                            className="p-0"
                                            color="error"
                                            style={{
                                              marginTop: "4%",
                                            }}
                                            onClick={() => {
                                              if (window.delete) {
                                                enqueueSnackbar(
                                                  <>
                                                    <div>
                                                      Are you sure you want to
                                                      delete the record and its
                                                      associated document for
                                                      Appeal Type {index + 1} ?
                                                    </div>
                                                  </>,
                                                  {
                                                    variant: "warning",
                                                    onClose: () => {},
                                                    anchorOrigin: {
                                                      vertical: "top",
                                                      horizontal: "right",
                                                    },
                                                    action: (key) => (
                                                      <>
                                                        <StyledButton
                                                          onClick={async () => {
                                                            const output =
                                                              formValue.filter(
                                                                (_, i) =>
                                                                  i !== index
                                                              );

                                                            const value = {
                                                              caseID: params,
                                                              index,
                                                            };
                                                            dispatch(
                                                              appealDeleteAction(
                                                                value
                                                              )
                                                            )
                                                              .unwrap()
                                                              .then(
                                                                (result) => {
                                                                  if (result) {
                                                                    dispatch(
                                                                      singleCaseDetailsAction(
                                                                        params
                                                                      )
                                                                    );
                                                                  }
                                                                }
                                                              )
                                                              .catch(
                                                                (error) => {
                                                                  console.error(
                                                                    "Action rejected with error:",
                                                                    error
                                                                  );
                                                                }
                                                              );

                                                            closeSnackbar(key);
                                                          }}
                                                        >
                                                          Confirm
                                                        </StyledButton>
                                                        <StyledButton
                                                          onClick={() => {
                                                            closeSnackbar(key);
                                                          }}
                                                        >
                                                          Cancel
                                                        </StyledButton>
                                                      </>
                                                    ),
                                                    autoHideDuration: null,
                                                    style: {
                                                      backgroundColor:
                                                        "#f44336",
                                                      width: "600px",
                                                      color: "#fff",
                                                    },
                                                  }
                                                );
                                              } else {
                                                animateCss(
                                                  "info",
                                                  "You are not authorized to delete"
                                                );
                                              }
                                            }}
                                          >
                                            <MdDelete
                                              style={{
                                                fontSize: "24px",
                                              }}
                                            />
                                          </IconButton>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Box>
                          </Box>
                        </Box>

                        {/* Dynamice Data */}
                      </LocalizationProvider>
                    </>
                  </AccordionDetails>
                </Accordion>
              </form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

const ModelForm = ({
  formValue,
  setFormValue,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
  dispatch,
  params,
  data,
  rowData,
}) => {
  const document = useSelector(
    (state) => state?.case?.preTrailCaseDetailsDocuments
  );

  const loading = useSelector((state) => state?.case.loading);

  const editValue = data?.[editIndex];

  const { id } = useParams();

  let initialValues = {
    appealFilingDate: editValue?.appealFilingDate ?? "",
    appealType: editValue?.appealType ?? "",
    appealingTypeOtherDesc: editValue?.appealingTypeOtherDesc ?? "",
    appealingParty: editValue?.appealingParty ?? "",
    appealingPartyOtherDesc: editValue?.appealingPartyOtherDesc ?? "",
    APPetitionersCounsel: editValue?.APPetitionersCounsel ?? "",
    APRespondentsCounsel: editValue?.APRespondentsCounsel ?? "",
    appealForum: editValue?.appealForum ?? "",
    appealJurisdiction: editValue?.appealJurisdiction ?? "",
    groundsForAppeal: editValue?.groundsForAppeal ?? "",
    firstAPHearingDate: editValue?.firstAPHearingDate ?? "",
    previousAPHearingDate: editValue?.previousAPHearingDate ?? "",
    nextAPHearingDate: editValue?.nextAPHearingDate ?? "",
    appealStatus: editValue?.appealStatus ?? "",
    appealDecisionDate: editValue?.appealDecisionDate ?? "",
    appealOutcome: editValue?.appealOutcome ?? "",
    appealOutcomeOther: editValue?.appealOutcomeOther ?? "",
    detailsOfAppealJudgment: editValue?.detailsOfAppealJudgment ?? "",
    furtherActionPostAppeal: editValue?.furtherActionPostAppeal ?? "",
    appealClosureDate: editValue?.appealClosureDate ?? "",
    attachAppealDocument: editIndex !== null ? document : [],
    editIndex,
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            try {
              const form = new FormData();
              form.append("caseID", id);
              form.append("appealFilingDate", values.appealFilingDate);
              form.append("appealType", values.appealType);
              form.append(
                "appealingTypeOtherDesc",
                values.appealingTypeOtherDesc
              );
              form.append("appealingParty", values.appealingParty);
              form.append(
                "appealingPartyOtherDesc",
                values.appealingPartyOtherDesc
              );
              form.append("APPetitionersCounsel", values.APPetitionersCounsel);
              form.append("APRespondentsCounsel", values.APRespondentsCounsel);
              form.append("appealForum", values.appealForum);
              form.append("appealJurisdiction", values.appealJurisdiction);
              form.append("groundsForAppeal", values.groundsForAppeal);
              form.append("firstAPHearingDate", values.firstAPHearingDate);
              form.append(
                "previousAPHearingDate",
                values.previousAPHearingDate
              );
              form.append("nextAPHearingDate", values.nextAPHearingDate);
              form.append("appealStatus", values.appealStatus);
              form.append("appealDecisionDate", values.appealDecisionDate);
              form.append("appealOutcome", values.appealOutcome);
              form.append("appealOutcomeOther", values.appealOutcomeOther);
              form.append(
                "detailsOfAppealJudgment",
                values.detailsOfAppealJudgment
              );
              form.append(
                "furtherActionPostAppeal",
                values.furtherActionPostAppeal
              );
              form.append("appealClosureDate", values.appealClosureDate);

              if (values.attachAppealDocument) {
                values.attachAppealDocument.forEach((file) => {
                  if (file?.caseID) {
                    form.append(
                      `attachAppealDocumentsOld`,
                      file?.checkListDocID
                    );
                  } else {
                    form.append(`Appeal Document Attachment`, file?.file);
                  }
                });
              }

              if (editIndex == null) {
                setOpenModel(false);
                dispatch(appealCreateAction(form))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();

                    if (result) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                setOpenModel(false);
                const updatedData = [...data];
                updatedData[editIndex] = values;
                setFormValue(updatedData);
                form.append("editIndex", editIndex);

                dispatch(appealUpdateAction(form))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();
                    if (result) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Appeal Filing Date
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="appealFilingDate"
                  selected={values?.appealFilingDate}
                  onChange={(date) => setFieldValue("appealFilingDate", date)}
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.appealFilingDate && errors.appealFilingDate && (
                  <Typography color="error">
                    {errors.appealFilingDate}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="appealType">Appeal Type</InputLabel>
                <Select
                  labelId="appealType"
                  id="appealType"
                  name="appealType"
                  value={values?.appealType || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) => item?.categoryName === "Disposition Authority"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.appealType && errors.appealType && (
                  <Typography color="error">{errors.appealType}</Typography>
                )}
              </Box>

              {settings
                ?.find((item) => item?.categoryName === "Appeal Type")
                ?.Children?.find(
                  (child) =>
                    String(child.generalSettingsID) ===
                    String(values.appealType)
                )?.categoryName === "Other" && (
                <Box sx={{ display: "grid", mb: 2 }}>
                  <InputLabel htmlFor="appealingTypeOtherDesc">
                    Appealing Type Other
                  </InputLabel>
                  <OutlinedInput
                    id="appealingTypeOtherDesc"
                    name="appealingTypeOtherDesc"
                    value={values?.appealingTypeOtherDesc}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.appealingTypeOtherDesc &&
                    errors.appealingTypeOtherDesc && (
                      <Typography color="error">
                        {errors.appealingTypeOtherDesc}
                      </Typography>
                    )}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="appealingParty">
                  Appealing Party
                </InputLabel>
                <Select
                  labelId="appealingParty"
                  id="appealingParty"
                  name="appealingParty"
                  value={values?.appealingParty || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "EP Type")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.appealingParty && errors.appealingParty && (
                  <Typography color="error">{errors.appealingParty}</Typography>
                )}
              </Box>

              {settings
                ?.find((item) => item?.categoryName === "Appealing Party")
                ?.Children?.find(
                  (child) =>
                    String(child.generalSettingsID) ===
                    String(values.appealingParty)
                )?.categoryName == "Other" && (
                <Box sx={{ display: "grid", mb: 2 }}>
                  <InputLabel htmlFor="appealingPartyOtherDesc">
                    Appealing Party Other
                  </InputLabel>
                  <OutlinedInput
                    id="appealingPartyOtherDesc"
                    name="appealingPartyOtherDesc"
                    value={values?.appealingPartyOtherDesc}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.appealingPartyOtherDesc &&
                    errors.appealingPartyOtherDesc && (
                      <Typography color="error">
                        {errors.appealingPartyOtherDesc}
                      </Typography>
                    )}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="APPetitionersCounsel">
                  AP Petitioner’s Counsel
                </InputLabel>
                <OutlinedInput
                  id="APPetitionersCounsel"
                  name="APPetitionersCounsel"
                  value={values?.APPetitionersCounsel}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.APPetitionersCounsel &&
                  errors.APPetitionersCounsel && (
                    <Typography color="error">
                      {errors.APPetitionersCounsel}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="APRespondentsCounsel">
                  AP Respondent’s Counsel
                </InputLabel>
                <OutlinedInput
                  id="APRespondentsCounsel"
                  name="APRespondentsCounsel"
                  value={values?.APRespondentsCounsel}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.APRespondentsCounsel &&
                  errors.APRespondentsCounsel && (
                    <Typography color="error">
                      {errors.APRespondentsCounsel}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="appealForum">Appeal Forum</InputLabel>
                <OutlinedInput
                  id="appealForum"
                  name="appealForum"
                  value={values?.appealForum}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.appealForum && errors.appealForum && (
                  <Typography color="error">{errors.appealForum}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="appealJurisdiction">
                  Appeal Jurisdiction
                </InputLabel>
                <OutlinedInput
                  id="appealJurisdiction"
                  name="appealJurisdiction"
                  value={values?.appealJurisdiction}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.appealJurisdiction && errors.appealJurisdiction && (
                  <Typography color="error">
                    {errors.appealJurisdiction}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="groundsForAppeal">
                  Grounds for Appeal
                </InputLabel>
                <OutlinedInput
                  id="groundsForAppeal"
                  name="groundsForAppeal"
                  value={values?.groundsForAppeal}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.groundsForAppeal && errors.groundsForAppeal && (
                  <Typography color="error">
                    {errors.groundsForAppeal}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  First AP Hearing Date
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="firstAPHearingDate"
                  selected={values?.firstAPHearingDate}
                  onChange={(date) => setFieldValue("firstAPHearingDate", date)}
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.firstAPHearingDate && errors.firstAPHearingDate && (
                  <Typography color="error">
                    {errors.firstAPHearingDate}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Previous AP Hearing Date
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="previousAPHearingDate"
                  selected={values?.previousAPHearingDate}
                  onChange={(date) =>
                    setFieldValue("previousAPHearingDate", date)
                  }
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.previousAPHearingDate &&
                  errors.previousAPHearingDate && (
                    <Typography color="error">
                      {errors.previousAPHearingDate}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Next AP Hearing Date
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="nextAPHearingDate"
                  selected={values?.nextAPHearingDate}
                  onChange={(date) => setFieldValue("nextAPHearingDate", date)}
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.nextAPHearingDate && errors.nextAPHearingDate && (
                  <Typography color="error">
                    {errors.nextAPHearingDate}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="appealStatus">Appeal Status</InputLabel>
                <Select
                  labelId="appealStatus"
                  id="appealStatus"
                  name="appealStatus"
                  value={values?.appealStatus || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "EP Status")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.appealStatus && errors.appealStatus && (
                  <Typography color="error">{errors.appealStatus}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Appeal Decision Date
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="appealDecisionDate"
                  selected={values?.appealDecisionDate}
                  onChange={(date) => setFieldValue("appealDecisionDate", date)}
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.appealDecisionDate && errors.appealDecisionDate && (
                  <Typography color="error">
                    {errors.appealDecisionDate}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="appealOutcome">Appeal Outcome</InputLabel>
                <Select
                  labelId="appealOutcome"
                  id="appealOutcome"
                  name="appealOutcome"
                  value={values?.appealOutcome || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Appeal Outcome")
                    ?.Children?.slice()
                    ?.sort((a, b) =>
                      a.categoryName === "Other"
                        ? 1
                        : b.categoryName === "Other"
                          ? -1
                          : 0
                    )
                    ?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}

                  {/* <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem> */}
                </Select>
                {touched.appealOutcome && errors.appealOutcome && (
                  <Typography color="error">{errors.appealOutcome}</Typography>
                )}
              </Box>

              {settings
                ?.find((item) => item?.categoryName === "Appeal Outcome")
                ?.Children?.find(
                  (child) =>
                    String(child.generalSettingsID) ===
                    String(values.appealOutcome)
                )?.categoryName === "Other" && (
                <Box sx={{ display: "grid", mb: 2 }}>
                  <InputLabel htmlFor="appealOutcomeOther">
                    Appeal Outcome Other
                  </InputLabel>
                  <OutlinedInput
                    id="appealOutcomeOther"
                    name="appealOutcomeOther"
                    value={values?.appealOutcomeOther}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.appealOutcomeOther && errors.appealOutcomeOther && (
                    <Typography color="error">
                      {errors.appealOutcomeOther}
                    </Typography>
                  )}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="detailsOfAppealJudgment">
                  Details of Appeal Judgment
                </InputLabel>
                <OutlinedInput
                  id="detailsOfAppealJudgment"
                  name="detailsOfAppealJudgment"
                  value={values?.detailsOfAppealJudgment}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.detailsOfAppealJudgment &&
                  errors.detailsOfAppealJudgment && (
                    <Typography color="error">
                      {errors.detailsOfAppealJudgment}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="furtherActionPostAppeal">
                  Further Action Post-Appeal
                </InputLabel>
                <Select
                  labelId="furtherActionPostAppeal"
                  id="furtherActionPostAppeal"
                  name="furtherActionPostAppeal"
                  value={values?.furtherActionPostAppeal || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {touched.furtherActionPostAppeal &&
                  errors.furtherActionPostAppeal && (
                    <Typography color="error">
                      {errors.furtherActionPostAppeal}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Appeal Closure Date
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="appealClosureDate"
                  selected={values?.appealClosureDate}
                  onChange={(date) => setFieldValue("appealClosureDate", date)}
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.appealClosureDate && errors.appealClosureDate && (
                  <Typography color="error">
                    {errors.appealClosureDate}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Attach Appeal Document
                </Typography>
                <Button
                  className="col-12 col-sm-12 col-md-6 col-xl-3"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    // accept="application/pdf"
                    onChange={(event) => {
                      const existingFiles = values?.attachAppealDocument || [];
                      const newFiles = Array.from(event.target.files);

                      const updatedFiles = [
                        ...new Map(
                          [...existingFiles, ...newFiles].map((file) => [
                            file.name || file?.actualDocName,
                            {
                              file: file?.file ?? file,
                              checkListDocID: file?.checkListDocID || null,
                              caseRowID: file?.caseRowID || null,
                              caseID: file?.caseID || null,
                              checklistID: file?.checklistID || null,
                              genSetID: file?.genSetID || null,
                              checklistDate: null,
                              actualDocName: file.name || file?.actualDocName,
                              uploadDocName: file.name || file?.uploadDocName,
                              documentType: file.type || file.documentType,
                              documentSize:
                                file.documentSize || file.size?.toString(),
                              documentClassification: null,
                              documentService: null,
                              serviceCount: null,
                              comments: null,
                              createdBy: file?.createdBy || 1,
                              updatedBy: file?.updatedBy || 1,
                              isActive: 1,
                              createdAt:
                                file?.createdAt || new Date()?.toISOString(),
                              updatedAt:
                                file?.updatedAt || new Date()?.toISOString(),
                            },
                          ])
                        ).values(),
                      ];

                      setFieldValue("attachAppealDocument", updatedFiles);
                    }}
                  />
                </Button>
                {touched.attachAppealDocument &&
                  errors.attachAppealDocument && (
                    <Typography color="error">
                      {errors.attachAppealDocument}
                    </Typography>
                  )}
              </Box>

              {!loading && values.attachAppealDocument.length > 0 ? (
                <Box sx={{ mt: 2 }}>
                  {values.attachAppealDocument?.map((file, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={file?.actualDocName || file.name}
                          onDelete={() => {
                            if (window.delete) {
                              const updatedFiles =
                                values.attachSupportingDocuments.filter(
                                  (_, fileIndex) => fileIndex !== index
                                );
                              setFieldValue(
                                "attachAppealDocument",
                                updatedFiles
                              );
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to delete"
                              );
                            }
                          }}
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
              ) : (
                loading && (
                  <>
                    {" "}
                    <Skeleton variant="rectangular" width="60%" height={30} />
                    <br />
                    <Skeleton variant="rectangular" width="60%" height={30} />
                    <br />
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={30}
                    />{" "}
                  </>
                )
              )}

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                  variant="outlined"
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default Appeals;

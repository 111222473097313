import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import Dashboard from "../layout/Dashboard";
// import AuthLogin from "../pages/authentication/auth-forms/AuthLogin";
// import AuthRegister from "../pages/authentication/auth-forms/AuthRegister";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../pages/NotFoundPage";
// import UserProfileLite from "../pages/User/views/UserProfileLite";
import Templates from "../pages/Templates/HTMLTemplate";
import UserOrgnization from "../pages/Settings/UserOrganization/UserOrganizationReport";

import Checklist from "../pages/Settings/Checklist/ChecklistReport";
import CaseTemplate from "../pages/CaseInfo/caseTemplate/index";
import CreateCaseTemplate from "../pages/CaseInfo/caseTemplate/CreateTemplate/CreateCaseTemplate";
import OrganizationCaseDetails from "../pages/CaseInfo/caseDetails/OrganizationCaseDetails";
import CaseChecklist from "../pages/CaseInfo/caseDetails/CaseChecklist";
import UserResetPassword from "../pages/User/UserResetPassword";
// import CaseDocuments from "../pages/CaseInfo/caseDocuments";

// const Color = Loadable(lazy(() => import("../pages/component-overview/color")));

// const Typography = Loadable(
//   lazy(() => import("../pages/component-overview/typography"))
// );
// const Shadow = Loadable(
//   lazy(() => import("../pages/component-overview/shadows"))
// );
// render - sample page
// const SamplePage = Loadable(
//   lazy(() => import("../pages/extra-pages/sample-page"))
// );

//render - report page

const Form = Loadable(lazy(() => import("../pages/Forms/UserCreation")));

//Dashboard

const DashboardDefault = Loadable(
  lazy(() => import("../pages/dashboard/index"))
);

//Settings
const Users = Loadable(lazy(() => import("../pages/Settings/User/UserReport")));
const UserRoles = Loadable(
  lazy(() => import("../pages/Settings/UserRole/UserRoleReport"))
);
const UserDesignation = Loadable(
  lazy(
    () => import("../pages/Settings/UserDesignations/UserDesignationsReport")
  )
);

//Repository

const Repository = Loadable(
  lazy(() => import("../pages/Organization/Repository/RepositoryReports"))
);

const RepositoryAward = Loadable(
  lazy(() => import("../pages/Organization/Repository/RepositoryAwardsReports"))
);

//Profile

const ViewProfile = Loadable(
  lazy(() => import("../pages/User/views/UserProfileLite"))
);

//UserRole Route
const UserRoleRouteReport = Loadable(
  lazy(() => import("../pages/UserRoleRoute/UserRoleRoute"))
);

const UserChangePassword = Loadable(
  lazy(() => import("../pages/User/views/UserChangePassword"))
);

const CaseDocuments = Loadable(
  lazy(() => import("../pages/CaseInfo/caseDocuments"))
);

const PDFDocuments = Loadable(
  lazy(() => import("../pages/CaseInfo/PDFDocument/PDFDocument"))
);

const Maintanance = Loadable(lazy(() => import("../pages/MaintanancePage")));
const CaseDetailsUpdate = Loadable(
  lazy(() => import("../pages/CaseInfo/caseDetailsUpdate/CaseDetailsUpdate"))
);
const Allotment = Loadable(
  lazy(() => import("../pages/CaseInfo/Allotment//AllotmentCaseDetails"))
);
const CreateAllotment = Loadable(
  lazy(() => import("../pages/CaseInfo/Allotment/Allotment"))
);

const CaseBulkUpdate = Loadable(
  lazy(() => import("../pages/CaseUpdae/CaseBulkUpdate"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <ProtectedRoute>
      <Dashboard />
    </ProtectedRoute>
  ),
  errorElement: <NotFound />,

  children: [
    // {
    //   path: "/home",
    //   element: <Maintanance />,
    // },
    {
      path: "/case/tasks",
      element: <Maintanance />,
    },
    {
      path: "/case/notifications",
      element: <Maintanance />,
    },
    {
      path: "/case/reports",
      element: <Maintanance />,
    },
    {
      path: "/case/billingandpayments",
      element: <Maintanance />,
    },
    {
      path: "/case/itsupport",
      element: <Maintanance />,
    },
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "/home",
      element: <DashboardDefault />,
    },

    {
      path: "/settings/users/",
      element: <Users />,
    },
    {
      path: "/settings/userroles/",
      element: <UserRoles />,
    },
    {
      path: "/settings/userdesignation/",
      element: <UserDesignation />,
    },
    {
      path: "/settings/userrolemapping",
      element: <UserRoleRouteReport />,
    },
    {
      path: "/settings/userorganization",
      element: <UserOrgnization />,
    },
    {
      path: "/settings/caseChecklist",
      element: <Checklist />,
    },
    {
      path: "/repositorycentral/masterrepository",
      element: <Repository />,
    },
    {
      path: "/repositorycentral/awardsandorders",
      element: <RepositoryAward />,
    },
    {
      path: "/case/caseallotments/",
      element: <Allotment />,
    },
    {
      path: "/case/caseallotments/createAllotment",
      element: <CreateAllotment />,
    },
    {
      path: "/profile/viewprofile",
      element: <ViewProfile />,
    },
    {
      path: "/profile/changepassword",
      element: <UserChangePassword />,
    },
    {
      path: "/case/users",
      element: <Users />,
    },

    // {
    //   path: "/settings/conciliatornotice",
    //   element: <Templates />,
    // },
    {
      path: "/settings/caseTemplate",
      element: <CaseTemplate />,
    },
    {
      path: "/case/caseallotment",
      element: <CreateCaseTemplate />,
    },
    {
      path: "/case/caseDetails",
      element: <OrganizationCaseDetails />,
    },
    {
      path: "/case/caseDetails/:id",
      element: <CaseChecklist />,
    },
    {
      path: "/case/caseDetails/update",
      element: <CaseBulkUpdate />,
    },
    {
      path: "/case/caseDetailsUpdate",
      element: <CaseDetailsUpdate />,
    },
    {
      path: "/case/caseDocuments",
      element: <CaseDocuments />,
    },
    {
      path: "/settings/pdfdocument",
      element: <PDFDocuments />,
    },
    {
      path: "/case/createMatter",
      element: <Maintanance />,
    },
    {
      path: "/case/createTask",
      element: <Maintanance />,
    },
    {
      path: "/case/addDocument",
      element: <Maintanance />,
    },
    {
      path: "/resetPassword",
      element: <UserResetPassword />,
    },
    // {
    //   path: "/case/caseDetails/:id",
    //   element: <OrganizationCaseDetails />,
    // },
    // {
    //   path: "sample-page",
    //   element: <SamplePage />,
    // },
    // {
    //   path: "shadow",
    //   element: <Shadow />,
    // },
    // {
    //   path: "typography",
    //   element: <Typography />,
    // },
    // {
    //   path: "color",
    //   element: <Color />,
    // },
  ],
};

export default MainRoutes;

import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// project import
import { handlerActiveItem, useGetMenuMaster } from "../../../../../api/menu";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import { Chip, Collapse, List } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { OpenNavGroup } from "../../../../../redux/slices/User/usersSlice";

export default function CollapseNavItem({
  item,
  level,
  routesChecking,
  userRole,
  userRoleRouters,
  setOpenCollapseId,
  openCollapseId,
  open,
  setOpen,
  openDropDown,
  setOpenDropDown,
}) {
  const [childMenu, setChileMenu] = useState(false);

  const theme = useTheme();

  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;
  const openItem = menuMaster.openedItem;

  const dispatch = useDispatch();

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }
  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link
        underline="none"
        ref={ref}
        {...props}
        to={item.url}
        target={itemTarget}
      />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  const { pathname } = useLocation();
  const isSelected =
    !!matchPath({ path: item.url, end: false }, pathname) ||
    openItem === item.id;

  // active menu item on page load
  useEffect(() => {
    if (pathname === item.url) handlerActiveItem(item.id);
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.common.white;
  const iconSelectedColor = theme.palette.common.white;

  const [openGroup, setOpenGroup] = useState();

  const handleClick = (e) => {
    setOpenGroup(e?.id);
    setOpen(item.id == selectedNav && open ? false : true);
    setOpenDropDown((prev) => (prev === item.id ? null : item.id));
    dispatch(OpenNavGroup(e?.id));
  };

  const selectedNav = useSelector((state) => state.users.navGroup);

  //  ||
  // item?.children?.filter(
  //   (i) => i.title == window.location?.pathname?.split("/")?.[1]
  // );

  // const selectedGroup = item?.children;

  function printRoutes(routeGroup) {
    const groupData = userRoleRouters?.map((group, i) => {
      if (group?.routeGroup === routeGroup.id && item.id == selectedNav) {
        return (
          <Collapse
            in={item.id == selectedNav && open}
            timeout="auto"
            unmountOnExit
            key={i}
          >
            <List component="div" disablePadding>
              {group.url?.map((userRoute, index) => {
                const subMenu = item.children.find(
                  (item) => item.url === userRoute.url && item?.type == "item"
                );

                if (subMenu) {
                  return (
                    <Link
                      key={index}
                      to={subMenu.url}
                      style={{ textDecoration: "none" }}
                      onClick={() => window.location.pathname == subMenu.url}
                    >
                      <ListItemButton
                        key={index}
                        style={
                          window.location.pathname == subMenu.url ||
                          window.location?.pathname?.split("/")?.[2] ==
                            subMenu?.url?.split("/")?.[2]
                            ? {
                                color: iconSelectedColor,

                                backgroundColor: "#0b30f087",
                              }
                            : {
                                color: iconSelectedColor,
                              }
                        }
                        sx={{
                          zIndex: 1201,
                          pl: drawerOpen ? `${level * 28}px` : 1.5,
                          py: !drawerOpen && level === 1 ? 1.25 : 1,
                          ...(drawerOpen && {
                            "&:hover": {
                              // bgcolor: "primary.lighter",
                              bgcolor: "#0b30f087",
                              color: iconSelectedColor,
                            },
                            "&.Mui-selected": {
                              // bgcolor: "primary.lighter",
                              borderRight: `2px solid ${theme.palette.primary.main}`,

                              bgcolor: "#0b30f087",
                              color: iconSelectedColor,
                              "&:hover": {
                                color: iconSelectedColor,
                                bgcolor: "primary.lighter",
                              },
                            },
                          }),
                          ...(!drawerOpen && {
                            "&:hover": {
                              bgcolor: "transparent",
                            },
                            "&.Mui-selected": {
                              "&:hover": {
                                bgcolor: "transparent",
                              },
                              bgcolor: "transparent",
                            },
                          }),
                        }}
                      >
                        {/* <ListItemIcon
                          sx={{
                            minWidth: 28,
                            color: isSelected ? iconSelectedColor : textColor,
                            ...(!drawerOpen && {
                              borderRadius: 1.5,
                              width: 36,
                              height: 36,
                              alignItems: "center",
                              justifyContent: "center",
                              "&:hover": {
                                bgcolor: "secondary.lighter",
                              },
                            }),
                            ...(!drawerOpen &&
                              isSelected && {
                                bgcolor: "primary.lighter",
                                "&:hover": {
                                  bgcolor: "primary.lighter",
                                },
                              }),
                          }}
                        > */}
                        {/* <Icon src={subMenu.icon} alt="icon" /> */}
                        {/* </ListItemIcon> */}

                        <ListItemText primary={subMenu.title} />
                      </ListItemButton>
                    </Link>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Collapse>
        );
      } else {
        return null;
      }
    });

    return groupData;
  }

  const isActiveNav = item.children.find(
    (nav) => nav.url === window.location.pathname || false
  );
  const groupData =
    userRoleRouters?.filter((group) => group?.routeGroup === item.id) ?? // Filter directly instead of using map + filter
    [];
  const totalNav = groupData?.[0]?.url;

  const selectedGroup =
    item?.url?.split("/")?.[1]?.toLowerCase() ==
    window.location?.pathname?.split("/")?.[1]?.toLowerCase();

  return (
    <>
      <ListItemButton
        onClick={() => {
          handleClick(item);
          setOpenDropDown(item.id);
          setOpenCollapseId(item.id);
        }}
        disabled={item.disabled}
        selected={
          item?.url?.split("/")?.[1]?.toLowerCase() ==
          window.location?.pathname?.split("/")?.[1]?.toLowerCase()
          //  isActiveNav !== null && typeof isActiveNav === "object"
          //   ? true
          //   : isActiveNav || selectedGroup
        }
        sx={{
          zIndex: 1201,
          pl: drawerOpen ? `${level * 28}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(item?.url?.split("/")?.[1]?.toLowerCase() ==
            window.location?.pathname?.split("/")?.[1]?.toLowerCase() && {
            "&:hover": {
              bgcolor: "#0b30f087",
              // "primary.lighter",
            },
            "&.Mui-selected": {
              bgcolor: "#0b30f087",

              borderRight: `2px solid ${theme.palette.primary.main}`,
              //  "primary.lighter",
              //   bgcolor: "white",
              color: iconSelectedColor,
              "&:hover": {
                color: iconSelectedColor,
                bgcolor: "#0b30f087",
                // "primary.lighter",
              },
            },
          }),
          // ...(!drawerOpen &&
          //   selectedNav == item.id && {
          //     "&:hover": {
          //       bgcolor: "transparent",
          //       color: iconSelectedColor,
          //     },
          //     "&.Mui-selected": {
          //       "&:hover": {
          //         bgcolor: "transparent",
          //       },
          //       bgcolor: "transparent",
          //     },
          //   }),
        }}
      >
        <ListItemIcon style={{ color: iconSelectedColor }}>
          {" "}
          {itemIcon}
        </ListItemIcon>
        <ListItemText primary={item.title} />
        {/* <Chip color="warning" label={totalNav?.length} onClick={handleClick} /> */}

        {item.id == selectedNav && open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {drawerOpen && openDropDown == item.id ? printRoutes(item) : ""}
    </>
  );
}

CollapseNavItem.propTypes = { item: PropTypes.object, level: PropTypes.number };

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Stack,
  styled,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePicker from "react-datepicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  preTrailCaseDetailsDocumentsAction,
  singleCaseDetailsAction,
  claimantSideArgumentsCreateAction,
  claimantSideArgumentsUpdateAction,
  claimantSideArgumentsDeleteAction,
} from "../../../../redux/actions/Case/caseAction";
import React, { useEffect, useState } from "react";
import { animateCss } from "../../../TosterAlert";
import { MdDelete } from "react-icons/md";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { IoIosOpen, IoMdAttach } from "react-icons/io";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const ClaimantSideArguments = ({
  expanded,
  openModel,
  setExpanded,
  setOpenModel,
  setViewDocument,
  setEditIndex,
  editIndex,
  handleAccordionChange,
  formValue,
  setFormValue,
}) => {
  const [editForm, setEditForm] = useState(false);
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState({});

  const { id } = useParams();
  const params = { caseID: id };
  const rowData = useSelector((state) => state?.case?.editCaseDetails);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);

  const data = rowData.ClaimantSideArguments
    ? JSON.parse(JSON.parse(rowData.ClaimantSideArguments))
    : [];

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch(singleCaseDetailsAction(params));
    }, 3000);
  }, [openModel]);

  return (
    <>
      <div
        className="d-flex  row p-0 m-0"
        spacing={2}
        style={{ backgroundColor: "#fbfbfc" }}
      >
        <div className="d-flex p-0 m-0 row col-12 mt-2  ">
          {/* {editForm && expanded === 5 && editForm ? ( */}
          {/* <div className="d-flex row col-12 mt-2  "> */}
          <form
            className="pe-0"
            // onSubmit={handleSubmit}
            style={{ boxShadow: "none" }}
            xs={12}
            sm={12}
            md={12}
          >
            <Accordion expanded={expanded === 5}>
              <AccordionSummary
                onClick={(event) => {
                  event.stopPropagation();
                  handleAccordionChange(5);
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  className="p-0 m-0"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="m-0"
                    sx={{
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                    // onClick={() => handleAccordionChange(5)}
                  >
                    Claimant Side Arguments
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "end",
                    px: 2,
                    gridTemplateColumns: {
                      xs: "1fr",
                      sm: "1fr",
                      md: "1fr",
                    },
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                    },
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  {window.edit && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(event) => {
                        event.stopPropagation();
                        setEditIndex(null);
                        setExpanded(5);
                        setOpenModel(true);
                      }}
                      disabled={data?.length == 10}
                      className="justify-content-center"
                    >
                      Claimant Side Arguments Submission Form
                    </Button>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        backgroundColor: "white",
                        flexWrap: "wrap",
                        alignItems: "stretch",
                      }}
                    >
                      {expanded === 5 && openModel && (
                        <ModelForm
                          values={data}
                          settings={settings}
                          formValue={formValue}
                          setFormValue={setFormValue}
                          setOpenModel={setOpenModel}
                          openModel={openModel}
                          editIndex={editIndex}
                          setEditIndex={setEditIndex}
                        />
                      )}

                      <Box
                        sx={{
                          display: "grid",
                          // px: 2,
                          gridTemplateColumns: {
                            xs: "1fr",
                            sm: "1fr",
                            md: "1fr",
                          },
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "100%",
                          },
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <Box
                          className="d-flex col-12"
                          // style={{
                          //   overflowX: "auto",
                          //   width: "100%",
                          // }}
                        >
                          <table
                            style={{ overflowWrap: "anywhere" }}
                            className="my-2 table table-striped table-bordered"
                          >
                            <thead
                              style={{
                                fontSize: "14px",
                                fontWeight: 800,
                              }}
                            >
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    fontWeight: "lighter",
                                  }}
                                  className="border"
                                >
                                  Argument Number
                                </th>

                                <th
                                  scope="col"
                                  style={{
                                    fontWeight: "lighter",
                                  }}
                                  className="border"
                                >
                                  Start Date
                                </th>

                                <th
                                  scope="col"
                                  style={{
                                    fontWeight: "lighter",
                                  }}
                                  className="border"
                                >
                                  Status
                                </th>

                                <th
                                  scope="col"
                                  style={{
                                    fontWeight: "lighter",
                                  }}
                                  className="border"
                                >
                                  Detailed Arguments Attachment
                                </th>

                                <th
                                  scope="col"
                                  style={{
                                    fontWeight: "lighter",
                                    width: "65px",
                                  }}
                                  className="border"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {data?.map((order, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Typography
                                        component="a"
                                        className="m-0"
                                        sx={{
                                          ...(window.edit && {
                                            color: "primary.main",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            fontWeight: 800,
                                          }),
                                          ...(!window.edit && {
                                            color: "secondary.main",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            fontWeight: 800,
                                          }),
                                        }}
                                        onClick={() => {
                                          if (window.edit) {
                                            setOpenModel(true);
                                            setEditIndex(index);
                                          } else {
                                            animateCss(
                                              "info",
                                              "You are not authorized to update"
                                            );
                                          }
                                        }}
                                      >
                                        Argument {index + 1}
                                      </Typography>{" "}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {order?.argumentsStartDate
                                        ? formatDate(order?.argumentsStartDate)
                                        : ""}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ===
                                            "Arguments Status"
                                        )
                                        ?.Children?.find(
                                          (child) =>
                                            String(child.generalSettingsID) ===
                                            String(order?.argumentsStatus)
                                        )?.categoryName || ""}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {[...(order?.detailedArguments || [])]
                                        ?.length > 0 && (
                                        <Button
                                          onClick={() => {
                                            setViewDocument(true);
                                            const uniqueDocumentId = [
                                              ...(order?.detailedArguments ||
                                                []),
                                            ];

                                            const document = {
                                              uniqueDocumentId,
                                            };
                                            dispatch(
                                              preTrailCaseDetailsDocumentsAction(
                                                document
                                              )
                                            );
                                          }}
                                          aria-label="attach"
                                          onMouseEnter={() =>
                                            setHovered({
                                              id: index,
                                              open: true,
                                              key: "Attachment",
                                            })
                                          }
                                          onMouseLeave={() =>
                                            setHovered({
                                              id: index,
                                              open: false,
                                              key: "Attachment",
                                            })
                                          }
                                        >
                                          <IoIosOpen className="fs-4 text-primary" />{" "}
                                          <span
                                            className={`transition-opacity duration-500 ${
                                              hovered.id === index &&
                                              hovered.open &&
                                              hovered.key === "Attachment"
                                                ? "opacity-100"
                                                : "opacity-0"
                                            }`}
                                          >
                                            {" "}
                                            View Attachment
                                          </span>
                                        </Button>
                                      )}
                                    </td>
                                    <td
                                      className={
                                        data?.length - 1 == index && "d-flex"
                                      }
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        color="error"
                                        style={{
                                          marginTop: "4%",
                                        }}
                                        onClick={() => {
                                          if (window.delete) {
                                            enqueueSnackbar(
                                              <>
                                                <div>
                                                  Are you sure you want to
                                                  delete the record and its
                                                  associated document for
                                                  Claimant Side Arguments{" "}
                                                  {index + 1} ?
                                                </div>
                                              </>,
                                              {
                                                variant: "warning",
                                                onClose: () => {},
                                                anchorOrigin: {
                                                  vertical: "top",
                                                  horizontal: "right",
                                                },
                                                action: (key) => (
                                                  <>
                                                    <StyledButton
                                                      onClick={async () => {
                                                        const output =
                                                          formValue.filter(
                                                            (_, i) =>
                                                              i !== index
                                                          );

                                                        setFormValue(output);
                                                        const value = {
                                                          caseID: params,
                                                          index,
                                                        };
                                                        dispatch(
                                                          claimantSideArgumentsDeleteAction(
                                                            value
                                                          )
                                                        )
                                                          .unwrap()
                                                          .then((result) => {
                                                            closeSnackbar(key);
                                                            if (
                                                              result.documents
                                                            ) {
                                                              setTimeout(() => {
                                                                dispatch(
                                                                  singleCaseDetailsAction(
                                                                    params
                                                                  )
                                                                );
                                                              }, 2000);
                                                            }
                                                          })
                                                          .catch((error) => {
                                                            console.error(
                                                              "Action rejected with error:",
                                                              error
                                                            );
                                                          });

                                                        setTimeout(() => {
                                                          dispatch(
                                                            singleCaseDetailsAction(
                                                              params
                                                            )
                                                          );
                                                        }, 1000);
                                                      }}
                                                    >
                                                      Confirm
                                                    </StyledButton>
                                                    <StyledButton
                                                      onClick={() => {
                                                        closeSnackbar(key);
                                                      }}
                                                    >
                                                      Cancel
                                                    </StyledButton>
                                                  </>
                                                ),
                                                autoHideDuration: null,
                                                style: {
                                                  backgroundColor: "#f44336",
                                                  width: "600px",
                                                  color: "#fff",
                                                },
                                              }
                                            );
                                          } else {
                                            animateCss(
                                              "info",
                                              "You are not authorized to delete"
                                            );
                                          }
                                        }}
                                      >
                                        <MdDelete
                                          style={{
                                            fontSize: "24px",
                                          }}
                                        />
                                      </IconButton>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </Box>
                      </Box>
                    </Box>

                    {/* Dynamice Data */}
                  </LocalizationProvider>
                </>
              </AccordionDetails>
            </Accordion>
          </form>
        </div>
      </div>
    </>
  );
};

export default ClaimantSideArguments;

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  marginTop: "1%",
  fontSize: "1rem",
  borderRadius: "4px",
  border: "1px solid #ccc",
  resize: "vertical",
  minHeight: "90px",
  transition: "border-color 0.3s ease",
  overflow: "auto",
  "&:focus": {
    borderColor: theme.palette.primary.main,
    outline: "none",
  },
}));

const ModelForm = ({
  setPreTrialApplications,
  values,
  formValue,
  setFormValue,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
}) => {
  const [formData, setFormData] = useState(null);
  const data = values;

  const claimantPreTrailApplicationAttachmentsOld = [
    ...(data[editIndex]?.detailedArguments || []),
  ];

  const uniqueevidenceAttachment = Array.from(
    new Set(claimantPreTrailApplicationAttachmentsOld?.map(Number))
  );

  const uniqueDocumentId = [...(uniqueevidenceAttachment || [])];

  const dispatch = useDispatch();

  const { id } = useParams();

  const params = { caseID: id };

  const document = {
    uniqueDocumentId,
    id,
  };

  // Updating formData based on editIndex
  useEffect(() => {
    dispatch(preTrailCaseDetailsDocumentsAction(document));
    if (editIndex >= 0) {
      setFormData(data?.[editIndex]);
    } else {
      setFormData(null);
    }
  }, [editIndex, openModel]);

  let initialValues = {
    argumentsStartDate: data?.[editIndex]?.argumentsStartDate ?? "",
    argumentsEndDate: data?.[editIndex]?.argumentsEndDate ?? "",
    argumentsStatus: data?.[editIndex]?.argumentsStatus ?? "",
    summaryOfArguments: data?.[editIndex]?.summaryOfArguments ?? "",
    detailedArguments:
      useSelector((state) => state.case?.preTrailCaseDetailsDocuments)?.filter(
        (item) =>
          data?.[editIndex]?.detailedArguments?.includes(item.checkListDocID)
      ) || [],
    legalGroundsProvisionsReliedUpon:
      data?.[editIndex]?.legalGroundsProvisionsReliedUpon ?? "",
    keyPrecedentsCited: data?.[editIndex]?.keyPrecedentsCited ?? "",
    reliefRemedySought: data?.[editIndex]?.reliefRemedySought ?? "",
    editIndex,
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    Formik.setFieldValue("claimantsPreTrailApplicationAttachments", files);
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            try {
              const form = new FormData();
              form.append("argumentsStartDate", values.argumentsStartDate);
              form.append("argumentsEndDate", values.argumentsEndDate);
              form.append("argumentsStatus", values.argumentsStatus);
              form.append("summaryOfArguments", values.summaryOfArguments);
              form.append("caseID", id);
              if (values.detailedArguments) {
                values.detailedArguments.forEach((file) => {
                  if (file?.caseID) {
                    form.append(`detailedArgumentsOld`, file?.checkListDocID);
                  } else {
                    form.append(`Detailed Arguments`, file?.file);
                  }
                });
              }
              form.append(
                "legalGroundsProvisionsReliedUpon",
                values.legalGroundsProvisionsReliedUpon
              );
              form.append("keyPrecedentsCited", values.keyPrecedentsCited);
              form.append("reliefRemedySought", values.reliefRemedySought);

              if (editIndex == null) {
                dispatch(claimantSideArgumentsCreateAction(form))
                  .unwrap()
                  .then((result) => {
                    setOpenModel(false);
                    if (result.documents) {
                      dispatch(singleCaseDetailsAction(params));
                      setOpenModel(false);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                const updatedData = [...formValue];
                updatedData[editIndex] = values;
                setFormValue(updatedData);
                form.append("editIndex", editIndex);
                dispatch(claimantSideArgumentsUpdateAction(form))
                  .unwrap()
                  .then((result) => {
                    setOpenModel(false);
                    if (result.documents) {
                      dispatch(singleCaseDetailsAction(params));
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }

              setOpenModel(false);
              // dispatch(
              //   allAgreementDocumentListAction({
              //     value: "Arbitration Agreement",
              //     caseID: id,
              //   })
              // );

              actions.resetForm();
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Arguments Start Date
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="argumentsStartDate"
                  selected={values?.argumentsStartDate}
                  onChange={(date) => setFieldValue("argumentsStartDate", date)}
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.argumentsStartDate && errors.argumentsStartDate && (
                  <Typography color="error">
                    {errors.argumentsStartDate}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Arguments End Date
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="argumentsEndDate"
                  selected={values?.argumentsEndDate}
                  onChange={(date) => setFieldValue("argumentsEndDate", date)}
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.argumentsEndDate && errors.argumentsEndDate && (
                  <Typography color="error">
                    {errors.argumentsEndDate}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="argumentsStatus">
                  Arguments Status
                </InputLabel>
                <Select
                  labelId="argumentsStatus"
                  id="argumentsStatus"
                  name="argumentsStatus"
                  value={values?.argumentsStatus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Arguments Status")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.argumentsStatus && errors.argumentsStatus && (
                  <Typography color="error">
                    {errors.argumentsStatus}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="summaryOfArguments">
                  Summary of Arguments
                </InputLabel>
                <OutlinedInput
                  id="summaryOfArguments"
                  name="summaryOfArguments"
                  value={values?.summaryOfArguments}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.summaryOfArguments && errors.summaryOfArguments && (
                  <Typography color="error">
                    {errors.summaryOfArguments}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Detailed Arguments
                </Typography>
                <Button
                  className="col-12 col-sm-12 col-md-6 col-xl-3"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    // accept="application/pdf"
                    onChange={(event) => {
                      const existingFiles = values?.detailedArguments || [];
                      const newFiles = Array.from(event.target.files);

                      const existingFile = existingFiles.find(
                        (item) => item.actualDocName === newFiles[0]?.name
                      );

                      let combinedFiles;
                      if (!existingFile) {
                        combinedFiles = [...existingFiles, ...newFiles];
                      } else {
                        combinedFiles = existingFiles;
                      }

                      const updatedFiles = [
                        ...new Map(
                          combinedFiles?.map((file) => [
                            file.name || file.actualDocName,
                            {
                              file: file,

                              checkListDocID: file?.checkListDocID,
                              caseRowID: file?.caseRowID,
                              caseID: file?.caseID,
                              checklistID: file?.checklistID,
                              genSetID: file?.genSetID,
                              checklistDate: null,
                              actualDocName: file.name || file?.actualDocName, // File name
                              uploadDocName: file.name || file?.uploadDocName,
                              documentType: file.type || file.documentType, // File type (e.g., application/pdf)
                              documentSize:
                                file.documentSize || file.size.toString(), // File size as string
                              documentClassification: null, // Placeholder for document classification
                              documentService: null, // Placeholder for document service
                              serviceCount: null, // Placeholder for service count
                              comments: null, // Placeholder for any comments
                              createdBy: 1, // Example createdBy (could be dynamic)
                              updatedBy: 1, // Example updatedBy (could be dynamic)
                              isActive: 1, // File is active
                              createdAt:
                                file.createdAt || new Date()?.toISOString(),
                              updatedAt:
                                file.updatedAt || new Date()?.toISOString(),
                            },
                          ])
                        ).values(),
                      ];

                      if (updatedFiles.length <= 5) {
                        setFieldValue("detailedArguments", updatedFiles);
                      } else {
                        animateCss(
                          "warn",
                          "You can upload a maximum of 5 files.",
                          2000
                        );
                      }
                    }}
                  />
                </Button>
                {touched.detailedArguments && errors.detailedArguments && (
                  <Typography color="error">
                    {errors.detailedArguments}
                  </Typography>
                )}
              </Box>

              {values.detailedArguments.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  {values.detailedArguments?.map((file, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={file?.actualDocName || file.name}
                          onDelete={() => {
                            if (window.delete) {
                              const updatedFiles =
                                values.detailedArguments.filter(
                                  (_, fileIndex) => fileIndex !== index
                                );
                              setFieldValue("detailedArguments", updatedFiles);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to delete"
                              );
                            }
                          }}
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="legalGroundsProvisionsReliedUpon">
                  Legal Grounds/Provisions Relied Upon
                </InputLabel>
                <OutlinedInput
                  id="legalGroundsProvisionsReliedUpon"
                  name="legalGroundsProvisionsReliedUpon"
                  value={values?.legalGroundsProvisionsReliedUpon}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.legalGroundsProvisionsReliedUpon &&
                  errors.legalGroundsProvisionsReliedUpon && (
                    <Typography color="error">
                      {errors.legalGroundsProvisionsReliedUpon}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="keyPrecedentsCited">
                  Key Precedents Cited
                </InputLabel>
                <OutlinedInput
                  id="keyPrecedentsCited"
                  name="keyPrecedentsCited"
                  value={values?.keyPrecedentsCited}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.keyPrecedentsCited && errors.keyPrecedentsCited && (
                  <Typography color="error">
                    {errors.keyPrecedentsCited}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="reliefRemedySought">
                  Relief/Remedy Sought
                </InputLabel>
                <OutlinedInput
                  id="reliefRemedySought"
                  name="reliefRemedySought"
                  value={values?.reliefRemedySought}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.reliefRemedySought && errors.reliefRemedySought && (
                  <Typography color="error">
                    {errors.reliefRemedySought}
                  </Typography>
                )}
              </Box>

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                  variant="outlined"
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  color="primary"
                  type="submit"
                >
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

import { Card, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import ArbitrationStatusTracking from "./TimelineAndStatusTracking/ArbitrationStatusTracking";
import DocumentDrawer from "../../Drawer";
import ArbitrationDurationAndTimelineMonitoring from "./TimelineAndStatusTracking/ArbitrationDurationAndTimelineMonitoring";

const TimelineAndStatusTracking = () => {
  const rowData = useSelector((state) => state?.case?.editCaseDetails);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [editForm, setEditForm] = useState(null);
  const [openDocument, setOpenDocument] = useState(false);

  function trackerFormatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const handleAccordion = (panel) => {
    setAccordionOpen(panel);
  };

  const { id } = useParams();
  const user = useSelector((state) => state.login.data);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);
  const document = useSelector(
    (state) => state?.case?.preTrailCaseDetailsDocuments
  );
  return (
    <>
      <Card
        variant="outlined"
        className=" mt-3 md-6 xl-6 xxl-6"
        sx={{ maxWidth: "100%" }}
        xs={12}
        sm={12}
        md={6}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="m-0 px-2  text-secondary"
        >
          Timeline and Status Tracking
        </Typography>

        <div
          className="d-flex row"
          spacing={2}
          style={{ backgroundColor: "#fbfbfc" }}
        >
          {/* <ArbitrationDurationAndTimelineManagement
            rowData={rowData}
            accordionOpen={accordionOpen}
            setAccordionOpen={setAccordionOpen}
            trackerFormatDate={trackerFormatDate}
            id={id}
            user={user}
            settings={settings}
            handleAccordion={handleAccordion}
            editForm={editForm}
            setEditForm={setEditForm}
          /> */}

          <ArbitrationStatusTracking
            rowData={rowData}
            accordionOpen={accordionOpen}
            setAccordionOpen={setAccordionOpen}
            trackerFormatDate={trackerFormatDate}
            id={id}
            user={user}
            settings={settings}
            handleAccordion={handleAccordion}
            editForm={editForm}
            setEditForm={setEditForm}
            openDocument={openDocument}
            setOpenDocument={setOpenDocument}
          />

          <ArbitrationDurationAndTimelineMonitoring
            rowData={rowData}
            accordionOpen={accordionOpen}
            setAccordionOpen={setAccordionOpen}
            trackerFormatDate={trackerFormatDate}
            id={id}
            user={user}
            settings={settings}
            handleAccordion={handleAccordion}
            editForm={editForm}
            setEditForm={setEditForm}
            openDocument={openDocument}
            setOpenDocument={setOpenDocument}
          />
        </div>
      </Card>
      <DocumentDrawer
        open={openDocument}
        setOpen={setOpenDocument}
        checklistDocuments={document}
      />
    </>
  );
};

export default TimelineAndStatusTracking;

import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";

export const exportXLSX = (tableData, sheetName, fileName) => {
  try {
    const limitedData = tableData.map((row) => {
      const limitedRow = {};
      const keys = Object.keys(row).slice(0, 20);
      keys.forEach((key) => {
        limitedRow[key] = row[key];
      });
      return limitedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(limitedData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const baseFileName = fileName.trim()
      ? `${fileName}.xlsx`
      : "CaseDetails.xlsx";

    const generateUniqueFileName = (baseFileName) => {
      let fileName = baseFileName;
      let counter = 1;
      while (fileExists(fileName)) {
        fileName = baseFileName.replace(".xlsx", ` (${counter}).xlsx`);
        counter++;
      }
      return fileName;
    };

    const fileExists = (fileName) => {
      return false;
    };

    const excelFileName = generateUniqueFileName(baseFileName);

    XLSX.writeFile(workbook, excelFileName);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
    alert("Error generating Excel file.");
  }
};

export const exportPDF = (tableData, fileName) => {
  try {
    const doc = new jsPDF("landscape");

    const margin = 10;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    const tableColumns = Object.keys(tableData[0]).slice(0, 21);
    const tableRows = tableData.map((row) =>
      Object.values(row)
        .slice(0, 21)
        .map((cell) => (cell == null ? "" : cell))
    );

    const columnWidths = tableColumns.map((column, index) => {
      const maxColumnWidth = Math.max(
        column.length,
        ...tableRows.map((row) => String(row[index]).length)
      );
      return Math.max(maxColumnWidth * 2, 80);
    });

    const totalColumnWidth = columnWidths.reduce(
      (sum, width) => sum + width,
      0
    );

    const scaleFactor = (pageWidth - 2 * margin) / totalColumnWidth;
    let fontSize = Math.max(5, scaleFactor * 3);
    const cellHeight = 10;

    doc.setFont("helvetica", "normal");

    const addTableContent = (startYOffset) => {
      let yOffset = startYOffset;

      tableColumns.forEach((column, index) => {
        const xOffset =
          margin +
          columnWidths.slice(0, index).reduce((a, b) => a + b, 0) * scaleFactor;

        doc.setFontSize(fontSize);
        const headerText = doc.splitTextToSize(
          column,
          columnWidths[index] * scaleFactor - 5
        );
        doc.text(headerText, xOffset, yOffset);
      });

      tableRows.forEach((row, rowIndex) => {
        yOffset += cellHeight;

        if (yOffset > pageHeight - margin - 20) {
          doc.addPage();
          yOffset = margin;
        }

        row.forEach((cell, colIndex) => {
          const xOffset =
            margin +
            columnWidths.slice(0, colIndex).reduce((a, b) => a + b, 0) *
              scaleFactor;

          const wrappedText = doc.splitTextToSize(
            String(cell),
            columnWidths[colIndex] * scaleFactor - 5
          );
          doc.setFontSize(fontSize);
          doc.text(wrappedText, xOffset, yOffset);
        });
      });

      return yOffset;
    };

    const addFooter = () => {
      // Current date and time
      const footerText = `Generated on: ${new Date().toLocaleString()}`;
      const appLinkText = "Casenect App";

      // Y position for footer
      const footerY = pageHeight - margin - 2;
      const footerTextY = footerY - 10; // Adjust text position for date/time
      const linkY = footerTextY + 10; // Position for link

      // Set font size and color for text
      doc.setFontSize(8);
      doc.setTextColor(0, 0, 0); // Black color for the footer text

      // Display "Generated on: [date/time]"
      doc.text(footerText, margin, footerTextY);

      // Optionally, add a link for "Casenect App"
      doc.setTextColor(0, 0, 255); // Blue color for links
      doc.textWithLink(appLinkText, margin, linkY, {
        url: "https://casenect.com/",
      });

      // Add the logo image to the footer (replace 'logo.png' with your logo file path)
      const logoX = pageWidth - margin - 0; // Position logo towards the right
      const logoY = footerY - 8; // Adjust vertical position
      const logoWidth = 20; // Set logo width
      const logoHeight = 10; // Set logo height

      // Ensure the logo is correctly imported
      //   const logo = require("../assets/images/logo/CASENECT_Logo.png");

      //   // Adding the image to the document with the logo path and optional link
      //   doc.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);

      // Optional: Adding the clickable link to the logo
      doc.textWithLink(" ", logoX, logoY, { url: "https://casenect.com/" });
    };

    let yOffset = margin + fontSize;

    yOffset = addTableContent(yOffset);

    doc.setFont("helvetica", "normal");

    addFooter();

    for (let i = 1; i < doc.internal.pages.length; i++) {
      doc.setPage(i - 1 + 1);

      addFooter();
    }

    const generateUniqueFileName = (baseFileName) => {
      let fileName = baseFileName;
      let counter = 1;
      while (fileExists(fileName)) {
        fileName = baseFileName.replace(".pdf", ` (${counter}).pdf`);
        counter++;
      }
      return fileName;
    };

    const fileExists = (fileName) => {
      return false;
    };

    const pdfFileName = generateUniqueFileName(
      fileName.trim() ? `${fileName}.pdf` : "CaseDetails.pdf"
    );

    doc.save(pdfFileName);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
    alert("Error generating PDF file.");
  }
};

export const exportCSV = (tableData, sheetName, fileName) => {
  try {
    const limitedData = tableData.map((row) => {
      const limitedRow = {};
      const keys = Object.keys(row).slice(0, 20);
      keys.forEach((key) => {
        limitedRow[key] = row[key];
      });
      return limitedRow;
    });
    // Convert tableData to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(limitedData);

    // Generate a base file name
    const baseFileName = fileName.trim()
      ? `${fileName}.csv`
      : "CaseDetails.csv";

    // Function to generate a unique file name in case the file already exists (you can adapt this to your file system)
    const generateUniqueFileName = (baseFileName) => {
      let fileName = baseFileName;
      let counter = 1;
      while (fileExists(fileName)) {
        fileName = baseFileName.replace(".csv", ` (${counter}).csv`);
        counter++;
      }
      return fileName;
    };

    // Placeholder for file existence check (you can replace this with actual logic)
    const fileExists = (fileName) => {
      return false;
    };

    // Get the unique CSV file name
    const csvFileName = generateUniqueFileName(baseFileName);

    // Convert the worksheet to CSV format
    const csvData = XLSX.utils.sheet_to_csv(worksheet);

    // Create a Blob for the CSV data and trigger the download
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = csvFileName;
    link.click();
  } catch (error) {
    console.error("Error exporting to CSV:", error);
    alert("Error generating CSV file.");
  }
};

export const handleDownload = (downloadUrl, fileName) => {
  fetch(downloadUrl)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};

export const formatDate = (date) => {
  if (date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
};

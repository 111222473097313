import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import {
  allCaseMappingDetailAction,
  caseMappingDetailAction,
  caseMappingDetailIsActiveAction,
  selectedCaseMappingDetailAction,
} from "../../../redux/actions/Case/caseMappingAction";
import { Formik } from "formik";
import ReactDatePicker from "react-datepicker";

import EditCaseTemplate from "./EditCaseDetails";
import { animate } from "framer-motion";
import { animateCss } from "../../TosterAlert";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import { MdOutlineBackupTable } from "react-icons/md";
import * as XLSX from "xlsx";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
// import { SiMicrosoftexcel } from "react-icons/si";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";

import { mappingTemplateReducer } from "../../../redux/slices/Case/caseSlice";
import { api } from "../../../api/api_route";

const fakeData = [];

export const usStates = ["Yes", "No"];

const DatePickerField = ({ field, form, ...other }) => {
  const { name, value } = field;

  const handleChange = (val) => {
    form.setFieldValue(name, val);
  };

  return (
    <>
      <ReactDatePicker
        sx={{ m: 0, p: 2 }}
        className="p-2 form-control"
        {...other}
        selected={value}
        onChange={handleChange}
        dateFormat="dd-MM-yyyy"
        placeholderText="Select Date"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        error={form.touched[name] && form.errors[name]}
      />
      {form.touched[name] && form.errors[name] && (
        <FormHelperText error>{form.errors[name]}</FormHelperText>
      )}
    </>
  );
};

const CaseTemplateList = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [editedUsers, setEditedUsers] = useState({});

  const caseDeatilsData = useSelector(
    (state) => state.case?.caseMappingDetails?.mappingTemplate
  );

  const organizationName = useSelector((state) => state?.organization?.profile);
  const caseType = useSelector((state) => state?.case?.settings);

  const dispatch = useDispatch();

  const caseTemplate = useSelector(
    (state) => state.case.caseMappingDetails.allCaseMappingDetails
  );

  useEffect(() => {
    dispatch(allCaseMappingDetailAction());
  }, [caseDeatilsData]);

  return (
    <>
      {!caseDeatilsData[0] ? (
        <>
          <div
            className=" bg-white shadow p-3 mb-5 rounded"
            sx={{
              width: 400,
              "@media (min-width: 300px)": {
                width: 300,
              },
              "@media (min-width: 400px)": {
                width: 350,
              },
              "@media (min-width: 600px)": {
                width: 280,
              },
              "@media (min-width: 900px)": {
                width: 400,
              },
              "@media (min-width: 1200px)": {
                width: 400,
              },
              "@media (min-width: 1536px)": {
                width: 400,
              },
            }}
          >
            <h5>Case Template</h5>

            <hr />

            <Formik
              initialValues={{
                organizationName: "",
                caseType: "",
              }}
              validationSchema={Yup.object().shape({
                organizationName: Yup.string().required(
                  "Organization Name is required"
                ),
                caseType: Yup.string().required("Case Type is required"),
              })}
              onSubmit={async (values, actions) => {
                try {
                  await dispatch(selectedCaseMappingDetailAction(values));
                } catch (error) {
                  console.error("Error uploading:", error);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <FormControl
                      style={{ marginRight: "20px", minWidth: "200px" }}
                      sx={{ my: 2 }}
                    >
                      <InputLabel id="organization">
                        Organization Name
                      </InputLabel>
                      <Select
                        labelId="organization"
                        id="organization"
                        label="Organization Name"
                        name="organizationName"
                        value={values.organizationName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.organizationName && errors.organizationName
                        )}
                      >
                        {organizationName?.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.organizationName}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.organizationName && errors.organizationName && (
                        <FormHelperText error>
                          {errors.organizationName}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl
                      style={{ marginRight: "20px", minWidth: "200px" }}
                      sx={{ my: 2 }}
                    >
                      <InputLabel id="caseType">Case Type</InputLabel>
                      <Select
                        labelId="caseType"
                        id="caseType"
                        label="Case Type"
                        name="caseType"
                        value={values.caseType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.caseType && errors.caseType)}
                      >
                        {caseType?.map((item, index) => (
                          <MenuItem key={index} value={item.generalSettingsID}>
                            {item.categoryName}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.caseType && errors.caseType && (
                        <FormHelperText error>{errors.caseType}</FormHelperText>
                      )}
                    </FormControl>

                    <Button variant="contained" type="submit">
                      Search
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          {caseTemplate?.[0] && (
            <CaseTemplateListTable caseTemplates={caseTemplate} />
          )}
        </>
      ) : (
        <EditCaseTemplate />
      )}
    </>
  );
};

const CaseTemplateListTable = ({ caseTemplates }) => {
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState();
  const dispatch = useDispatch();

  const [editValue, setEditValue] = useState({});

  const userId = useSelector((state) => state?.login.data);

  const tableStyle = {
    WebkitAlignItems: "flex-start",
    WebkitBoxAlign: "flex-start",
    MsFlexAlign: "flex-start",
    alignItems: "flex-start",
    boxSizing: "border-box",
    display: "-webkit-box",
    display: "-webkit-flex",
    display: "-ms-flexbox",
    display: "flex",
    gap: "0.5rem",
    WebkitBoxPack: "justify",
    WebkitJustifyContent: "space-between",
    justifyContent: "flex-end",
    padding: "0.5rem",
    position: "relative",
    right: 0,
    top: 0,
    width: "100%",
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isActiveTemplate = (findData) => {
    dispatch(allCaseMappingDetailAction(findData));
    setIsActive();
  };

  useEffect(() => {
    if (isActive) {
      isActiveTemplate();
    }
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      isActiveTemplate();
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "templateName",
        header: "Template Name",
        size: 200,
        Cell: ({ row }, rowIndex) => {
          return (
            <Link
              style={{ textDecoration: "none" }}
              to=""
              onClick={() => {
                if (window.edit) {
                  dispatch(mappingTemplateReducer(row.original));

                  setOpen(true);
                  setEditValue(row.original);
                } else {
                  animateCss("info", "You are not authorized to update");
                }
              }}
            >
              {row?.original?.templateName}
            </Link>
          );
        },
      },
      {
        accessorKey: "mapDate",
        size: 180,
        id: "mapDate",
        header: "Template Date",
        filterVariant: "date-range",
        accessorFn: (originalRow) => new Date(originalRow.mapDate),
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
      },
      {
        accessorKey: "organization.organizationName",
        header: "Organization ",
        size: 180,
      },
      {
        accessorKey: "caseType.categoryName",
        header: "Case Type",
        size: 150,
      },

      {
        accessorKey: "uploadName",
        header: "Template",
        size: 150,
        Cell: ({ row }, rowIndex) => {
          const values = row?.original.mappingData?.[0];
          return (
            <a
              href={`${api}/documents/template/${row?.original?.uploadName}`}
              download="allotmentDocument.xlsx"
            >
              <Tooltip title="Template download">
                <IconButton variant="circular" edge="end" className="">
                  <PiMicrosoftExcelLogoFill className="text-success" />
                </IconButton>
              </Tooltip>
            </a>
          );
        },
      },
      {
        accessorKey: "creater.name",
        header: "Created By",
        size: 180,
        Cell: ({ row }, rowIndex) => {
          const values = row?.original;

          return (
            <Box display="flex" justifyContent="center">
              <p>{values?.creater?.name}</p>
            </Box>
          );
        },
      },
      {
        accessorKey: "updator.name",
        header: "Last Modify By",
        size: 200,
        Cell: ({ row }, rowIndex) => {
          const values = row?.original;

          return (
            <Box display="flex" justifyContent="center">
              <p>{values?.updator?.name}</p>
            </Box>
          );
        },
      },

      {
        accessorKey: "updatedAt",
        size: 200,
        id: "updatedAt",
        header: "Last Modify Date",
        filterVariant: "date-range",
        accessorFn: (originalRow) => new Date(originalRow.updatedAt),
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
      },
      {
        accessorKey: "userStatus",
        header: "Status",
        size: 150,
        Cell: ({ row }, rowIndex) => {
          return (
            <Box display="flex" justifyContent="center">
              <Tooltip
                title={
                  row?.original?.isActive
                    ? "Active Template"
                    : "Inactive Template"
                }
                onClick={() => {
                  if (window.edit) {
                    const data = {
                      isActiveData: {
                        caseMapRowID: row?.original?.caseMapRowID,
                        isActive: row?.original?.isActive,
                      },
                      User: userId.id,
                    };
                    const findData = {
                      organizationName: row?.original?.organization?.id,
                      caseType: row?.original?.caseType?.generalSettingsID,
                    };
                    dispatch(caseMappingDetailIsActiveAction(data));
                    setIsActive(findData);
                    isActiveTemplate(findData);
                  } else {
                    animateCss("info", "You are not authorized to update");
                  }
                }}
              >
                <IconButton variant="circular" edge="end">
                  {row?.original?.isActive ? (
                    <DoneAllIcon color="success" />
                  ) : (
                    <DoDisturbAltIcon color="error" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    []
  );

  const exportData = async (rows) => {
    try {
      const tableData = await Promise.all(
        rows.map(async (item, index) => {
          if (item.fieldNotNeeded !== true) {
            return item.header;
          }
        })
      );
      const filteredTableData = tableData.filter((item) => item !== undefined);

      const valuesArray = [filteredTableData];

      const ws = XLSX.utils.aoa_to_sheet(valuesArray);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Case Template");

      XLSX.writeFile(wb, "case_template.xlsx");
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: caseTemplates ?? [],
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableFullScreenToggle: false,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableRowVirtualization: false,
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableStickyHeader: true,
    enableGlobalFilter: true,
    enableColumnPinning: true,
    layoutMode: "grid-no-grow",
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },

      density: "compact",
    },
    keyAccessor: (row, rowIndex) =>
      row.orderStatus + "_" + row.id + "_" + rowIndex,
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};
export default CaseTemplateList;

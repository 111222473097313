import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "../../helpers/cookie";
import { getUserAction } from "../../redux/actions/loginAction";

// ==============================|| MINIMAL LAYOUT ||============================== //

export default function MinimalLayout() {
  const login = useSelector((state) => state.login);
  const isLogggedIn = login?.data?.role ? login?.data?.role?.role : "";
  const userRoute = useSelector((state) => state?.userRoleRouters?.landingPage);

  const dispatch = useDispatch();

  useEffect(() => {
    if (getCookie("accessToken")) {
      dispatch(getUserAction());
    }
  }, []);

  if (isLogggedIn) {
    const role =
      typeof isLogggedIn === "string" ? isLogggedIn.toLowerCase() : "";

    if (role) {
      if (userRoute?.[0]?.navPath) {
        return <Navigate to={userRoute && userRoute?.[0]?.navPath} />;
      }
    }
    if (!role) {
      return <Navigate to="/" />;
    }
  }

  return (
    <>
      <Outlet />
    </>
  );
}

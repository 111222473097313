import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  styled,
  SvgIcon,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IoMdAttach } from "react-icons/io";
import * as Yup from "yup";
import { IoDocumentAttach } from "react-icons/io5";

import { deepOrange } from "@mui/material/colors";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allAgreementDocumentListAction,
  singleCaseDetailsAction,
  updateAppointmentOfArbitratorSec11and12Action,
  updateArbitrationClausAndAgreementAction,
  updateArbitrationEnforcementAndAppealsAction,
  updateArbitrationFeePaymentStatusAction,
  updateArbitrationHoldWithdrawalAndSettlementAction,
  updateArbitrationInterimOrdersReliefsAwardsAction,
  updateArbitrationWitnessEvidenceAndExpertReportAction,
  updateInitiationOfArbitrationAction,
} from "../../../redux/actions/Case/caseAction";
import { useEffect, useState } from "react";
import { animateCss } from "../../TosterAlert";
import ArbitrationDocumentList from "./ArbitrationDocumentList";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
const MAX_COUNT = 50;
const PreliminaryChecksCommencement = () => {
  const [open, setOpen] = useState(false);
  const [editInitiationOfArbitration, setEditInitiationOfArbitration] =
    useState(false);
  const [editfArbitrationProceedings, setEditfArbitrationProceedings] =
    useState(false);
  const [
    editArbitrationInterimOrdersReliefsAwards,
    setEditArbitrationInterimOrdersReliefsAwards,
  ] = useState(false);
  const [
    editArbitrationWitnessEvidenceAndExpertReport,
    setEditArbitrationWitnessEvidenceAndExpertReport,
  ] = useState(false);
  const [
    editArbitrationEnforcementAndAppeals,
    setEditArbitrationEnforcementAndAppeals,
  ] = useState(false);

  const [
    editArbitrationHoldWithdrawalAndSettlement,
    setEditArbitrationHoldWithdrawalAndSettlement,
  ] = useState(false);
  const [editArbitrationFeesAndExpenses, setEditArbitrationFeesAndExpenses] =
    useState(false);
  const [arbitrationClauseAgreement, setArbitrationClauseAgreement] =
    useState(false);
  // const rowData = {};
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (file.type == "application/pdf") {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          uploaded.push(file);
          if (uploaded?.length === MAX_COUNT) setFileLimit(true);
          if (uploaded?.length > MAX_COUNT) {
            alert(`You can only add a maximum of ${MAX_COUNT} files`);
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
        }
      } else if (file.type !== "application/pdf") {
        animateCss("error", "Please Upload PDF Document Only");
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleDelete = (chipToDelete) => () => {
    setUploadedFiles((chips) =>
      chips.filter((chip) => chip.name !== chipToDelete.name)
    );
  };

  const useStyles = makeStyles((theme) => ({
    textarea: {
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "1%",
      fontSize: "1rem",
      // borderRadius: "4px",
      // border: "1px solid #ccc",
      resize: "vertical",
      minHeight: "100px",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  function trackerFormatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const { id } = useParams();
  const params = { caseID: id };
  const user = useSelector((state) => state.login.data);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);
  const rowData = useSelector((state) => state?.case?.editCaseDetails);

  const defaultCategory = settings
    ?.find((item) => item?.categoryName === "Mode of Proceedings")
    ?.Children?.find(
      (data) => data?.categoryName.toLowerCase() === "physical"
    )?.generalSettingsID;

  const StyledButton = styled(Button)(({ theme }) => ({
    color: "white",
    marginLeft: theme.spacing(1),
  }));

  const arbitrationAgreementAttachment = useSelector(
    (state) => state.case.agreementDocumentList
  );

  useEffect(() => {
    dispatch(
      allAgreementDocumentListAction({
        value: "Arbitration Agreement",
        caseID: id,
      })
    );
  }, []);

  return (
    <>
      <ArbitrationDocumentList
        open={open}
        setOpen={setOpen}
        checklistDocuments={{}}
      />
      <Card
        variant="outlined"
        className=" mt-3 md-6 xl-6 xxl-6"
        sx={{ maxWidth: "100%" }}
        xs={12}
        sm={12}
        md={6}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="m-0 px-2  text-secondary"
        >
          Preliminary Checks & Commencement
        </Typography>

        <div
          className="d-flex row"
          spacing={2}
          style={{ backgroundColor: "#fbfbfc" }}
        >
          {/* {editArbitrationEnforcementAndAppeals &&
          expanded === "Arbitration Hold, Withdrawal, and Settlement" ? (
            <Formik
              initialValues={{
                noticeToSuspendArbitrationProceedingsReceivedOn:
                  !rowData?.caseID
                    ? null
                    : rowData?.NoticeToSuspendArbitrationProceedingsReceivedOn,
                noticeToResumeArbitrationProceedingsFromHoldReceivedOn:
                  !rowData?.caseID
                    ? null
                    : rowData?.NoticeToResumeArbitrationProceedingsFromHoldReceivedOn,
                noticeOfArbitrationWithdrawalReceivedOn: !rowData?.caseID
                  ? null
                  : rowData?.NoticeOfArbitrationWithdrawalReceivedOn,
                noticeOfDisputeSettlementReceivedOn: !rowData?.caseID
                  ? null
                  : rowData?.NoticeOfDisputeSettlementReceivedOn,
                settlementAgreementFiledOn: !rowData?.caseID
                  ? null
                  : rowData?.SettlementAgreementFiledOn,
                params,
                user,
              }}
              onSubmit={async (values, actions) => {
                try {
                  dispatch(
                    updateArbitrationHoldWithdrawalAndSettlementAction(values)
                  );
                  setEditArbitrationEnforcementAndAppeals(false);
                  setTimeout(() => {
                    dispatch(singleCaseDetailsAction(params));
                  }, 1000);
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                return (
                  <div className="d-flex  col-12 mt-2">
                    <form onSubmit={handleSubmit} xs={12} sm={12} md={12}>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="m-0 "
                            >
                              Arbitration Hold, Withdrawal, and Settlement
                            </Typography>

                            <div>
                              <Button
                                className="mx-2"
                                type="button"
                                variant="outlined"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEditArbitrationEnforcementAndAppeals(
                                    false
                                  );
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                // color="primary"
                                variant="contained"
                                onClick={async () => {}}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <Box
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                backgroundColor: "white",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    }, // Responsive layout
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    }, // Adjust width based on screen size
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Notice to Suspend Arbitration Proceedings
                                    Received On
                                  </Typography>

                                  <OutlinedInput
                                    id="noticeToSuspendArbitrationProceedingsReceivedOn"
                                    name="noticeToSuspendArbitrationProceedingsReceivedOn"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={
                                      values?.noticeToSuspendArbitrationProceedingsReceivedOn
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    }, // Responsive layout
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    }, // Adjust width based on screen size
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Notice to Resume Arbitration Proceedings
                                    from Hold Received on
                                  </Typography>

                                  <OutlinedInput
                                    id="noticeToResumeArbitrationProceedingsFromHoldReceivedOn"
                                    name="noticeToResumeArbitrationProceedingsFromHoldReceivedOn"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={
                                      values?.noticeToResumeArbitrationProceedingsFromHoldReceivedOn
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    }, // Responsive layout
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    }, // Adjust width based on screen size
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Notice of Arbitration Withdrawal Received On
                                  </Typography>

                                  <OutlinedInput
                                    id="noticeOfArbitrationWithdrawalReceivedOn"
                                    name="noticeOfArbitrationWithdrawalReceivedOn"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={
                                      values?.noticeOfArbitrationWithdrawalReceivedOn
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    }, // Responsive layout
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    }, // Adjust width based on screen size
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Notice of Dispute Settlement Received On
                                  </Typography>

                                  <OutlinedInput
                                    id="noticeOfDisputeSettlementReceivedOn"
                                    name="noticeOfDisputeSettlementReceivedOn"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={
                                      values?.noticeOfDisputeSettlementReceivedOn
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    }, // Responsive layout
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    }, // Adjust width based on screen size
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Settlement Agreement Filed On
                                  </Typography>

                                  <OutlinedInput
                                    id="settlementAgreementFiledOn"
                                    name="settlementAgreementFiledOn"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={values?.settlementAgreementFiledOn}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                              </LocalizationProvider>
                            </Box>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <>
              <Accordion
                className="mt-2"
                expanded={
                  expanded === "Arbitration Hold, Withdrawal, and Settlement"
                }
                onChange={handleChange(
                  "Arbitration Hold, Withdrawal, and Settlement"
                )}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0 "
                    >
                      Arbitration Hold, Withdrawal, and Settlement
                    </Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              setEditArbitrationEnforcementAndAppeals(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                          type="button"
                          color="primary"
                          variant="contained"
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary=" Notice to Suspend Arbitration Proceedings Received On"
                          secondary={
                            rowData?.NoticeToSuspendArbitrationProceedingsReceivedOn
                          }
                        />
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary=" Notice to Resume Arbitration Proceedings from Hold Received on"
                          secondary={
                            rowData?.NoticeToResumeArbitrationProceedingsFromHoldReceivedOn
                          }
                        />
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Notice of Arbitration Withdrawal Received On"
                          secondary={
                            rowData?.NoticeOfArbitrationWithdrawalReceivedOn
                          }
                        />
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Notice of Dispute Settlement Received On"
                          secondary={
                            rowData?.NoticeOfDisputeSettlementReceivedOn
                          }
                        />
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Settlement Agreement Filed On"
                          secondary={rowData?.SettlementAgreementFiledOn}
                        />
                      </List>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )} */}

          {/* {editArbitrationHoldWithdrawalAndSettlement &&
          expanded === "Arbitration Enforcement and Appeals" ? (
            <Formik
              initialValues={{
                enforcementAppFiledOn: !rowData?.caseID
                  ? null
                  : rowData?.EnforcementAppFiledOn,
                enforcementStatus: !rowData?.caseID
                  ? null
                  : rowData?.EnforcementStatus,
                appealFiledOn: !rowData?.caseID ? null : rowData?.AppealFiledOn,
                appealStatus: !rowData?.caseID ? null : rowData?.AppealStatus,
                params,
                user,
              }}
              onSubmit={async (values, actions) => {
                try {
                  dispatch(
                    updateArbitrationEnforcementAndAppealsAction(values)
                  );

                  setEditArbitrationHoldWithdrawalAndSettlement(false);
                  setTimeout(() => {
                    dispatch(singleCaseDetailsAction(params));
                  }, 1000);
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                return (
                  <div className="d-flex row p-0 m-0  col-12 mt-2">
                    <form onSubmit={handleSubmit} xs={12} sm={12} md={12}>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="m-0 "
                            >
                              Arbitration Enforcement and Appeals
                            </Typography>

                            <div>
                              <Button
                                className="mx-2"
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEditArbitrationHoldWithdrawalAndSettlement(
                                    false
                                  );
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                // color="primary"
                                variant="contained"
                                onClick={async () => {}}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <Box
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                backgroundColor: "white",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    }, // Responsive layout
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    }, // Adjust width based on screen size
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Enforcement Application Filed On:
                                  </Typography>

                                  <OutlinedInput
                                    id="enforcementAppFiledOn"
                                    name="enforcementAppFiledOn"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={values?.enforcementAppFiledOn}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    }, // Responsive layout
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    }, // Adjust width based on screen size
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <InputLabel
                                    htmlFor="enforcementStatus"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Enforcement Status
                                  </InputLabel>

                                  <Select
                                    labelId="enforcementStatus"
                                    id="enforcementStatus"
                                    name="enforcementStatus"
                                    value={values?.enforcementStatus}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth // Ensures the Select is 100% width
                                  >
                                    {settings
                                      ?.find(
                                        (item) =>
                                          item?.categoryName ==
                                          "Status of Application"
                                      )
                                      ?.Children?.map((data, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={data?.generalSettingsID}
                                          >
                                            {data?.categoryName}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </Box>

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    }, // Responsive layout
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    }, // Adjust width based on screen size
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Appeal Filed On
                                  </Typography>

                                  <OutlinedInput
                                    id="appealFiledOn"
                                    name="appealFiledOn"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={values?.appealFiledOn}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    }, // Responsive layout
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    }, // Adjust width based on screen size
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <InputLabel
                                    htmlFor="appealStatus"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Appeal Status
                                  </InputLabel>

                                  <Select
                                    labelId="appealStatus"
                                    id="appealStatus"
                                    name="appealStatus"
                                    value={values?.appealStatus}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth // Ensures the Select is 100% width
                                  >
                                    {settings
                                      ?.find(
                                        (item) =>
                                          item?.categoryName ==
                                          "Status of Application"
                                      )
                                      ?.Children?.map((data, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={data?.generalSettingsID}
                                          >
                                            {data?.categoryName}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </Box>
                              </LocalizationProvider>
                            </Box>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <>
              <Accordion
                className="mt-2"
                expanded={expanded === "Arbitration Enforcement and Appeals"}
                onChange={handleChange("Arbitration Enforcement and Appeals")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0 "
                    >
                      Arbitration Enforcement and Appeals
                    </Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              setEditArbitrationHoldWithdrawalAndSettlement(
                                true
                              );
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                          type="button"
                          color="primary"
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Enforcement Application Filed On"
                          secondary={rowData?.EnforcementAppFiledOn}
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Enforcement Status"
                          secondary={rowData?.enforcementStatus?.categoryName}
                        />
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Appeal Filed On"
                          secondary={rowData?.AppealFiledOn}
                        />
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Appeal Status"
                          secondary={rowData?.appealStatus?.categoryName}
                        />
                      </List>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )} */}

          {editArbitrationFeesAndExpenses &&
          expanded === "Arbitration Fees and Expenses" ? (
            <Formik
              initialValues={{
                arbitrationFeePaymentStatus: !rowData?.caseID
                  ? null
                  : rowData?.ArbitrationFeePaymentStatus,
                params,
                user,
              }}
              onSubmit={async (values, actions) => {
                try {
                  dispatch(updateArbitrationFeePaymentStatusAction(values));
                  setEditArbitrationFeesAndExpenses(false);
                  setTimeout(() => {
                    dispatch(singleCaseDetailsAction(params));
                  }, 1000);
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                return (
                  <div className="d-flex  col-12 mt-2">
                    <form
                      onSubmit={handleSubmit}
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ width: "100%" }}
                    >
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="m-0 "
                            >
                              Arbitration Fees and Expenses
                            </Typography>

                            <div>
                              <Button
                                className="mx-2"
                                type="button"
                                variant="outlined"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEditArbitrationFeesAndExpenses(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                // color="primary"
                                variant="contained"
                                onClick={async () => {}}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <Box
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                backgroundColor: "white",
                                flexWrap: "wrap",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "100%",
                                      md: "100%",
                                    },
                                  }}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                >
                                  <InputLabel
                                    htmlFor="caseStatus"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Arbitration Fee Payment Status
                                  </InputLabel>
                                </Box>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "100%",
                                      md: "100%",
                                    },
                                  }}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                >
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="arbitrationFeePaymentStatus"
                                    name="arbitrationFeePaymentStatus"
                                    value={values?.arbitrationFeePaymentStatus}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    {settings
                                      ?.find(
                                        (item) =>
                                          item?.categoryName == "Payment Status"
                                      )
                                      ?.Children?.map((data, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={data?.generalSettingsID}
                                          >
                                            {data?.categoryName}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </Box>
                              </LocalizationProvider>
                            </Box>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <>
              <Accordion
                className="mt-2"
                expanded={expanded === "Arbitration Fees and Expenses"}
                onChange={handleChange("Arbitration Fees and Expenses")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0 "
                    >
                      Arbitration Fees and Expenses
                    </Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              setEditArbitrationFeesAndExpenses(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                          type="button"
                          color="primary"
                          variant="contained"
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Arbitration Fee Payment Status"
                          secondary={
                            rowData?.arbitrationFeePaymentStatus?.categoryName
                          }
                        />
                      </List>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default PreliminaryChecksCommencement;

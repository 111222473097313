import axios from "axios";
import { api } from "./api_route";

export const createChecklistDocuments = async (values) => {
  try {
    const result = await axios.post(`${api}/checklistDocuments/upload`, values);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const createChecklistDocumentsPDFSplit = async (values) => {
  try {
    const result = await axios.post(
      `${api}/checklistDocuments/pdfSplit`,
      values
    );

    return result.data;
  } catch (error) {
    return [];
  }
};

export const findCaseChecklistDocument = async (values) => {
  try {
    const result = await axios.post(`${api}/checklistDocuments/`, values);

    return values !== "" ? result.data : "";
  } catch (error) {
    return [];
  }
};

export const checklistDocumentUplodOptions = async (values) => {
  try {
    const result = await axios.get(`${api}/checklistDocuments/options`);

    return values !== "" ? result.data : "";
  } catch (error) {
    return [];
  }
};

export const deleteCaseChecklistDocument = async (values) => {
  try {
    const result = await axios.post(
      `${api}/checklistDocuments/deleteCaseChecklistDocument`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const organizationDocumentBulkUpdate = async (values) => {
  try {
    const result = await axios.post(
      `${api}/organization/organizationDocumentBulkUpdate`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

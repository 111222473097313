import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FieldArray, Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePicker from "react-datepicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allAgreementDocumentListAction,
  preTrailCaseDetailsDocumentsAction,
  preTrailCaseDetailsCreateAction,
  singleCaseDetailsAction,
  preTrailCaseDetailsUpdateAction,
  preTrailCaseDetailsDeleteAction,
} from "../../../../redux/actions/Case/caseAction";
import React, { useEffect, useState } from "react";
import { animateCss } from "../../../TosterAlert";
import { MdDelete, MdEdit } from "react-icons/md";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { IoIosOpen, IoMdAttach } from "react-icons/io";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const PreTrialApplication = ({
  open,
  setOpen,
  expanded,
  setExpanded,
  preTrialApplications,
  setPreTrialApplications,
  editIndex,
  setEditIndex,
  openModel,
  setOpenModel,
  expandedAccordionRef,
  preTrialApplicationsData,
  setPreTrialApplicationsData,
  viewDocument,
  setViewDocument,
  handleAccordionChange,
}) => {
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState({});

  const { id } = useParams();
  const params = { caseID: id };
  const user = useSelector((state) => state.login.data);
  const rowData = useSelector((state) => state?.case?.editCaseDetails);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);
  const preTrailCaseDetailsDocuments = useSelector(
    (state) => state?.case?.preTrailCaseDetailsDocuments
  );

  const preTrialData = rowData.preTrialApplications
    ? JSON.parse(rowData.preTrialApplications)
    : "[]";

  const preTrialJSONData = preTrialData ? JSON.parse(preTrialData) : [];

  const documentId = preTrialJSONData?.flatMap((item) => {
    return [
      ...(item?.["Claimant Pre-Trail Application Attachments"] || []),
      ...(item?.["Respondent Pre-Trail Application Attachments"] || []),
      ...(item?.["List Of Documents To Respondent Served By Attachment"] || []),
      ...(item?.["List Of Documents To Claimant Served By Attachment"] || []),
    ];
  });

  const uniqueDocumentId = [...new Set(documentId)];

  useEffect(() => {
    setPreTrialApplicationsData(preTrialJSONData);
    const document = {
      uniqueDocumentId,
      id,
    };
    dispatch(
      allAgreementDocumentListAction({
        value: "Arbitration Agreement",
        caseID: id,
      })
    );
  }, []);

  useEffect(() => {
    setPreTrialApplicationsData(preTrialJSONData);

    const document = {
      uniqueDocumentId,
      id,
    };

    setPreTrialApplicationsData(preTrialJSONData);
  }, [openModel, open]);

  return (
    <>
      <div
        className="d-flex  row p-0 m-0"
        spacing={2}
        style={{ backgroundColor: "#fbfbfc" }}
      >
        <div className="d-flex p-0 m-0 row col-12 mt-2  ">
          <form
            className="pe-0"
            // onSubmit={handleSubmit}
            style={{ boxShadow: "none" }}
            xs={12}
            sm={12}
            md={12}
          >
            <Accordion expanded={expanded === 0}>
              <AccordionSummary
                onClick={(event) => {
                  event.stopPropagation();
                  handleAccordionChange(0);
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="m-0"
                    // sx={{
                    //   "&:hover": {
                    //     color: "primary.main",
                    //   },
                    // }}
                    // onClick={() => handleAccordionChange(0)}
                  >
                    Pre-Trial Applications
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "end",
                    px: 2,
                    gridTemplateColumns: {
                      xs: "1fr",
                      sm: "1fr",
                      md: "1fr",
                    },
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                    },
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  {window.edit && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(event) => {
                        event.stopPropagation();
                        setEditIndex(null);
                        setExpanded(0);
                        setOpenModel(true);
                      }}
                      disabled={preTrialApplicationsData?.length == 5}
                      className="justify-content-center"
                    >
                      File Pre-Trial Application
                    </Button>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        backgroundColor: "white",
                        flexWrap: "wrap",
                        alignItems: "stretch",
                      }}
                    >
                      <>
                        {openModel && expanded === 0 && (
                          <IntrimOrder
                            // values={values}
                            setPreTrialApplications={setPreTrialApplications}
                            // setFieldValue={setFieldValue}
                            // handleBlur={handleBlur}
                            // handleSubmit={handleSubmit}
                            // handleChange={handleChange}
                            settings={settings}
                            preTrialApplicationsData={preTrialApplicationsData}
                            setOpenModel={setOpenModel}
                            openModel={openModel}
                            editIndex={editIndex}
                            setEditIndex={setEditIndex}
                          />
                        )}

                        <Box
                          sx={{
                            display: "grid",
                            // px: 2,
                            gridTemplateColumns: {
                              xs: "1fr",
                              sm: "1fr",
                              md: "1fr",
                            },
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                            },
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Box
                            className="d-flex col-12"
                            // style={{
                            //   overflowX: "auto",
                            //   width: "100%",
                            // }}
                          >
                            <table
                              style={{ overflowWrap: "anywhere" }}
                              className="my-2 table table-striped table-bordered"
                            >
                              <thead
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 800,
                                }}
                              >
                                <tr>
                                  <th
                                    style={{
                                      fontWeight: "lighter",
                                    }}
                                    className="border"
                                  >
                                    Pre-Trial Order
                                  </th>

                                  <th
                                    style={{
                                      fontWeight: "lighter",
                                    }}
                                    className="border"
                                  >
                                    Applications Filed By
                                  </th>

                                  <th
                                    style={{
                                      fontWeight: "lighter",
                                    }}
                                    className="border"
                                  >
                                    Claimant’s Application Status
                                  </th>

                                  <th
                                    style={{
                                      fontWeight: "lighter",
                                    }}
                                    className="border"
                                  >
                                    Claimant’s Application Attachments
                                  </th>

                                  <th
                                    style={{
                                      fontWeight: "lighter",
                                    }}
                                    className="border"
                                  >
                                    Documents to Claimant Served by
                                  </th>
                                  <th
                                    style={{
                                      fontWeight: "lighter",
                                    }}
                                    className="border"
                                  >
                                    Respondent’s Application Status
                                  </th>

                                  <th
                                    style={{
                                      fontWeight: "lighter",
                                    }}
                                    className="border"
                                  >
                                    Respondent’s Application Attachments
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontWeight: "lighter",
                                      width: "65px",
                                    }}
                                    className="border"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {preTrialJSONData?.map((order, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Typography
                                          component="a"
                                          className="m-0"
                                          sx={{
                                            ...(window.edit && {
                                              color: "primary.main",
                                              cursor: "pointer",
                                              textDecoration: "none",
                                              fontWeight: 800,
                                            }),
                                            ...(!window.edit && {
                                              color: "secondary.main",
                                              cursor: "pointer",
                                              textDecoration: "none",
                                              fontWeight: 800,
                                            }),
                                          }}
                                          onClick={() => {
                                            if (window.edit) {
                                              const documentId =
                                                preTrialJSONData?.[index]
                                                  ? [
                                                      ...(preTrialJSONData[
                                                        index
                                                      ]?.[
                                                        "Claimant Pre-Trail Application Attachments"
                                                      ] || []),
                                                      ...(preTrialJSONData[
                                                        index
                                                      ]?.[
                                                        "Respondent Pre-Trail Application Attachments"
                                                      ] || []),
                                                      ...(preTrialJSONData[
                                                        index
                                                      ]?.[
                                                        "List Of Documents To Respondent Served By Attachment"
                                                      ] || []),
                                                      ...(preTrialJSONData[
                                                        index
                                                      ]?.[
                                                        "List Of Documents To Claimant Served By Attachment"
                                                      ] || []),
                                                    ]
                                                  : [];

                                              const uniqueDocumentId = [
                                                ...new Set(documentId),
                                              ];

                                              const document = {
                                                uniqueDocumentId,
                                                id,
                                              };

                                              setOpenModel(true);
                                              setEditIndex(index);

                                              dispatch(
                                                preTrailCaseDetailsDocumentsAction(
                                                  document
                                                )
                                              );
                                            } else {
                                              animateCss(
                                                "info",
                                                "You are not authorized to update"
                                              );
                                            }
                                          }}
                                        >
                                          Order {index + 1}
                                        </Typography>
                                      </td>

                                      <td style={{ fontSize: "12px" }}>
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ===
                                              "Pre-Trail Applications Filed by"
                                          )
                                          ?.Children?.find(
                                            (child) =>
                                              String(
                                                child.generalSettingsID
                                              ) ===
                                              String(
                                                order?.preTrailApplicationsFiledBy
                                              )
                                          )?.categoryName || ""}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                        }}
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ===
                                              "Claimant’s Pre-Trail Application Status"
                                          )
                                          ?.Children?.find(
                                            (child) =>
                                              String(
                                                child.generalSettingsID
                                              ) ===
                                              String(
                                                order?.claimantsPreTrailApplicationStatus
                                              )
                                          )?.categoryName || ""}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                        }}
                                      >
                                        {order?.[
                                          "Claimant Pre-Trail Application Attachments"
                                        ]?.length > 0 && (
                                          <Button
                                            onClick={() => {
                                              setViewDocument(true);
                                              const documentId =
                                                preTrialJSONData?.[index]
                                                  ? [
                                                      ...(preTrialJSONData[
                                                        index
                                                      ]?.[
                                                        "Claimant Pre-Trail Application Attachments"
                                                      ] || []),
                                                    ]
                                                  : [];

                                              const uniqueDocumentId = [
                                                ...new Set(documentId),
                                              ];

                                              const document = {
                                                uniqueDocumentId,
                                                id,
                                              };

                                              dispatch(
                                                preTrailCaseDetailsDocumentsAction(
                                                  document
                                                )
                                              );
                                            }}
                                            aria-label="attach"
                                            onMouseEnter={() =>
                                              setHovered({
                                                id: index,
                                                open: true,
                                                key: "Claimant Pre-Trail Application Attachments",
                                              })
                                            }
                                            onMouseLeave={() =>
                                              setHovered({
                                                id: index,
                                                open: false,
                                                key: "Claimant Pre-Trail Application Attachments",
                                              })
                                            }
                                          >
                                            <IoIosOpen className="fs-4 text-primary" />{" "}
                                            <span
                                              className={`transition-opacity duration-500 ${
                                                hovered.id === index &&
                                                hovered.open &&
                                                hovered.key ===
                                                  "Claimant Pre-Trail Application Attachments"
                                                  ? "opacity-100"
                                                  : "opacity-0"
                                              }`}
                                            >
                                              {" "}
                                              View Attachment
                                            </span>
                                          </Button>
                                        )}
                                      </td>

                                      <td
                                        style={{
                                          fontSize: "12px",
                                        }}
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ===
                                              "List of Documents to Respondent Served by"
                                          )
                                          ?.Children?.find(
                                            (child) =>
                                              String(
                                                child.generalSettingsID
                                              ) ===
                                              String(
                                                order?.listOfDocumentsToRespondentServedBy
                                              )
                                          )?.categoryName || ""}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                        }}
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ===
                                              "Respondent’s Pre-Trail Application Status"
                                          )
                                          ?.Children?.find(
                                            (child) =>
                                              String(
                                                child.generalSettingsID
                                              ) ===
                                              String(
                                                order?.respondentsPreTrailApplicationStatus
                                              )
                                          )?.categoryName || ""}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {order?.[
                                          "Respondent Pre-Trail Application Attachments"
                                        ]?.length > 0 && (
                                          <Button
                                            onClick={() => {
                                              setViewDocument(true);
                                              const documentId =
                                                preTrialJSONData?.[index]
                                                  ? [
                                                      ...(preTrialJSONData[
                                                        index
                                                      ]?.[
                                                        "Respondent Pre-Trail Application Attachments"
                                                      ] || []),
                                                    ]
                                                  : [];

                                              const uniqueDocumentId = [
                                                ...new Set(documentId),
                                              ];

                                              const document = {
                                                uniqueDocumentId,
                                                id,
                                              };

                                              dispatch(
                                                preTrailCaseDetailsDocumentsAction(
                                                  document
                                                )
                                              );
                                            }}
                                            aria-label="attach"
                                            onMouseEnter={() =>
                                              setHovered({
                                                id: index,
                                                open: true,
                                                key: "Respondent Pre-Trial Application Attachments",
                                              })
                                            }
                                            onMouseLeave={() =>
                                              setHovered({
                                                id: index,
                                                open: false,
                                                key: "Respondent Pre-Trial Application Attachments",
                                              })
                                            }
                                          >
                                            <IoIosOpen className="fs-4 text-primary" />{" "}
                                            <span
                                              className={`transition-opacity duration-500 ${
                                                hovered.id === index &&
                                                hovered.open &&
                                                hovered.key ===
                                                  "Respondent Pre-Trial Application Attachments"
                                                  ? "opacity-100"
                                                  : "opacity-0"
                                              }`}
                                            >
                                              {" "}
                                              View Attachment
                                            </span>
                                          </Button>
                                        )}
                                      </td>
                                      <td
                                        className={
                                          preTrialJSONData?.length - 1 ==
                                            index && "d-flex"
                                        }
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          color="error"
                                          style={{
                                            marginTop: "4%",
                                          }}
                                          onClick={() => {
                                            if (window.delete) {
                                              enqueueSnackbar(
                                                <>
                                                  <div>
                                                    Are you sure you want to
                                                    delete the record and its
                                                    associated document for
                                                    Pre-Trial Applications Order{" "}
                                                    {index + 1} ?
                                                  </div>
                                                </>,
                                                {
                                                  variant: "warning",
                                                  onClose: () => {},
                                                  anchorOrigin: {
                                                    vertical: "top",
                                                    horizontal: "right",
                                                  },
                                                  action: (key) => (
                                                    <>
                                                      <StyledButton
                                                        onClick={async () => {
                                                          const output =
                                                            preTrialApplicationsData.filter(
                                                              (_, i) =>
                                                                i !== index
                                                            );
                                                          setPreTrialApplicationsData(
                                                            output
                                                          );
                                                          const value = {
                                                            caseID: params,
                                                            index,
                                                          };
                                                          dispatch(
                                                            preTrailCaseDetailsDeleteAction(
                                                              value
                                                            )
                                                          )
                                                            .unwrap()
                                                            .then((result) => {
                                                              if (result) {
                                                                dispatch(
                                                                  singleCaseDetailsAction(
                                                                    params
                                                                  )
                                                                );
                                                              }
                                                            })
                                                            .catch((error) => {
                                                              console.error(
                                                                "Action rejected with error:",
                                                                error
                                                              );
                                                            });

                                                          closeSnackbar(key);
                                                        }}
                                                      >
                                                        Confirm
                                                      </StyledButton>
                                                      <StyledButton
                                                        onClick={() => {
                                                          closeSnackbar(key);
                                                        }}
                                                      >
                                                        Cancel
                                                      </StyledButton>
                                                    </>
                                                  ),
                                                  autoHideDuration: null,
                                                  style: {
                                                    backgroundColor: "#f44336",
                                                    width: "600px",
                                                    color: "#fff",
                                                  },
                                                }
                                              );
                                            } else {
                                              animateCss(
                                                "info",
                                                "You are not authorized to delete"
                                              );
                                            }
                                          }}
                                          //
                                        >
                                          <MdDelete
                                            style={{
                                              fontSize: "24px",
                                            }}
                                          />
                                        </IconButton>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Box>
                        </Box>
                      </>
                    </Box>

                    {/* Dynamice Data */}
                  </LocalizationProvider>
                </>
              </AccordionDetails>
            </Accordion>
          </form>
        </div>
      </div>
    </>
  );
};

export default PreTrialApplication;

const IntrimOrder = ({
  setPreTrialApplications,
  preTrialApplicationsData,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
}) => {
  const [formData, setFormData] = useState(null);
  const data = preTrialApplicationsData;

  const claimantPreTrailApplicationAttachments =
    data[editIndex]?.["Claimant Pre-Trail Application Attachments"] ?? [];

  const respondentPreTrailApplicationAttachments =
    data[editIndex]?.["Respondent Pre-Trail Application Attachments"] ?? [];

  const listOfDocumentsToRespondentServedByAttachment =
    data[editIndex]?.["List Of Documents To Respondent Served By Attachment"] ??
    [];

  const listOfDocumentsToClaimantServedByAttachment =
    data[editIndex]?.["List Of Documents To Claimant Served By Attachment"] ??
    [];

  const uniqueClaimantPreTrailApplicationAttachments = Array.from(
    new Set(claimantPreTrailApplicationAttachments.map(Number))
  );

  const uniqueRespondentPreTrailApplicationAttachments = Array.from(
    new Set(respondentPreTrailApplicationAttachments.map(Number))
  );

  const uniqueListOfDocumentsToRespondentServedByAttachment = Array.from(
    new Set(listOfDocumentsToRespondentServedByAttachment.map(Number))
  );

  const uniqueListOfDocumentsToClaimantServedByAttachment = Array.from(
    new Set(listOfDocumentsToClaimantServedByAttachment.map(Number))
  );

  const uniqueDocumentId = [
    ...(uniqueClaimantPreTrailApplicationAttachments || []),
    ...(uniqueRespondentPreTrailApplicationAttachments || []),
    ...(uniqueListOfDocumentsToRespondentServedByAttachment || []),
    ...(uniqueListOfDocumentsToClaimantServedByAttachment || []),
  ];

  const dispatch = useDispatch();

  const { id } = useParams();

  const params = { caseID: id };

  // Updating formData based on editIndex
  useEffect(() => {
    if (editIndex >= 0) {
      setFormData(data?.[editIndex]);
    } else {
      setFormData(null);
    }
  }, [editIndex, data]);

  const reduxAttachments = useSelector(
    (state) => state.case?.preTrailCaseDetailsDocuments
  );

  const claimantsPreTrailApplicationAttachments =
    reduxAttachments?.filter((item) =>
      uniqueClaimantPreTrailApplicationAttachments?.includes(
        item.checkListDocID
      )
    ) ?? [];

  const respondentsPreTrailApplicationAttachments =
    reduxAttachments?.filter((item) =>
      uniqueRespondentPreTrailApplicationAttachments?.includes(
        item.checkListDocID
      )
    ) ?? [];

  const initialValues = {
    preTrailApplicationFiledOn: formData
      ? formData?.preTrailApplicationFiledOn
      : null,
    preTrailApplicationsFiledBy: formData?.preTrailApplicationsFiledBy ?? "",
    preTrialDocumentsNoticesServedToRespondent:
      formData?.preTrialDocumentsNoticesServedToRespondent ?? "",
    listOfDocumentsServedToRespondent:
      formData?.listOfDocumentsServedToRespondent ?? "",
    listOfDocumentsToRespondentServedOn:
      formData?.listOfDocumentsToRespondentServedOn ?? "",
    listOfDocumentsToRespondentServedBy:
      formData?.listOfDocumentsToRespondentServedBy ?? null,
    listOfDocumentsToRespondentServedByAttachment:
      reduxAttachments?.filter((item) =>
        data[editIndex]?.[
          "List Of Documents To Respondent Served By Attachment"
        ]?.includes(item.checkListDocID)
      ) ?? [],
    // formData?.listOfDocumentsToRespondentServedByAttachment ?? [],
    claimantsPreTrailApplicationStatus:
      formData?.claimantsPreTrailApplicationStatus ?? "",

    outcomeOfTheClaimantsPreTrailApplication:
      formData?.outcomeOfTheClaimantsPreTrailApplication ?? "",
    claimantsPreTrailApplicationAttachments:
      claimantsPreTrailApplicationAttachments ?? [],
    preTrialDocumentsNoticesServedToClaimant:
      Boolean(formData?.preTrialDocumentsNoticesServedToClaimant) == true
        ? true
        : false,
    listOfDocumentsServedToClaimant:
      formData?.listOfDocumentsServedToClaimant ?? "",
    listOfDocumentsToClaimantServedOn:
      formData?.listOfDocumentsToClaimantServedOn ?? "",
    listOfDocumentsToClaimantServedBy:
      formData?.listOfDocumentsToClaimantServedBy ?? "",
    listOfDocumentsToClaimantServedByAttachment:
      reduxAttachments?.filter((item) =>
        data[editIndex]?.[
          "List Of Documents To Claimant Served By Attachment"
        ]?.includes(item.checkListDocID)
      ) ?? [],
    // formData?.listOfDocumentsToClaimantServedByAttachment ?? "",
    respondentsPreTrailApplicationStatus:
      formData?.respondentsPreTrailApplicationStatus ?? "",
    outcomeOfTheRespondentsPreTrailApplication:
      formData?.outcomeOfTheRespondentsPreTrailApplication ?? "",
    respondentsPreTrailApplicationAttachments:
      respondentsPreTrailApplicationAttachments,
    editIndex: null,
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            try {
              const form = new FormData();
              form.append(
                "claimantsPreTrailApplicationStatus",
                values.claimantsPreTrailApplicationStatus
              );
              form.append(
                "listOfDocumentsServedToClaimant",
                values.listOfDocumentsServedToClaimant
              );
              form.append(
                "listOfDocumentsServedToRespondent",
                values.listOfDocumentsServedToRespondent
              );
              form.append(
                "listOfDocumentsToClaimantServedBy",
                values.listOfDocumentsToClaimantServedBy
              );
              form.append(
                "listOfDocumentsToClaimantServedOn",
                values.listOfDocumentsToClaimantServedOn
              );
              form.append(
                "listOfDocumentsToRespondentServedBy",
                values.listOfDocumentsToRespondentServedBy
              );
              form.append(
                "listOfDocumentsToRespondentServedOn",
                values.listOfDocumentsToRespondentServedOn
              );
              form.append(
                "outcomeOfTheClaimantsPreTrailApplication",
                values.outcomeOfTheClaimantsPreTrailApplication
              );
              form.append(
                "outcomeOfTheRespondentsPreTrailApplication",
                values.outcomeOfTheRespondentsPreTrailApplication
              );
              form.append(
                "preTrailApplicationFiledOn",
                values.preTrailApplicationFiledOn
              );
              form.append(
                "preTrailApplicationsFiledBy",
                values.preTrailApplicationsFiledBy
              );
              form.append(
                "preTrialDocumentsNoticesServedToClaimant",
                values.preTrialDocumentsNoticesServedToClaimant
              );
              form.append(
                "preTrialDocumentsNoticesServedToRespondent",
                values.preTrialDocumentsNoticesServedToRespondent
              );
              form.append(
                "respondentsPreTrailApplicationStatus",
                values.respondentsPreTrailApplicationStatus
              );
              form.append("caseID", id);
              if (values.claimantsPreTrailApplicationAttachments) {
                values.claimantsPreTrailApplicationAttachments.forEach(
                  (file) => {
                    if (file?.caseID) {
                      form.append(
                        `claimantPreTrailApplicationAttachmentsOld`,
                        file?.checkListDocID
                      );
                    } else {
                      form.append(
                        `Claimant Pre-Trail Application Attachments`,
                        file?.file
                      );
                    }
                  }
                );
              }

              if (values.listOfDocumentsToRespondentServedByAttachment) {
                values.listOfDocumentsToRespondentServedByAttachment.forEach(
                  (file) => {
                    if (file?.caseID) {
                      form.append(
                        `listOfDocumentsToRespondentServedByAttachmentOld`,
                        file?.checkListDocID
                      );
                    } else {
                      form.append(
                        `List Of Documents To Respondent Served By Attachment`,
                        file?.file?.file ?? file?.file
                      );
                    }
                  }
                );
              }

              if (values.listOfDocumentsToClaimantServedByAttachment) {
                values.listOfDocumentsToClaimantServedByAttachment.forEach(
                  (file) => {
                    if (file?.caseID) {
                      form.append(
                        `listOfDocumentsToClaimantServedByAttachmentOld`,
                        file?.checkListDocID
                      );
                    } else {
                      form.append(
                        `List Of Documents To Claimant Served By Attachment`,
                        file?.file
                      );
                    }
                  }
                );
              }

              if (values.respondentsPreTrailApplicationAttachments) {
                values.respondentsPreTrailApplicationAttachments.forEach(
                  (file, index) => {
                    if (file?.caseID) {
                      form.append(
                        `respondentsPreTrailApplicationAttachmentsOld`,
                        file?.checkListDocID
                      );
                    } else {
                      form.append(
                        `Respondent Pre-Trail Application Attachments`,
                        file?.file
                      );
                    }
                  }
                );
              }

              if (editIndex == null) {
                dispatch(preTrailCaseDetailsCreateAction(form))
                  .unwrap()
                  .then((result) => {
                    setOpenModel(false);

                    setPreTrialApplications(true);

                    dispatch(singleCaseDetailsAction(params));
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                form.append("editIndex", editIndex);
                dispatch(preTrailCaseDetailsUpdateAction(form))
                  .unwrap()
                  .then((result) => {
                    setOpenModel(false);
                    setPreTrialApplications(true);
                    if (result) {
                      dispatch(singleCaseDetailsAction(params));
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }
              dispatch(
                allAgreementDocumentListAction({
                  value: "Arbitration Agreement",
                  caseID: id,
                })
              );

              actions.resetForm();
              setOpenModel(false);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Pre-Trail Application Filed On
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="preTrailApplicationFiledOn"
                  selected={values?.preTrailApplicationFiledOn}
                  onChange={(date) =>
                    setFieldValue("preTrailApplicationFiledOn", date)
                  }
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.preTrailApplicationFiledOn &&
                  errors.preTrailApplicationFiledOn && (
                    <Typography color="error">
                      {errors.preTrailApplicationFiledOn}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="preTrailApplicationsFiledBy">
                  Pre-Trail Applications Filed by
                </InputLabel>
                <Select
                  labelId="preTrailApplicationsFiledBy"
                  id="preTrailApplicationsFiledBy"
                  name="preTrailApplicationsFiledBy"
                  value={values?.preTrailApplicationsFiledBy}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) =>
                        item?.categoryName === "Pre-Trail Applications Filed by"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.preTrailApplicationsFiledBy &&
                  errors.preTrailApplicationsFiledBy && (
                    <Typography color="error">
                      {errors.preTrailApplicationsFiledBy}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="preTrialDocumentsNoticesServedToRespondent">
                  Pre-Trial Documents/Notices Served to Respondent
                </InputLabel>
                <Select
                  labelId="preTrialDocumentsNoticesServedToRespondent"
                  id="preTrialDocumentsNoticesServedToRespondent"
                  name="preTrialDocumentsNoticesServedToRespondent"
                  value={values?.preTrialDocumentsNoticesServedToRespondent}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {touched.preTrialDocumentsNoticesServedToRespondent &&
                  errors.preTrialDocumentsNoticesServedToRespondent && (
                    <Typography color="error">
                      {errors.preTrialDocumentsNoticesServedToRespondent}
                    </Typography>
                  )}
              </Box>

              {(values?.preTrialDocumentsNoticesServedToRespondent == true ||
                values?.preTrialDocumentsNoticesServedToRespondent ==
                  "true") && (
                <>
                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      List of Documents Served to Respondent
                    </Typography>
                    <OutlinedInput
                      id="listOfDocumentsServedToRespondent"
                      name="listOfDocumentsServedToRespondent"
                      value={values?.listOfDocumentsServedToRespondent}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.listOfDocumentsServedToRespondent &&
                      errors.listOfDocumentsServedToRespondent && (
                        <Typography color="error">
                          {errors.listOfDocumentsServedToRespondent}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      List of Documents to Respondent Served On
                    </Typography>
                    <DatePicker
                      id="listOfDocumentsToRespondentServedOn"
                      selected={values?.listOfDocumentsToRespondentServedOn}
                      onChange={(date) => {
                        setFieldValue(
                          "listOfDocumentsToRespondentServedOn",
                          date ? date.toISOString() : null
                        );
                      }}
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                    />
                    {touched.listOfDocumentsToRespondentServedOn &&
                      errors.listOfDocumentsToRespondentServedOn && (
                        <Typography color="error">
                          {errors.listOfDocumentsToRespondentServedOn}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="listOfDocumentsToRespondentServedBy">
                      List of Documents to Respondent Served by
                    </InputLabel>
                    <Select
                      labelId="listOfDocumentsToRespondentServedBy"
                      id="listOfDocumentsToRespondentServedBy"
                      name="listOfDocumentsToRespondentServedBy"
                      value={values?.listOfDocumentsToRespondentServedBy}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    >
                      {settings
                        ?.find(
                          (item) =>
                            item?.categoryName ===
                            "List of Documents to Respondent Served by"
                        )
                        ?.Children?.map((data, index) => (
                          <MenuItem key={index} value={data?.generalSettingsID}>
                            {data?.categoryName}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.listOfDocumentsToRespondentServedBy &&
                      errors.listOfDocumentsToRespondentServedBy && (
                        <Typography color="error">
                          {errors.listOfDocumentsToRespondentServedBy}
                        </Typography>
                      )}
                  </Box>

                  {values?.listOfDocumentsToRespondentServedBy?.length > 0 && (
                    <Box sx={{ display: "grid", mb: 2 }}>
                      <Button
                        className="col-3"
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload files
                        <VisuallyHiddenInput
                          type="file"
                          multiple
                          accept="application/pdf"
                          onChange={(event) => {
                            const existingFiles =
                              values?.listOfDocumentsToRespondentServedByAttachment ||
                              [];
                            const newFiles = Array.from(event.target.files);

                            const updatedFiles = [
                              ...new Map(
                                [...existingFiles, ...newFiles].map((file) => [
                                  file.name || file?.actualDocName,
                                  {
                                    file: file?.file ?? file,
                                    checkListDocID:
                                      file?.checkListDocID || null,
                                    caseRowID: file?.caseRowID || null,
                                    caseID: file?.caseID || null,
                                    checklistID: file?.checklistID || null,
                                    genSetID: file?.genSetID || null,
                                    checklistDate: null,
                                    actualDocName:
                                      file.name || file?.actualDocName,
                                    uploadDocName:
                                      file.name || file?.uploadDocName,
                                    documentType:
                                      file.type || file.documentType,
                                    documentSize:
                                      file.documentSize ||
                                      file.size?.toString(),
                                    documentClassification: null,
                                    documentService: null,
                                    serviceCount: null,
                                    comments: null,
                                    createdBy: file?.createdBy || 1,
                                    updatedBy: file?.updatedBy || 1,
                                    isActive: 1,
                                    createdAt:
                                      file?.createdAt ||
                                      new Date()?.toISOString(),
                                    updatedAt:
                                      file?.updatedAt ||
                                      new Date()?.toISOString(),
                                  },
                                ])
                              ).values(),
                            ];

                            if (updatedFiles.length <= 3) {
                              setFieldValue(
                                "listOfDocumentsToRespondentServedByAttachment",
                                updatedFiles
                              );
                            } else {
                              animateCss(
                                "warn",
                                "You can upload a maximum of 3 files.",
                                2000
                              );
                            }
                          }}
                        />
                      </Button>
                      {touched.listOfDocumentsToRespondentServedByAttachment &&
                        errors.listOfDocumentsToRespondentServedByAttachment && (
                          <Typography color="error">
                            {
                              errors.listOfDocumentsToRespondentServedByAttachment
                            }
                          </Typography>
                        )}
                      {values.listOfDocumentsToRespondentServedByAttachment
                        .length > 0 && (
                        <Box sx={{ mt: 2 }}>
                          {values.listOfDocumentsToRespondentServedByAttachment.map(
                            (file, index) => {
                              return (
                                <Box
                                  key={index}
                                  display="flex"
                                  alignItems="center"
                                  sx={{ mb: 1 }}
                                >
                                  <Stack direction="row" spacing={1}>
                                    <Chip
                                      label={file?.actualDocName || file?.name}
                                      onDelete={() => {
                                        const updatedFiles =
                                          values.listOfDocumentsToRespondentServedByAttachment.filter(
                                            (_, fileIndex) =>
                                              fileIndex !== index
                                          );
                                        setFieldValue(
                                          "listOfDocumentsToRespondentServedByAttachment",
                                          updatedFiles
                                        );
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              );
                            }
                          )}
                        </Box>
                      )}
                    </Box>
                  )}

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="claimantsPreTrailApplicationStatus">
                      Claimant’s Pre-Trail Application Status
                    </InputLabel>
                    <Select
                      labelId="claimantsPreTrailApplicationStatus"
                      id="claimantsPreTrailApplicationStatus"
                      name="claimantsPreTrailApplicationStatus"
                      value={values?.claimantsPreTrailApplicationStatus}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    >
                      {settings
                        ?.find(
                          (item) =>
                            item?.categoryName ===
                            "Claimant’s Pre-Trail Application Status"
                        )
                        ?.Children?.map((data, index) => (
                          <MenuItem key={index} value={data?.generalSettingsID}>
                            {data?.categoryName}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.claimantsPreTrailApplicationStatus &&
                      errors.claimantsPreTrailApplicationStatus && (
                        <Typography color="error">
                          {errors.claimantsPreTrailApplicationStatus}
                        </Typography>
                      )}
                  </Box>
                </>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Outcome of the Claimant’s Pre-Trail Application
                </Typography>
                <OutlinedInput
                  id="outcomeOfTheClaimantsPreTrailApplication"
                  name="outcomeOfTheClaimantsPreTrailApplication"
                  value={values?.outcomeOfTheClaimantsPreTrailApplication}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.outcomeOfTheClaimantsPreTrailApplication &&
                  errors.outcomeOfTheClaimantsPreTrailApplication && (
                    <Typography color="error">
                      {errors.outcomeOfTheClaimantsPreTrailApplication}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="preTrialDocumentsNoticesServedToClaimant">
                  Pre-Trial Documents/Notices Served to Claimant
                </InputLabel>
                <Select
                  labelId="preTrialDocumentsNoticesServedToClaimant"
                  id="preTrialDocumentsNoticesServedToClaimant"
                  name="preTrialDocumentsNoticesServedToClaimant"
                  value={values?.preTrialDocumentsNoticesServedToClaimant}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {touched.preTrialDocumentsNoticesServedToClaimant &&
                  errors.preTrialDocumentsNoticesServedToClaimant && (
                    <Typography color="error">
                      {errors.preTrialDocumentsNoticesServedToClaimant}
                    </Typography>
                  )}
              </Box>
              {values?.preTrialDocumentsNoticesServedToClaimant == true && (
                <>
                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      Claimant’s Pre-Trail Application Attachments
                    </Typography>
                    <Button
                      className="col-3"
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload files
                      <VisuallyHiddenInput
                        type="file"
                        multiple
                        accept="application/pdf"
                        onChange={(event) => {
                          const existingFiles =
                            values?.claimantsPreTrailApplicationAttachments ||
                            [];
                          const newFiles = Array.from(event.target.files);

                          const updatedFiles = [
                            ...new Map(
                              [...existingFiles, ...newFiles].map((file) => [
                                file.name || file?.actualDocName,
                                {
                                  file: file?.file ?? file,
                                  checkListDocID: file?.checkListDocID || null,
                                  caseRowID: file?.caseRowID || null,
                                  caseID: file?.caseID || null,
                                  checklistID: file?.checklistID || null,
                                  genSetID: file?.genSetID || null,
                                  checklistDate: null,
                                  actualDocName:
                                    file.name || file?.actualDocName,
                                  uploadDocName:
                                    file.name || file?.uploadDocName,
                                  documentType: file.type || file.documentType,
                                  documentSize:
                                    file.documentSize || file.size?.toString(),
                                  documentClassification: null,
                                  documentService: null,
                                  serviceCount: null,
                                  comments: null,
                                  createdBy: file?.createdBy || 1,
                                  updatedBy: file?.updatedBy || 1,
                                  isActive: 1,
                                  createdAt:
                                    file?.createdAt ||
                                    new Date()?.toISOString(),
                                  updatedAt:
                                    file?.updatedAt ||
                                    new Date()?.toISOString(),
                                },
                              ])
                            ).values(),
                          ];

                          if (updatedFiles.length <= 3) {
                            setFieldValue(
                              "claimantsPreTrailApplicationAttachments",
                              updatedFiles
                            );
                          } else {
                            animateCss(
                              "warn",
                              "You can upload a maximum of 3 files.",
                              2000
                            );
                          }
                        }}
                      />
                    </Button>
                    {touched.claimantsPreTrailApplicationAttachments &&
                      errors.claimantsPreTrailApplicationAttachments && (
                        <Typography color="error">
                          {errors.claimantsPreTrailApplicationAttachments}
                        </Typography>
                      )}
                  </Box>

                  {values.claimantsPreTrailApplicationAttachments.length >
                    0 && (
                    <Box sx={{ mt: 2 }}>
                      {values.claimantsPreTrailApplicationAttachments.map(
                        (file, index) => (
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            sx={{ mb: 1 }}
                          >
                            <Stack direction="row" spacing={1}>
                              <Chip
                                label={file?.actualDocName || file.name}
                                onDelete={() => {
                                  if (window.delete) {
                                    const updatedFiles =
                                      values.claimantsPreTrailApplicationAttachments.filter(
                                        (_, fileIndex) => fileIndex !== index
                                      );
                                    setFieldValue(
                                      "claimantsPreTrailApplicationAttachments",
                                      updatedFiles
                                    );
                                  } else {
                                    animateCss(
                                      "info",
                                      "You are not authorized to delete"
                                    );
                                  }
                                }}
                              />
                            </Stack>
                          </Box>
                        )
                      )}
                    </Box>
                  )}

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      List of Documents Served to Claimant
                    </Typography>
                    <OutlinedInput
                      id="listOfDocumentsServedToClaimant"
                      name="listOfDocumentsServedToClaimant"
                      value={values?.listOfDocumentsServedToClaimant}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.listOfDocumentsServedToClaimant &&
                      errors.listOfDocumentsServedToClaimant && (
                        <Typography color="error">
                          {errors.listOfDocumentsServedToClaimant}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <Typography variant="body1" component="label">
                      List of Documents to Claimant Served On
                    </Typography>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="listOfDocumentsToClaimantServedOn"
                      selected={values?.listOfDocumentsToClaimantServedOn}
                      onChange={(date) =>
                        setFieldValue("listOfDocumentsToClaimantServedOn", date)
                      }
                      // minDate={minDate}
                      onBlur={handleBlur}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      className="form-control w-100"
                    />
                    {touched.listOfDocumentsToClaimantServedOn &&
                      errors.listOfDocumentsToClaimantServedOn && (
                        <Typography color="error">
                          {errors.listOfDocumentsToClaimantServedOn}
                        </Typography>
                      )}
                  </Box>

                  <Box sx={{ display: "grid", mb: 2 }}>
                    <InputLabel htmlFor="listOfDocumentsToClaimantServedBy">
                      List of Documents to Claimant Served By
                    </InputLabel>
                    <Select
                      labelId="listOfDocumentsToClaimantServedBy"
                      id="listOfDocumentsToClaimantServedBy"
                      name="listOfDocumentsToClaimantServedBy"
                      value={values?.listOfDocumentsToClaimantServedBy}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    >
                      {settings
                        ?.find(
                          (item) =>
                            item?.categoryName ===
                            "List of Documents to Claimant Served by"
                        )
                        ?.Children?.map((data, index) => (
                          <MenuItem key={index} value={data?.generalSettingsID}>
                            {data?.categoryName}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.listOfDocumentsToClaimantServedBy &&
                      errors.listOfDocumentsToClaimantServedBy && (
                        <Typography color="error">
                          {errors.listOfDocumentsToClaimantServedBy}
                        </Typography>
                      )}
                  </Box>
                </>
              )}

              {values?.listOfDocumentsToClaimantServedBy && (
                <Box sx={{ display: "grid", mb: 2 }}>
                  <Button
                    className="col-3"
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload files
                    <VisuallyHiddenInput
                      type="file"
                      multiple
                      accept="application/pdf"
                      onChange={(event) => {
                        const existingFiles =
                          values?.listOfDocumentsToClaimantServedByAttachment ||
                          [];
                        const newFiles = Array.from(event.target.files);

                        const updatedFiles = [
                          ...new Map(
                            [...existingFiles, ...newFiles].map((file) => [
                              file.name || file?.actualDocName,
                              {
                                file: file?.file ?? file,
                                checkListDocID: file?.checkListDocID || null,
                                caseRowID: file?.caseRowID || null,
                                caseID: file?.caseID || null,
                                checklistID: file?.checklistID || null,
                                genSetID: file?.genSetID || null,
                                checklistDate: null,
                                actualDocName: file.name || file?.actualDocName,
                                uploadDocName: file.name || file?.uploadDocName,
                                documentType: file.type || file.documentType,
                                documentSize:
                                  file.documentSize || file.size?.toString(),
                                documentClassification: null,
                                documentService: null,
                                serviceCount: null,
                                comments: null,
                                createdBy: file?.createdBy || 1,
                                updatedBy: file?.updatedBy || 1,
                                isActive: 1,
                                createdAt:
                                  file?.createdAt || new Date()?.toISOString(),
                                updatedAt:
                                  file?.updatedAt || new Date()?.toISOString(),
                              },
                            ])
                          ).values(),
                        ];
                        setFieldValue(
                          "listOfDocumentsToClaimantServedByAttachment",
                          updatedFiles
                        );
                      }}
                    />
                  </Button>
                  {touched.listOfDocumentsToClaimantServedByAttachment &&
                    errors.listOfDocumentsToClaimantServedByAttachment && (
                      <Typography color="error">
                        {errors.listOfDocumentsToClaimantServedByAttachment}
                      </Typography>
                    )}
                  {values.listOfDocumentsToClaimantServedByAttachment.length >
                    0 && (
                    <Box sx={{ mt: 2 }}>
                      {values.listOfDocumentsToClaimantServedByAttachment.map(
                        (file, index) => {
                          return (
                            <Box
                              key={index}
                              display="flex"
                              alignItems="center"
                              sx={{ mb: 1 }}
                            >
                              <Stack direction="row" spacing={1}>
                                <Chip
                                  label={file?.actualDocName || file?.name}
                                  onDelete={() => {
                                    const updatedFiles =
                                      values.listOfDocumentsToClaimantServedByAttachment.filter(
                                        (_, fileIndex) => fileIndex !== index
                                      );
                                    setFieldValue(
                                      "listOfDocumentsToClaimantServedByAttachment",
                                      updatedFiles
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  )}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentsPreTrailApplicationStatus">
                  Respondent’s Pre-Trail Application Status
                </InputLabel>
                <Select
                  labelId="respondentsPreTrailApplicationStatus"
                  id="respondentsPreTrailApplicationStatus"
                  name="respondentsPreTrailApplicationStatus"
                  value={values?.respondentsPreTrailApplicationStatus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) =>
                        item?.categoryName ===
                        "Respondent’s Pre-Trail Application Status"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.respondentsPreTrailApplicationStatus &&
                  errors.respondentsPreTrailApplicationStatus && (
                    <Typography color="error">
                      {errors.respondentsPreTrailApplicationStatus}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Outcome of the Respondent’s Pre-Trail Application
                </Typography>
                <OutlinedInput
                  id="outcomeOfTheRespondentsPreTrailApplication"
                  name="outcomeOfTheRespondentsPreTrailApplication"
                  value={values?.outcomeOfTheRespondentsPreTrailApplication}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.outcomeOfTheRespondentsPreTrailApplication &&
                  errors.outcomeOfTheRespondentsPreTrailApplication && (
                    <Typography color="error">
                      {errors.outcomeOfTheRespondentsPreTrailApplication}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Respondent’s Pre-Trail Application Attachments
                </Typography>
                <Button
                  className="col-3"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    accept="application/pdf"
                    onChange={(event) => {
                      const existingFiles =
                        values?.respondentsPreTrailApplicationAttachments || [];
                      const newFiles = Array.from(event.target.files);

                      const updatedFiles = [
                        ...new Map(
                          [...existingFiles, ...newFiles].map((file) => [
                            file.name || file?.actualDocName,
                            {
                              file: file?.file ?? file,
                              checkListDocID: file?.checkListDocID || null,
                              caseRowID: file?.caseRowID || null,
                              caseID: file?.caseID || null,
                              checklistID: file?.checklistID || null,
                              genSetID: file?.genSetID || null,
                              checklistDate: null,
                              actualDocName: file.name || file?.actualDocName,
                              uploadDocName: file.name || file?.uploadDocName,
                              documentType: file.type || file.documentType,
                              documentSize:
                                file.documentSize || file.size?.toString(),
                              documentClassification: null,
                              documentService: null,
                              serviceCount: null,
                              comments: null,
                              createdBy: file?.createdBy || 1,
                              updatedBy: file?.updatedBy || 1,
                              isActive: 1,
                              createdAt:
                                file?.createdAt || new Date()?.toISOString(),
                              updatedAt:
                                file?.updatedAt || new Date()?.toISOString(),
                            },
                          ])
                        ).values(),
                      ];

                      // Validate the number of files
                      if (updatedFiles.length <= 3) {
                        setFieldValue(
                          "respondentsPreTrailApplicationAttachments",
                          updatedFiles
                        );
                      } else {
                        // Show warning if more than 3 files
                        animateCss(
                          "warn",
                          "You can upload a maximum of 3 files.",
                          2000
                        );
                      }
                    }}
                  />
                </Button>
                {touched.respondentsPreTrailApplicationAttachments &&
                  errors.respondentsPreTrailApplicationAttachments && (
                    <Typography color="error">
                      {errors.respondentsPreTrailApplicationAttachments}
                    </Typography>
                  )}
              </Box>

              {values.respondentsPreTrailApplicationAttachments.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  {values.respondentsPreTrailApplicationAttachments.map(
                    (file, index) => {
                      return (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          sx={{ mb: 1 }}
                        >
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label={file?.actualDocName || file?.name}
                              onDelete={() => {
                                const updatedFiles =
                                  values.respondentsPreTrailApplicationAttachments.filter(
                                    (_, fileIndex) => fileIndex !== index
                                  );
                                setFieldValue(
                                  "respondentsPreTrailApplicationAttachments",
                                  updatedFiles
                                );
                              }}
                            />
                          </Stack>
                        </Box>
                      );
                    }
                  )}
                </Box>
              )}

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                  variant="outlined"
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

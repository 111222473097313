import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allAgreementDocumentDelete,
  allAgreementDocumentList,
  allCaseDetails,
  allCaseType,
  caseBulkFilter,
  caseDetailHistory,
  caseTrackingUpdate,
  dashboardCaseDetailsCounts,
  filterColumn,
  organizationCaseDetails,
  pdfIDMatching,
  preTrailCaseDetailsDocuments,
  preTrailCaseDetailsCreate,
  singleCaseDetails,
  updateAppointmentOfArbitratorSec11and12,
  updateArbitrationClausAndAgreement,
  updateArbitrationDurationAndTimelineManagement,
  updateArbitrationEnforcementAndAppeals,
  updateArbitrationFeePaymentStatus,
  updateArbitrationHoldWithdrawalAndSettlement,
  updateArbitrationInterimOrdersReliefsAwards,
  updateArbitrationNoticesKeyDates,
  updateArbitrationWitnessEvidenceAndExpertReport,
  updateCaseDetails,
  updateCasePrimaryInformation,
  updateFinalAward,
  updateInitiationOfArbitration,
  updateInterimOrders,
  updateSec17InterimOrders,
  updateSec9InterimOrders,
  preTrailCaseDetailsUpdate,
  preTrailCaseDetailsDelete,
  claimantsListOfEvidenceCreate,
  claimantsListOfEvidenceDelete,
  claimantsListOfEvidenceUpdate,
  respondentsListOfEvidenceUpdate,
  respondentsListOfEvidenceDelete,
  respondentsListOfEvidenceCreate,
  claimantSideWitnessCreate,
  claimantSideWitnessUpdate,
  claimantSideWitnessDelete,
  respondentWitnessCreate,
  respondentWitnessUpdate,
  respondentWitnessDelete,
  claimantSideArgumentsCreate,
  claimantSideArgumentsUpdate,
  claimantSideArgumentsDelete,
  respondentSideArgumentsCreate,
  respondentSideArgumentsUpdate,
  respondentSideArgumentsDelete,
  dispositionsCreate,
  dispositionsUpdate,
  dispositionsDelete,
  enforcementsCreate,
  enforcementsUpdate,
  enforcementsDelete,
  appealCreate,
  appealUpdate,
  appealDelete,
  arbitrationStatusTrackingCreate,
  arbitrationStatusTrackingUpdate,
  arbitrationStatusTrackingDelete,
  arbitrationDurationAndTimelineMonitoring,
  partiesAndContactsClaimantDetailsCreate,
  partiesAndContactsClaimantDetailsUpdate,
  partiesAndContactsClaimantDetailsDelete,
  partiesAndContactsRespondentDetailsCreate,
  partiesAndContactsRespondentDetailsUpdate,
  partiesAndContactsRespondentDetailsDelete,
  partiesAndContactsArbitratorDetailsCreate,
  partiesAndContactsArbitratorDetailsUpdate,
  partiesAndContactsArbitratorDetailsDelete,
  caseDetailsBulkDelete,
  caseDetailsBulkUpdate,
} from "../../../api/case_api";
import { json } from "react-router-dom";

const filter = {};

export const allCaseTypeAction = createAsyncThunk(
  "case/allCaseTypeAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allCaseType();
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const caseDetailsBulkUpdateAction = createAsyncThunk(
  "case/caseDetailsBulkUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseDetailsBulkUpdate(values);
      const { data, status, statusText } = result;

      return { data, status, statusText };
      // return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const singleCaseDetailsAction = createAsyncThunk(
  "case/singleCaseTypeAction",
  async (values, { rejectWithValue }) => {
    try {
      const result = await singleCaseDetails(values);

      const data = result[0];

      return data ?? [];
      // return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
export const caseDetailsBulkDeleteAction = createAsyncThunk(
  "case/caseDetailsBulkDeleteAction",
  async (values, { rejectWithValue }) => {
    try {
      const result = await caseDetailsBulkDelete(values);

      const serializedHeaders = {
        contentLength: result.headers["content-length"],
        contentType: result.headers["content-type"],
      };

      return {
        data: result.data,
        // headers: serializedHeaders,
        // status: result.status,
      };
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allCaseDetailsAction = createAsyncThunk(
  "case/allCaseDetails",
  async (values, { rejectWithValue }) => {
    try {
      const result = await allCaseDetails(values);

      const serializedHeaders = {
        contentType: result.headers["content-type"],
      };

      let trueCount = 0;

      let parsedFilter;
      try {
        parsedFilter = JSON.parse(result?.data?.filter) ?? filter;
      } catch (error) {
        console.error("Failed to parse filter:", error);
      }

      const filterColumn =
        parsedFilter && typeof parsedFilter === "object"
          ? Object.fromEntries(
              Object.entries(parsedFilter).map(([key, value]) => {
                if (value === true && trueCount < 20) {
                  trueCount++;
                  return [key, true];
                }
                return [key, false];
              })
            )
          : {};
      // parsedFilter ?? {};

      return {
        data: result?.data?.result,
        filter: filterColumn,
        pageCount: result?.data.totalRecords,
        headers: serializedHeaders,
      };
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

// export const OrganizationCaseDetailsAction = createAsyncThunk(
//   "case/OrganizationCaseDetailsAction",
//   async (values, { rejectWithValue, getState }) => {
//     try {
//       const result = await organizationCaseDetails(values);

//       const serializedHeaders = {
//         contentType: result.headers["content-type"],
//       };

//       const newData = result?.data?.data?.map((item) => {
//         try {
//           let allCaseDetails = null;
//           if (item && item?.["Alter Case Details"]) {
//             try {
//               const parsedDetails = JSON.parse(item?.["Alter Case Details"]);

//               // allCaseDetails = Object.entries(parsedDetails).reduce(
//               //   (acc, [key, value]) => {
//               //     acc[value.alterHeader] = value.value;
//               //     return acc;
//               //   },
//               //   {}
//               // );

//               allCaseDetails = Object.entries(parsedDetails).reduce(
//                 (acc, [key, value]) => {
//                   let formattedValue = value.value;
//                   // const referenceDate = new Date("1970-01-01");

//                   // Check if value.value is a valid date
//                   // if (
//                   //   value.value instanceof Date &&
//                   //   !isNaN(value.value.getTime()) &&
//                   //   value.value > referenceDate
//                   // ) {
//                   //   const day = String(value.value.getDate()).padStart(2, "0");
//                   //   const month = String(value.value.getMonth() + 1).padStart(
//                   //     2,
//                   //     "0"
//                   //   ); // Months are zero-indexed
//                   //   const year = value.value.getFullYear();
//                   //   formattedValue = `${day}-${month}-${year}`;
//                   // }

//                   acc[value.alterHeader] = formattedValue;
//                   return acc;
//                 },
//                 {}
//               );

//               // return allCaseDetails;
//             } catch (error) {
//               console.error("Failed to parse alterCaseDetails:", error);
//             }
//           }

//           return {
//             ...item,
//             ...allCaseDetails,
//           };
//         } catch (err) {}
//       });

//       // const transformedName = newData?.reduce((acc, item) => {

//       //   return {
//       //     ...acc, // Accumulate existing properties
//       //     ...item, // Spread the current item properties
//       //   };
//       // }, {});

//       return {
//         // data: allData,
//         data: newData,
//         filter: result?.data?.filter,
//         pageCount: result?.data.totalRecords,
//         headers: serializedHeaders,
//       };
//     } catch (error) {
//       return rejectWithValue(error.message || "something went wrong");
//     }
//   }
// );

export const updateCaseDetailsAction = createAsyncThunk(
  "case/updateCaseDetailsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateCaseDetails(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const caseDetailsHistoryAction = createAsyncThunk(
  "case/caseDetailsHistoryAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseDetailHistory(values);

      const { data, headers } = result;

      const serializedHeaders = {
        contentType: headers["content-type"],
      };

      return { data, headers: serializedHeaders };
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const caseTrackingUpdateAction = createAsyncThunk(
  "case/caseTrackingUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseTrackingUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateCasePrimaryInformationAction = createAsyncThunk(
  "case/updateCasePrimaryInformationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateCasePrimaryInformation(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateInitiationOfArbitrationAction = createAsyncThunk(
  "case/updateInitiationOfArbitrationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateInitiationOfArbitration(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateAppointmentOfArbitratorSec11and12Action = createAsyncThunk(
  "case/updateAppointmentOfArbitratorSec11and12Action",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateAppointmentOfArbitratorSec11and12(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationInterimOrdersReliefsAwardsAction =
  createAsyncThunk(
    "case/updateArbitrationInterimOrdersReliefsAwardsAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationInterimOrdersReliefsAwards(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationWitnessEvidenceAndExpertReportAction =
  createAsyncThunk(
    "case/updateArbitrationWitnessEvidenceAndExpertReportAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationWitnessEvidenceAndExpertReport(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationHoldWithdrawalAndSettlementAction =
  createAsyncThunk(
    "case/updateArbitrationHoldWithdrawalAndSettlementAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationHoldWithdrawalAndSettlement(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationEnforcementAndAppealsAction = createAsyncThunk(
  "case/updateArbitrationEnforcementAndAppealsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationEnforcementAndAppeals(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationFeePaymentStatusAction = createAsyncThunk(
  "case/updateArbitrationFeePaymentStatusAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationFeePaymentStatus(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationNoticesKeyDatesAction = createAsyncThunk(
  "case/updateArbitrationNoticesKeyDatesAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationNoticesKeyDates(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationDurationAndTimelineManagementAction =
  createAsyncThunk(
    "case/updateArbitrationDurationAndTimelineManagementAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationDurationAndTimelineManagement(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationClausAndAgreementAction = createAsyncThunk(
  "case/updateArbitrationClausAndAgreementAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationClausAndAgreement(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateSec17InterimOrdersAction = createAsyncThunk(
  "case/updateSec17InterimOrdersAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateSec17InterimOrders(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
export const updateSec9InterimOrdersAction = createAsyncThunk(
  "case/updateSec9InterimOrdersAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateSec9InterimOrders(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateInterimOrdersAction = createAsyncThunk(
  "case/updateInterimOrdersAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateInterimOrders(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateFinalAwardAction = createAsyncThunk(
  "case/updateFinalAwardAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateFinalAward(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allAgreementDocumentListAction = createAsyncThunk(
  "case/allAgreementDocumentList",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allAgreementDocumentList(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allAgreementDocumentDeleteAction = createAsyncThunk(
  "case/allAgreementDocumentDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allAgreementDocumentDelete(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

//Column Arrange

export const filterColumnAction = createAsyncThunk(
  "case/filterColumnAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await filterColumn(values);

      return JSON.parse(result.data.data);
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const userFilterColumnAction = createAsyncThunk(
  "case/userFilterColumnAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      return JSON.parse(values);
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

// Case Bulk Filter

export const caseBulkFilterAction = createAsyncThunk(
  "case/caseBulkFilterAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseBulkFilter(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const pdfIDMatchingAction = createAsyncThunk(
  "case/pdfIDMatchingAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await pdfIDMatching(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const dashboardCaseDetailsCountsAction = createAsyncThunk(
  "case/dashboardCaseDetailsCountsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await dashboardCaseDetailsCounts(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const preTrailCaseDetailsCreateAction = createAsyncThunk(
  "case/preTrailCaseDetailsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await preTrailCaseDetailsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const preTrailCaseDetailsUpdateAction = createAsyncThunk(
  "case/preTrailCaseDetailsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await preTrailCaseDetailsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const preTrailCaseDetailsDocumentsAction = createAsyncThunk(
  "case/preTrailCaseDetailsDocumentsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await preTrailCaseDetailsDocuments(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const preTrailCaseDetailsDeleteAction = createAsyncThunk(
  "case/preTrailCaseDetailsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await preTrailCaseDetailsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantsListOfEvidenceCreateAction = createAsyncThunk(
  "case/claimantsListOfEvidenceCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantsListOfEvidenceCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantsListOfEvidenceDeleteAction = createAsyncThunk(
  "case/claimantsListOfEvidenceDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantsListOfEvidenceDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantsListOfEvidenceUpdateAction = createAsyncThunk(
  "case/claimantsListOfEvidenceUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantsListOfEvidenceUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentsListOfEvidenceCreateAction = createAsyncThunk(
  "case/respondentsListOfEvidenceCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentsListOfEvidenceCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentsListOfEvidenceDeleteAction = createAsyncThunk(
  "case/respondentsListOfEvidenceDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentsListOfEvidenceDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentsListOfEvidenceUpdateAction = createAsyncThunk(
  "case/respondentsListOfEvidenceUpdate",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentsListOfEvidenceUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideWitnessCreateAction = createAsyncThunk(
  "case/claimantSideWitnessCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideWitnessCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideWitnessUpdateAction = createAsyncThunk(
  "case/claimantSideWitnessUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideWitnessUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideWitnessDeleteAction = createAsyncThunk(
  "case/claimantSideWitnessDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideWitnessDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentWitnessCreateAction = createAsyncThunk(
  "case/respondentWitnessCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentWitnessCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentWitnessUpdateAction = createAsyncThunk(
  "case/respondentWitnessUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentWitnessUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentWitnessDeleteAction = createAsyncThunk(
  "case/respondentWitnessDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentWitnessDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideArgumentsCreateAction = createAsyncThunk(
  "case/claimantSideArgumentsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideArgumentsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideArgumentsUpdateAction = createAsyncThunk(
  "case/claimantSideArgumentsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideArgumentsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideArgumentsDeleteAction = createAsyncThunk(
  "case/claimantSideArgumentsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideArgumentsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentSideArgumentsCreateAction = createAsyncThunk(
  "case/respondentSideArgumentsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentSideArgumentsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentSideArgumentsUpdateAction = createAsyncThunk(
  "case/respondentSideArgumentsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentSideArgumentsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentSideArgumentsDeleteAction = createAsyncThunk(
  "case/respondentSideArgumentsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentSideArgumentsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const dispositionsCreateAction = createAsyncThunk(
  "case/dispositionsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await dispositionsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const dispositionsUpdateAction = createAsyncThunk(
  "case/dispositionsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await dispositionsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const dispositionsDeleteAction = createAsyncThunk(
  "case/dispositionsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await dispositionsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const enforcementsCreateAction = createAsyncThunk(
  "case/enforcementsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await enforcementsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const enforcementsUpdateAction = createAsyncThunk(
  "case/enforcementsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await enforcementsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const enforcementsDeleteAction = createAsyncThunk(
  "case/enforcementsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await enforcementsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const appealCreateAction = createAsyncThunk(
  "case/appealCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await appealCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const appealUpdateAction = createAsyncThunk(
  "case/appealUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await appealUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const appealDeleteAction = createAsyncThunk(
  "case/appealDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await appealDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const arbitrationStatusTrackingCreateAction = createAsyncThunk(
  "case/arbitrationStatusTrackingCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await arbitrationStatusTrackingCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const arbitrationStatusTrackingUpdateAction = createAsyncThunk(
  "case/arbitrationStatusTrackingUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await arbitrationStatusTrackingUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const arbitrationStatusTrackingDeleteAction = createAsyncThunk(
  "case/arbitrationStatusTrackingDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await arbitrationStatusTrackingDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const arbitrationDurationAndTimelineMonitoringAction = createAsyncThunk(
  "case/arbitrationDurationAndTimelineMonitoringAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await arbitrationDurationAndTimelineMonitoring(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const partiesAndContactsClaimantDetailsCreateAction = createAsyncThunk(
  "case/partiesAndContactsClaimantDetailsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await partiesAndContactsClaimantDetailsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const partiesAndContactsClaimantDetailsUpdateAction = createAsyncThunk(
  "case/partiesAndContactsClaimantDetailsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await partiesAndContactsClaimantDetailsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const partiesAndContactsClaimantDetailsDeleteAction = createAsyncThunk(
  "case/partiesAndContactsClaimantDetailsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await partiesAndContactsClaimantDetailsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const partiesAndContactsRespondentDetailsCreateAction = createAsyncThunk(
  "case/partiesAndContactsRespondentDetailsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await partiesAndContactsRespondentDetailsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const partiesAndContactsRespondentDetailsUpdateAction = createAsyncThunk(
  "case/partiesAndContactsRespondentDetailsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await partiesAndContactsRespondentDetailsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const partiesAndContactsRespondentDetailsDeleteAction = createAsyncThunk(
  "case/partiesAndContactsRespondentDetailsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await partiesAndContactsRespondentDetailsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const partiesAndContactsArbitratorDetailsCreateAction = createAsyncThunk(
  "case/partiesAndContactsArbitratorDetailsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await partiesAndContactsArbitratorDetailsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const partiesAndContactsArbitratorDetailsUpdateAction = createAsyncThunk(
  "case/partiesAndContactsArbitratorDetailsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await partiesAndContactsArbitratorDetailsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const partiesAndContactsArbitratorDetailsDeleteAction = createAsyncThunk(
  "case/partiesAndContactsArbitratorDetailsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await partiesAndContactsArbitratorDetailsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

import React, { useEffect, useState } from "react";
import {
  Drawer,
  Grid,
  Stack,
  IconButton,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Tooltip,
  CardHeader,
  Card,
  styled,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { api } from "../api/api_route";
import { Delete } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { animateCss } from "./TosterAlert";
import { Button, Skeleton } from "antd";
import { handleDownload } from "../helpers/helperTool";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { deleteCaseChecklistDocumentAction } from "../redux/actions/checklistDocuments/checklistDocumentsAction";
import { singleCaseDetailsAction } from "../redux/actions/Case/caseAction";
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 320,
    padding: theme.spacing(2),
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  closeButton: {
    marginLeft: "auto",
  },
  formControl: {
    minWidth: "100%",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentDrawer = ({ open, setOpen, checklistDocuments }) => {
  const [documents, setDocuments] = useState(null);

  const documentLoading = useSelector((state) => state?.case?.loading);

  useEffect(() => {
    setDocuments(null);
    const timer = setTimeout(() => {
      setDocuments(checklistDocuments);
    }, 1000);

    return () => clearTimeout(timer);
  }, [open]);

  const classes = useStyles();
  const user = useSelector((state) => state.login.data);

  const dateFormate = (date) => {
    const createdAtDate = new Date(date);
    const formattedDate = createdAtDate.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    return formattedDate;
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const params = { caseID: id };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      classes={{ paper: classes.drawerPaper }}
    >
      <div>
        <div className="MuiStack-root css-jjtu05" sx={{ zIndex: 0 }}>
          {/* Header */}
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h4">Documents</Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          {/* Divider */}
          <hr className="MuiDivider-root MuiDivider-fullWidth css-d7wf4l" />

          {/* Form */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <form>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  {documents
                    ? checklistDocuments?.map((item, i) => {
                        return (
                          <div key={i}>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const downloadUrl = `${api}/caseChecklistDocuments/${item.uploadDocName}`;
                                    const fileName = item.actualDocName;
                                    handleDownload(downloadUrl, fileName);
                                  }}
                                >
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={require("../assets/images/download.webp")}
                                  />
                                </a>
                              </ListItemAvatar>
                              <ListItemText
                                primary={<>{item?.actualDocName} </>}
                                secondary={
                                  <React.Fragment>
                                    <span>
                                      Date: {dateFormate(item?.createdAt)}
                                    </span>
                                    <br />
                                    <Tooltip title="Delete">
                                      <Button
                                        className="text-danger"
                                        onClick={() => {
                                          try {
                                            // if (window.delete) {
                                            enqueueSnackbar(
                                              <>
                                                <div>
                                                  Are you sure you want to
                                                  delete
                                                  <b> {item.actualDocName} </b>
                                                  Document ?
                                                </div>
                                              </>,
                                              {
                                                variant: "warning",
                                                onClose: () => {},
                                                anchorOrigin: {
                                                  vertical: "top",
                                                  horizontal: "right",
                                                },
                                                action: (key) => (
                                                  <>
                                                    <StyledButton
                                                      onClick={async () => {
                                                        const data = {
                                                          checkListDocID:
                                                            item.checkListDocID,
                                                        };
                                                        dispatch(
                                                          deleteCaseChecklistDocumentAction(
                                                            data
                                                          )
                                                        )
                                                          .unwrap()
                                                          .then((result) => {
                                                            if (result) {
                                                              dispatch(
                                                                singleCaseDetailsAction(
                                                                  params
                                                                )
                                                              );
                                                            }
                                                          })
                                                          .catch((error) => {
                                                            console.error(
                                                              "Action rejected with error:",
                                                              error
                                                            );
                                                          });

                                                        closeSnackbar(key);
                                                      }}
                                                      className="bg-danger"
                                                    >
                                                      Confirm
                                                    </StyledButton>
                                                    <StyledButton
                                                      onClick={() => {
                                                        closeSnackbar(key);
                                                      }}
                                                      className="bg-danger"
                                                    >
                                                      Cancel
                                                    </StyledButton>
                                                  </>
                                                ),
                                                autoHideDuration: null,
                                                style: {
                                                  backgroundColor: "#f44336",
                                                  width: "600px",
                                                  color: "#fff",
                                                },
                                              }
                                            );
                                            // } else {
                                            //   animateCss(
                                            //     "info",
                                            //     "You are not authorized to delete"
                                            //   );
                                            // }
                                          } catch (err) {
                                            console.error(
                                              "Error deleting checklist document:",
                                              err
                                            );
                                          }
                                        }}
                                      >
                                        {/* <Delete /> */}
                                        Delete
                                      </Button>
                                    </Tooltip>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </div>
                        );
                      })
                    : checklistDocuments?.map((item, index) => (
                        <Card key={index} style={{ marginBottom: "16px" }}>
                          <CardHeader
                            title={
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{ marginBottom: 6 }}
                              />
                            }
                          />
                        </Card>
                      ))}
                </List>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    </Drawer>
  );
};

export default DocumentDrawer;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  arbitrationDurationAndTimelineMonitoringAction,
  preTrailCaseDetailsDocumentsAction,
  singleCaseDetailsAction,
} from "../../../../redux/actions/Case/caseAction";
import { animateCss } from "../../../TosterAlert";
import dayjs from "dayjs";
import { Skeleton } from "antd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { enqueueSnackbar } from "notistack";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ArbitrationDurationAndTimelineMonitoring = ({
  rowData,
  accordionOpen,
  setAccordionOpen,
  trackerFormatDate,
  id,
  user,
  settings,
  handleAccordion,
  editForm,
  setEditForm,
  setOpenDocument,
}) => {
  const dispatch = useDispatch();

  const params = { caseID: id };

  const loading = useSelector((state) => state?.case.loading);

  const document = useSelector(
    (state) => state?.case?.preTrailCaseDetailsDocuments
  );

  useEffect(
    () => {
      const document = {
        uniqueDocumentId: rowData?.arbitrationTimelineMonitoringAttachment
          .split(",")
          .map(Number),
      };
      dispatch(preTrailCaseDetailsDocumentsAction(document));
    },
    [],
    [editForm]
  );

  return (
    <>
      {editForm == 3 && accordionOpen == 3 ? (
        <Formik
          initialValues={{
            agreedArbitrationDuration:
              rowData?.AgreedArbitrationDuration ?? null,
            startDateOfArbitration: rowData?.StartDateOfArbitration ?? null,
            endDateOfArbitration: rowData?.EndDateOfArbitration ?? null,
            daysRemaining: rowData?.daysRemaining ?? null,
            extensionOfDurationRequested:
              rowData?.extensionOfDurationRequested ?? null,
            extensionOfDurationRequestedOn:
              rowData?.extensionOfDurationRequestedOn ?? null,
            extensionOfDurationRequestedBy:
              rowData?.extensionOfDurationRequestedBy ?? null,
            extensionOfDurationRequestStatus:
              rowData?.extensionOfDurationRequestStatus ?? null,
            exceededAgreedDuration: rowData?.ExceededAgreedDuration ?? null,
            revisedEndDateOfArbitration:
              rowData?.revisedEndDateOfArbitration ?? null,
            supportingDocuments: document ?? null,
            params,
          }}
          onSubmit={async (values, actions) => {
            try {
              const form = new FormData();
              form.append("caseID", id);

              form.append(
                "agreedArbitrationDuration",
                values.agreedArbitrationDuration
              );
              form.append(
                "startDateOfArbitration",
                values.startDateOfArbitration
              );
              form.append("endDateOfArbitration", values.endDateOfArbitration);
              form.append("daysRemaining", values.daysRemaining);
              form.append(
                "extensionOfDurationRequested",
                values.extensionOfDurationRequested
              );
              form.append(
                "extensionOfDurationRequestedOn",
                values.extensionOfDurationRequestedOn
              );
              form.append(
                "extensionOfDurationRequestedBy",
                values.extensionOfDurationRequestedBy
              );
              form.append(
                "extensionOfDurationRequestStatus",
                values.extensionOfDurationRequestStatus
              );
              form.append(
                "exceededAgreedDuration",
                values.exceededAgreedDuration
              );
              form.append(
                "revisedEndDateOfArbitration",
                values.revisedEndDateOfArbitration
              );

              if (values.supportingDocuments) {
                values.supportingDocuments.forEach((file) => {
                  if (file?.caseID) {
                    form.append(`supportingDocumentsOld`, file?.checkListDocID);
                  } else {
                    form.append(
                      `Arbitration Duration and Timeline Monitoring Attachment`,
                      file?.file
                    );
                  }
                });
              }
              dispatch(arbitrationDurationAndTimelineMonitoringAction(form))
                .unwrap()
                .then(async (result) => {
                  if (result) {
                    await dispatch(singleCaseDetailsAction(params));

                    await actions.resetForm();
                    await setEditForm(false);
                  }
                })
                .catch((error) => {
                  console.error("Action rejected with error:", error);
                });
            } catch (error) {}
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            resetForm,
          }) => {
            return (
              <form onSubmit={handleSubmit} className="mt-2">
                <Accordion defaultExpanded>
                  <AccordionSummary
                    onClick={() => {
                      handleAccordion(!accordionOpen);
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0 "
                      >
                        Arbitration Duration And Timeline Management
                      </Typography>

                      <div>
                        <Button
                          className="mx-2"
                          type="button"
                          variant="outlined"
                          color="secondary"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleAccordion(3);
                            setEditForm(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Box
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          backgroundColor: "white",
                          flexWrap: "wrap",
                          alignItems: "flex-end",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              px: 2,
                              gridTemplateColumns: {
                                xs: "1fr",
                                sm: "1fr",
                                md: "1fr",
                              },
                              width: { xs: "100%", sm: "50%", md: "25%" },
                            }}
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Typography
                              variant="body1"
                              component="label"
                              sx={{ textWrap: "balance" }}
                            >
                              Original Agreed Arbitration Duration (in days)
                            </Typography>

                            <OutlinedInput
                              id="agreedArbitrationDuration"
                              name="agreedArbitrationDuration"
                              sx={{
                                gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                              }}
                              value={values?.agreedArbitrationDuration}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                let value = e.target.value.trim();

                                value = value.replace(/^0+/, "") || "0";

                                if (!/^\d+$/.test(value)) return;

                                setFieldValue(
                                  "agreedArbitrationDuration",
                                  value
                                );

                                if (values.startDateOfArbitration) {
                                  const startDate = dayjs(
                                    values.startDateOfArbitration
                                  );
                                  const duration = Number(value) || 0;
                                  const endDate = startDate.add(
                                    duration,
                                    "day"
                                  );

                                  const revisedEndDate =
                                    values?.revisedEndDateOfArbitration
                                      ? dayjs(
                                          values.revisedEndDateOfArbitration
                                        )
                                      : null;

                                  const daysRemaining = revisedEndDate
                                    ? revisedEndDate.diff(dayjs(), "day")
                                    : endDate.diff(dayjs(), "day");

                                  setFieldValue(
                                    "endDateOfArbitration",
                                    endDate.toDate()
                                  );
                                  setFieldValue("daysRemaining", daysRemaining);
                                }
                              }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              px: 2,
                              gridTemplateColumns: {
                                xs: "1fr",
                                sm: "1fr",
                                md: "1fr",
                              },
                              width: { xs: "100%", sm: "50%", md: "25%" },
                            }}
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Typography
                              variant="body1"
                              component="label"
                              sx={{ marginRight: 2, textWrap: "balance" }}
                            >
                              Start Date of Arbitration
                            </Typography>

                            <DatePicker
                              id="startDateOfArbitration"
                              selected={values?.startDateOfArbitration}
                              maxDate={
                                values?.endDateOfArbitration
                                  ? dayjs(values.endDateOfArbitration)
                                      .subtract(1, "day")
                                      .toDate()
                                  : null
                              }
                              onChange={(date) => {
                                setFieldValue("startDateOfArbitration", date);

                                const duration =
                                  Number(values.agreedArbitrationDuration) || 0;

                                const endDate = dayjs(date).add(
                                  duration,
                                  "day"
                                );

                                const revisedEndDate =
                                  values?.revisedEndDateOfArbitration
                                    ? dayjs(values.revisedEndDateOfArbitration)
                                    : null;

                                const daysRemaining = revisedEndDate
                                  ? revisedEndDate.diff(dayjs(date), "day")
                                  : endDate.diff(dayjs(date), "day");

                                setFieldValue(
                                  "endDateOfArbitration",
                                  endDate.toDate()
                                );
                                setFieldValue("daysRemaining", daysRemaining);
                              }}
                              sx={{
                                width: "100%",
                                gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                              }}
                              onBlur={handleBlur}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="DD-MM-YYYY"
                              className="form-control"
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              px: 2,
                              gridTemplateColumns: {
                                xs: "1fr",
                                sm: "1fr",
                                md: "1fr",
                              },
                              width: { xs: "100%", sm: "50%", md: "25%" },
                            }}
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Typography
                              variant="body1"
                              component="label"
                              sx={{ marginRight: 2, textWrap: "balance" }}
                            >
                              End Date of Arbitration
                            </Typography>

                            <DatePicker
                              id="endDateOfArbitration"
                              selected={values?.endDateOfArbitration}
                              sx={{
                                width: "100%",
                                gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                              }}
                              onBlur={handleBlur}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="DD-MM-YYYY"
                              className="form-control"
                              disabled
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              px: 2,
                              gridTemplateColumns: {
                                xs: "1fr",
                                sm: "1fr",
                                md: "1fr",
                              },
                              width: { xs: "100%", sm: "50%", md: "25%" },
                            }}
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Typography
                              variant="body1"
                              component="label"
                              sx={{ textWrap: "balance" }}
                            >
                              Days Remaining
                            </Typography>

                            <OutlinedInput
                              id="daysRemaining"
                              name="daysRemaining"
                              sx={{
                                gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                              }}
                              value={values?.daysRemaining}
                              onBlur={handleBlur}
                              disabled
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              px: 2,
                              gridTemplateColumns: {
                                xs: "1fr",
                                sm: "1fr",
                                md: "1fr",
                              },
                              width: { xs: "100%", sm: "50%", md: "25%" },
                            }}
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <InputLabel
                              htmlFor="extensionOfDurationRequested"
                              sx={{ textWrap: "balance" }}
                            >
                              Extension of Duration Requested
                            </InputLabel>

                            <Select
                              labelId="extensionOfDurationRequested"
                              id="extensionOfDurationRequested"
                              name="extensionOfDurationRequested"
                              value={values?.extensionOfDurationRequested}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                            >
                              <MenuItem value={1}>Yes</MenuItem>
                              <MenuItem value={0}>No</MenuItem>
                            </Select>
                          </Box>

                          {values?.extensionOfDurationRequested == true && (
                            <>
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ marginRight: 2, textWrap: "balance" }}
                                >
                                  Extension of Duration Requested On
                                </Typography>

                                <DatePicker
                                  id="extensionOfDurationRequestedOn"
                                  selected={
                                    values?.extensionOfDurationRequestedOn
                                  }
                                  onChange={(date) => {
                                    setFieldValue(
                                      "extensionOfDurationRequestedOn",
                                      date
                                    );
                                  }}
                                  sx={{
                                    width: "100%",
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                />
                              </Box>

                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ textWrap: "balance" }}
                                >
                                  Extension of Duration Requested By
                                </Typography>

                                <OutlinedInput
                                  id="extensionOfDurationRequestedBy"
                                  name="extensionOfDurationRequestedBy"
                                  sx={{
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  value={values?.extensionOfDurationRequestedBy}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Box>

                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <InputLabel
                                  htmlFor="extensionOfDurationRequestStatus"
                                  sx={{ textWrap: "balance" }}
                                >
                                  Extension of Duration Request Status
                                </InputLabel>

                                <Select
                                  labelId="extensionOfDurationRequestStatus"
                                  id="extensionOfDurationRequestStatus"
                                  name="extensionOfDurationRequestStatus"
                                  value={
                                    values?.extensionOfDurationRequestStatus
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  fullWidth
                                >
                                  {settings
                                    ?.find(
                                      (item) =>
                                        item.categoryName ===
                                        "Status of Application"
                                    )
                                    ?.Children?.map((data) => (
                                      <MenuItem
                                        key={data?.generalSettingsID}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </Box>

                              {settings
                                ?.find(
                                  (item) =>
                                    item.categoryName ===
                                    "Status of Application"
                                )
                                ?.Children?.filter(
                                  (data) =>
                                    data.generalSettingsID ==
                                      values?.extensionOfDurationRequestStatus &&
                                    data.categoryName === "Granted"
                                ).length > 0 && (
                                <>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "center",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "25%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Extended Agreed Duration (in days)
                                    </Typography>

                                    <OutlinedInput
                                      id="exceededAgreedDuration"
                                      name="exceededAgreedDuration"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.exceededAgreedDuration}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        let value = e.target.value;

                                        value = value.replace(/^0+/, "") || "0";

                                        if (!/^\d+$/.test(value)) return;

                                        setFieldValue(
                                          "exceededAgreedDuration",
                                          value
                                        );
                                      }}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "center",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "25%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Revised End Date of Arbitration
                                    </Typography>

                                    <DatePicker
                                      id="revisedEndDateOfArbitration"
                                      selected={
                                        values?.revisedEndDateOfArbitration
                                      }
                                      minDate={
                                        values?.startDateOfArbitration
                                          ? dayjs(values.startDateOfArbitration)
                                              .subtract(0, "day")
                                              .toDate()
                                          : undefined
                                      }
                                      onChange={(date) => {
                                        const revised = date
                                          ? dayjs(date)
                                          : null;
                                        const startDate =
                                          values?.startDateOfArbitration
                                            ? dayjs(
                                                values.startDateOfArbitration
                                              )
                                            : null;
                                        const endDate =
                                          values?.endDateOfArbitration
                                            ? dayjs(values.endDateOfArbitration)
                                            : null;

                                        let daysRemaining = 0;

                                        if (revised && startDate) {
                                          daysRemaining = revised.diff(
                                            startDate,
                                            "day"
                                          );
                                        } else if (
                                          !revised &&
                                          endDate &&
                                          startDate
                                        ) {
                                          daysRemaining = endDate.diff(
                                            startDate,
                                            "day"
                                          );
                                        }

                                        setFieldValue(
                                          "daysRemaining",
                                          daysRemaining
                                        );
                                        setFieldValue(
                                          "revisedEndDateOfArbitration",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                </>
                              )}
                            </>
                          )}

                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              px: 2,
                              gridTemplateColumns: {
                                xs: "1fr",
                                sm: "1fr",
                                md: "1fr",
                              },
                              width: { xs: "100%", sm: "50%", md: "25%" },
                            }}
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Typography variant="body1" component="label">
                              Supporting Documents
                            </Typography>
                            <Button
                              className="col-12 col-sm-12 col-md-12 col-xl-12 "
                              component="label"
                              role={undefined}
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload files
                              <VisuallyHiddenInput
                                type="file"
                                multiple
                                accept=".pdf, .eml"
                                onChange={(event) => {
                                  const existingFiles =
                                    values?.supportingDocuments || [];
                                  const newFiles = Array.from(
                                    event.target.files
                                  );

                                  if (
                                    existingFiles.length + newFiles.length >
                                    5
                                  ) {
                                    enqueueSnackbar(
                                      "You can only upload up to 5 documents.",
                                      {
                                        variant: "warning",
                                        anchorOrigin: {
                                          vertical: "top",
                                          horizontal: "right",
                                        },
                                      }
                                    );

                                    return;
                                  }

                                  const existingFileNames = new Set(
                                    existingFiles.map(
                                      (file) => file.name || file.actualDocName
                                    )
                                  );

                                  const filteredNewFiles = newFiles.filter(
                                    (file) => !existingFileNames.has(file.name)
                                  );

                                  if (filteredNewFiles.length === 0) return;

                                  const updatedFiles = [
                                    ...existingFiles,
                                    ...filteredNewFiles.map((file) => ({
                                      file: file,
                                      checkListDocID:
                                        file?.checkListDocID || null,
                                      caseRowID: file?.caseRowID || null,
                                      caseID: file?.caseID || null,
                                      checklistID: file?.checklistID || null,
                                      genSetID: file?.genSetID || null,
                                      checklistDate: null,
                                      actualDocName: file.name,
                                      uploadDocName: file.name,
                                      documentType: file.type || null,
                                      documentSize:
                                        file.size?.toString() || null,
                                      documentClassification: null,
                                      documentService: null,
                                      serviceCount: null,
                                      comments: null,
                                      createdBy: file?.createdBy || 1,
                                      updatedBy: file?.updatedBy || 1,
                                      isActive: 1,
                                      createdAt: new Date().toISOString(),
                                      updatedAt: new Date().toISOString(),
                                    })),
                                  ];

                                  setFieldValue(
                                    "supportingDocuments",
                                    updatedFiles
                                  );
                                }}
                              />
                            </Button>
                            {touched.supportingDocuments &&
                              errors.supportingDocuments && (
                                <Typography color="error">
                                  {errors.supportingDocuments}
                                </Typography>
                              )}
                          </Box>
                        </LocalizationProvider>
                      </Box>

                      {!loading && values.supportingDocuments?.length > 0 ? (
                        <Box sx={{ mt: 2 }}>
                          {values.supportingDocuments?.map((file, index) => (
                            <Box
                              key={index}
                              display="flex"
                              alignItems="center"
                              sx={{ mb: 1 }}
                            >
                              <Stack direction="row" spacing={1}>
                                <Chip
                                  label={file?.actualDocName || file.name}
                                  onDelete={() => {
                                    const filtered =
                                      values.supportingDocuments.filter(
                                        (_, fileIndex) => fileIndex == index
                                      )[0]?.checkListDocID == null;

                                    if (window.delete || filtered) {
                                      const updatedFiles =
                                        values.supportingDocuments.filter(
                                          (_, fileIndex) => fileIndex !== index
                                        );
                                      setFieldValue(
                                        "supportingDocuments",
                                        updatedFiles
                                      );
                                    } else {
                                      animateCss(
                                        "info",
                                        "You are not authorized to delete"
                                      );
                                    }
                                  }}
                                />
                              </Stack>
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        loading && (
                          <>
                            {" "}
                            <Skeleton
                              variant="rectangular"
                              width="60%"
                              height={30}
                            />
                            <br />
                            <Skeleton
                              variant="rectangular"
                              width="60%"
                              height={30}
                            />
                            <br />
                            <Skeleton
                              variant="rectangular"
                              width="60%"
                              height={30}
                            />{" "}
                          </>
                        )
                      )}
                    </>
                  </AccordionDetails>
                </Accordion>
              </form>
            );
          }}
        </Formik>
      ) : (
        <>
          <Accordion expanded={accordionOpen == 3} className="mt-2">
            <AccordionSummary
              onClick={() => {
                handleAccordion(3);
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="m-0 "
                >
                  Arbitration Duration and Timeline Monitoring
                </Typography>

                <div>
                  {document?.length > 0 && (
                    <IconButton
                      color="primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenDocument(true);
                        const document = {
                          uniqueDocumentId:
                            rowData?.arbitrationTimelineMonitoringAttachment
                              .split(",")
                              .map(Number),
                        };
                        dispatch(preTrailCaseDetailsDocumentsAction(document));
                      }}
                    >
                      <AttachFileIcon className="fs-6 mx-2 text-primary" />
                    </IconButton>
                  )}
                  {window?.edit && (
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (window.edit) {
                          event.stopPropagation();
                          handleAccordion(3);
                          setEditForm(3);
                        } else {
                          animateCss(
                            "info",
                            "You are not authorized to update"
                          );
                        }
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={4}>
                  <List sx={{ bgcolor: "background.paper" }}>
                    <ListItemText
                      primary="Original Agreed Arbitration Duration (in days)"
                      secondary={rowData?.AgreedArbitrationDuration}
                    />
                  </List>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <List sx={{ bgcolor: "background.paper" }}>
                    <ListItemText
                      primary="Start Date of Arbitration"
                      secondary={
                        rowData?.StartDateOfArbitration
                          ? trackerFormatDate(
                              new Date(rowData?.StartDateOfArbitration)
                            )
                          : "DD-MM-YYYY"
                      }
                    />
                  </List>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <List sx={{ bgcolor: "background.paper" }}>
                    <ListItemText
                      primary="End Date of Arbitration"
                      secondary={
                        rowData?.EndDateOfArbitration
                          ? trackerFormatDate(
                              new Date(rowData?.EndDateOfArbitration)
                            )
                          : "DD-MM-YYYY"
                      }
                    />
                  </List>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <List sx={{ bgcolor: "background.paper" }}>
                    <ListItemText
                      primary="Days Remaining"
                      secondary={rowData?.daysRemaining}
                    />
                  </List>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <List sx={{ bgcolor: "background.paper" }}>
                    <ListItemText
                      primary="Extension of Duration Requested"
                      secondary={
                        rowData?.extensionOfDurationRequested == 1
                          ? "Yes"
                          : "No"
                      }
                    />
                  </List>
                </Grid>

                {rowData?.extensionOfDurationRequested == 1 && (
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Extension of Duration Requested On"
                        secondary={
                          rowData?.extensionOfDurationRequestedOn
                            ? trackerFormatDate(
                                new Date(
                                  rowData?.extensionOfDurationRequestedOn
                                )
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>
                )}

                {rowData?.extensionOfDurationRequested == 1 && (
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Extension of Duration Requested By"
                        secondary={rowData?.extensionOfDurationRequestedBy}
                      />
                    </List>
                  </Grid>
                )}
                {rowData?.extensionOfDurationRequested == 1 && (
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Extension of Duration Request Status"
                        secondary={
                          rowData?.extensionOfDurationRequestStatusID
                            ?.categoryName
                        }
                      />
                    </List>
                  </Grid>
                )}

                {settings
                  ?.find(
                    (item) => item.categoryName === "Status of Application"
                  )
                  ?.Children?.filter(
                    (data) =>
                      data.generalSettingsID ==
                        rowData?.extensionOfDurationRequestStatus &&
                      data.categoryName === "Granted"
                  ).length > 0 && (
                  <>
                    {rowData?.extensionOfDurationRequested == 1 && (
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Extended Agreed Duration (in days)"
                            secondary={rowData?.ExceededAgreedDuration}
                          />
                        </List>
                      </Grid>
                    )}

                    {rowData?.extensionOfDurationRequested == 1 && (
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Revised End Date of Arbitration"
                            secondary={rowData?.revisedEndDateOfArbitration}
                          />
                        </List>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
};

export default ArbitrationDurationAndTimelineMonitoring;

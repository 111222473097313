import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  partiesAndContactsClaimantDetailsCreateAction,
  partiesAndContactsClaimantDetailsDeleteAction,
  partiesAndContactsClaimantDetailsUpdateAction,
  singleCaseDetailsAction,
} from "../../../../redux/actions/Case/caseAction";
import { animateCss } from "../../../TosterAlert";
import { MdDelete } from "react-icons/md";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClaimantDetails = ({
  open,
  setOpen,
  expanded,
  setExpanded,
  settings,
  params,

  dispatch,
  handleChange,
}) => {
  const [formValue, setFormValue] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [editForm, setEditForm] = useState(false);

  const rowData = useSelector((state) => state?.case?.editCaseDetails);

  let data = rowData.partiesAndContacts_ClaimantDetails
    ? JSON.parse(JSON.parse(rowData.partiesAndContacts_ClaimantDetails))
    : [];

  return (
    <>
      <div className="d-flex row p-0 m-0  col-12 mt-2">
        <form xs={12} sm={12} md={12}>
          <Accordion expanded={expanded === 1 && open === true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={(event) => {
                event.stopPropagation();
                handleChange(1);
                setOpen(true);
              }}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  width: "100%",
                  backgroundColor: "white",
                }}
                xs={12}
                sm={12}
                md={12}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="m-0"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleChange(1);
                    setOpen(false);
                  }}
                >
                  Claimant Details
                </Typography>

                <div>
                  {window?.edit && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setEditIndex(null);
                        setOpenModel(true);
                      }}
                      disabled={data?.length >= 10}
                      className="justify-content-center"
                    >
                      Add Claimant Details
                    </Button>
                  )}
                </div>

                {/* <div>
                  {editForm && expanded === 1 && open === true && (
                    <Button
                      className="mx-2"
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={(event) => {
                        event.stopPropagation();
                        setEditForm(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {window?.edit && !editForm && (
                    <div>
                      <Button
                        onClick={(event) => {
                          event.stopPropagation();
                          if (window.edit) {
                            setEditForm(true);
                            setExpanded(1);
                            setOpen(true);
                          } else {
                            animateCss(
                              "info",
                              "You are not authorized to update"
                            );
                          }
                        }}
                        type="button"
                        color="primary"
                        variant="contained"
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div> */}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // alignItems: "end",
                      backgroundColor: "white",
                      flexWrap: "wrap",
                      alignItems: "stretch",
                    }}
                  >
                    {openModel && expanded == 1 && (
                      <ModelForm
                        setOpen={setOpen}
                        data={data}
                        settings={settings}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        setOpenModel={setOpenModel}
                        openModel={openModel}
                        editIndex={editIndex}
                        setEditIndex={setEditIndex}
                        dispatch={dispatch}
                        params={params}
                      />
                    )}

                    <Box
                      sx={{
                        display: "grid",
                        // px: 2,
                        gridTemplateColumns: {
                          xs: "1fr",
                          sm: "1fr",
                          md: "1fr",
                        },
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                        },
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Box
                        className="d-flex col-12"
                        // style={{
                        //   overflowX: "auto",
                        //   width: "100%",
                        // }}
                      >
                        <table
                          style={{ overflowWrap: "anywhere" }}
                          className="my-2 table table-striped table-bordered"
                        >
                          <thead
                            style={{
                              fontSize: "14px",
                              fontWeight: 800,
                            }}
                          >
                            <tr scope="row">
                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Claimant Number
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Claimant Name
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Claimant Type
                              </th>
                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Claimant Address for Communication
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Claimant Phone Number
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Claimant Email
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                  width: "65px",
                                }}
                                className="border"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {data?.map((order, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 800,
                                    }}
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="p"
                                      component="div"
                                      className="m-0"
                                      sx={
                                        window?.edit
                                          ? {
                                              color: "primary.main",
                                              cursor: "pointer",
                                            }
                                          : {
                                              color: "secondary.main",
                                              cursor: "pointer",
                                              textDecoration: "none",
                                              fontWeight: 800,
                                            }
                                      }
                                      onClick={(event) => {
                                        event.stopPropagation();

                                        if (window.edit) {
                                          setOpenModel(true);
                                          setEditIndex(index);
                                        } else {
                                          animateCss(
                                            "info",
                                            "You are not authorized to update"
                                          );
                                        }
                                      }}
                                    >
                                      Claimant {index + 1}
                                    </Typography>{" "}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.claimantName}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {settings
                                      ?.find(
                                        (item) =>
                                          item?.categoryName === "Claimant Type"
                                      )
                                      ?.Children?.find(
                                        (child) =>
                                          String(child.generalSettingsID) ===
                                          String(order?.claimantType)
                                      )?.categoryName ?? ""}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.claimantAddressforCommunication}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.claimantPhoneNumber}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.claimantEmail}
                                  </td>

                                  <td
                                    className={
                                      data?.length - 1 === index ? "d-flex" : ""
                                    }
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      color="error"
                                      style={{
                                        marginTop: "4%",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();

                                        if (window.delete) {
                                          enqueueSnackbar(
                                            <>
                                              <div>
                                                Are you sure you want to delete
                                                the record for Claimant Details{" "}
                                                {index + 1} ?
                                              </div>
                                            </>,
                                            {
                                              variant: "warning",
                                              onClose: () => {},
                                              anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "right",
                                              },
                                              action: (key) => (
                                                <>
                                                  <StyledButton
                                                    onClick={async () => {
                                                      const value = {
                                                        caseID: params,
                                                        index,
                                                      };
                                                      dispatch(
                                                        partiesAndContactsClaimantDetailsDeleteAction(
                                                          value
                                                        )
                                                      )
                                                        .unwrap()
                                                        .then((result) => {
                                                          if (result) {
                                                            dispatch(
                                                              singleCaseDetailsAction(
                                                                params
                                                              )
                                                            );
                                                          }
                                                        })
                                                        .catch((error) => {
                                                          console.error(
                                                            "Action rejected with error:",
                                                            error
                                                          );
                                                        });

                                                      closeSnackbar(key);
                                                    }}
                                                  >
                                                    Confirm
                                                  </StyledButton>
                                                  <StyledButton
                                                    onClick={() => {
                                                      closeSnackbar(key);
                                                    }}
                                                  >
                                                    Cancel
                                                  </StyledButton>
                                                </>
                                              ),
                                              autoHideDuration: null,
                                              style: {
                                                backgroundColor: "#f44336",
                                                width: "600px",
                                                color: "#fff",
                                              },
                                            }
                                          );
                                        } else {
                                          animateCss(
                                            "info",
                                            "You are not authorized to delete"
                                          );
                                        }
                                      }}
                                    >
                                      <MdDelete
                                        style={{
                                          fontSize: "24px",
                                        }}
                                      />
                                    </IconButton>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  </Box>

                  {/* Dynamice Data */}
                </LocalizationProvider>
              </>
            </AccordionDetails>
          </Accordion>
        </form>
      </div>
    </>
  );
};

const ModelForm = ({
  setOpen,
  formValue,
  setFormValue,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
  dispatch,
  params,
  data,
}) => {
  const editValue = data?.[editIndex] ?? null;

  const { id } = useParams();

  const initialValues = {
    claimantName: editValue?.claimantName ?? null,
    careOfType: editValue?.careOfType ?? null,
    claimantsFatherMotherHubandGuardianName:
      editValue?.claimantsFatherMotherHubandGuardianName ?? null,
    claimantType: editValue?.claimantType ?? null,
    claimantsLegalRepresentative:
      editValue?.claimantsLegalRepresentative ?? null,
    claimantPhoneNumber: editValue?.claimantPhoneNumber ?? null,
    claimantEmail: editValue?.claimantEmail ?? null,
    claimantAddressforCommunication:
      editValue?.claimantAddressforCommunication ?? null,
    claimantAddressLandmark: editValue?.claimantAddressLandmark ?? null,
    villageTaluk: editValue?.villageTaluk ?? null,
    districtCity: editValue?.districtCity ?? null,
    state: editValue?.state ?? null,
    country: editValue?.country ?? null,
    postalCode: editValue?.postalCode ?? null,
    claimantsCounselName: editValue?.claimantsCounselName ?? null,
    claimantsCounselContactNumber:
      editValue?.claimantsCounselContactNumber ?? null,
    claimantsCounselEmail: editValue?.claimantsCounselEmail ?? null,
    claimantsCounselAddressForCommunication:
      editValue?.claimantsCounselAddressForCommunication ?? null,
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={false}
          onSubmit={async (values, actions) => {
            try {
              const data = {
                claimantName: values?.claimantName ?? null,
                careOfType: values?.careOfType ?? null,
                claimantsFatherMotherHubandGuardianName:
                  values?.claimantsFatherMotherHubandGuardianName ?? null,
                claimantType: values?.claimantType ?? null,
                claimantsLegalRepresentative:
                  values?.claimantsLegalRepresentative ?? null,
                claimantPhoneNumber: values?.claimantPhoneNumber ?? null,
                claimantEmail: values?.claimantEmail ?? null,
                claimantAddressforCommunication:
                  values?.claimantAddressforCommunication ?? null,
                claimantAddressLandmark:
                  values?.claimantAddressLandmark ?? null,
                villageTaluk: values?.villageTaluk ?? null,
                districtCity: values?.districtCity ?? null,
                state: values?.state ?? null,
                country: values?.country ?? null,
                postalCode: values?.postalCode ?? null,
                claimantsCounselName: values?.claimantsCounselName ?? null,
                claimantsCounselContactNumber:
                  values?.claimantsCounselContactNumber ?? null,
                claimantsCounselEmail: values?.claimantsCounselEmail ?? null,
                claimantsCounselAddressForCommunication:
                  values?.claimantsCounselAddressForCommunication ?? null,
                caseID: id,
                editIndex,
              };

              if (editIndex == null) {
                dispatch(partiesAndContactsClaimantDetailsCreateAction(data))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();
                    setOpenModel(false);

                    dispatch(singleCaseDetailsAction(params));
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                dispatch(partiesAndContactsClaimantDetailsUpdateAction(data))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();
                    setOpenModel(false);

                    dispatch(singleCaseDetailsAction(params));
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            isSubmitting,
          }) => (
            <form
              onChange={(event) => {
                event.stopPropagation();
                setOpen(true);
              }}
              onSubmit={handleSubmit}
            >
              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantName">Claimant Name</InputLabel>
                <OutlinedInput
                  id="claimantName"
                  name="claimantName"
                  value={values?.claimantName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantName && errors.claimantName && (
                  <Typography color="error">{errors.claimantName}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="careOfType">Care of Type</InputLabel>
                <Select
                  labelId="careOfType"
                  id="careOfType"
                  name="careOfType"
                  value={values?.careOfType || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Care of Type")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.careOfType && errors.careOfType && (
                  <Typography color="error">{errors.careOfType}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantsFatherMotherHubandGuardianName">
                  Claimant’s Father/Mother/Husband/Guardian Name
                </InputLabel>
                <OutlinedInput
                  id="claimantsFatherMotherHubandGuardianName"
                  name="claimantsFatherMotherHubandGuardianName"
                  value={values?.claimantsFatherMotherHubandGuardianName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantsFatherMotherHubandGuardianName &&
                  errors.claimantsFatherMotherHubandGuardianName && (
                    <Typography color="error">
                      {errors.claimantsFatherMotherHubandGuardianName}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantType">Claimant Type</InputLabel>
                <Select
                  labelId="claimantType"
                  id="claimantType"
                  name="claimantType"
                  value={values?.claimantType || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Claimant Type")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.claimantType && errors.claimantType && (
                  <Typography color="error">{errors.claimantType}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantsLegalRepresentative">
                  Claimant’s Legal Representative
                </InputLabel>
                <OutlinedInput
                  id="claimantsLegalRepresentative"
                  name="claimantsLegalRepresentative"
                  value={values?.claimantsLegalRepresentative}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantsLegalRepresentative &&
                  errors.claimantsLegalRepresentative && (
                    <Typography color="error">
                      {errors.claimantsLegalRepresentative}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantPhoneNumber">
                  Claimant Phone Number
                </InputLabel>
                <OutlinedInput
                  id="claimantPhoneNumber"
                  name="claimantPhoneNumber"
                  value={values?.claimantPhoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantPhoneNumber && errors.claimantPhoneNumber && (
                  <Typography color="error">
                    {errors.claimantPhoneNumber}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantEmail">Claimant Email</InputLabel>
                <OutlinedInput
                  id="claimantEmail"
                  name="claimantEmail"
                  value={values?.claimantEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantEmail && errors.claimantEmail && (
                  <Typography color="error">{errors.claimantEmail}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantAddressforCommunication">
                  Claimant Address for Communication
                </InputLabel>
                <OutlinedInput
                  id="claimantAddressforCommunication"
                  name="claimantAddressforCommunication"
                  value={values?.claimantAddressforCommunication}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantAddressforCommunication &&
                  errors.claimantAddressforCommunication && (
                    <Typography color="error">
                      {errors.claimantAddressforCommunication}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantAddressLandmark">
                  Claimant Address Landmark
                </InputLabel>
                <OutlinedInput
                  id="claimantAddressLandmark"
                  name="claimantAddressLandmark"
                  value={values?.claimantAddressLandmark}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantAddressLandmark &&
                  errors.claimantAddressLandmark && (
                    <Typography color="error">
                      {errors.claimantAddressLandmark}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="villageTaluk">Village/Taluk</InputLabel>
                <OutlinedInput
                  id="villageTaluk"
                  name="villageTaluk"
                  value={values?.villageTaluk}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.villageTaluk && errors.villageTaluk && (
                  <Typography color="error">{errors.villageTaluk}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="districtCity">District/City</InputLabel>
                <OutlinedInput
                  id="districtCity"
                  name="districtCity"
                  value={values?.districtCity}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.districtCity && errors.districtCity && (
                  <Typography color="error">{errors.districtCity}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="state">State</InputLabel>
                <OutlinedInput
                  id="state"
                  name="state"
                  value={values?.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.state && errors.state && (
                  <Typography color="error">{errors.state}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="country">Country</InputLabel>
                <OutlinedInput
                  id="country"
                  name="country"
                  value={values?.country}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.country && errors.country && (
                  <Typography color="error">{errors.country}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="postalCode">Postal Code (PIN)</InputLabel>
                <OutlinedInput
                  id="postalCode"
                  name="postalCode"
                  value={values?.postalCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.postalCode && errors.postalCode && (
                  <Typography color="error">{errors.postalCode}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantsCounselName">
                  Claimant’s Counsel Name
                </InputLabel>
                <OutlinedInput
                  id="claimantsCounselName"
                  name="claimantsCounselName"
                  value={values?.claimantsCounselName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantsCounselName &&
                  errors.claimantsCounselName && (
                    <Typography color="error">
                      {errors.claimantsCounselName}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantsCounselContactNumber">
                  Claimant’s Counsel Contact Number
                </InputLabel>
                <OutlinedInput
                  id="claimantsCounselContactNumber"
                  name="claimantsCounselContactNumber"
                  value={values?.claimantsCounselContactNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantsCounselContactNumber &&
                  errors.claimantsCounselContactNumber && (
                    <Typography color="error">
                      {errors.claimantsCounselContactNumber}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantsCounselEmail">
                  Claimant’s Counsel Email
                </InputLabel>
                <OutlinedInput
                  id="claimantsCounselEmail"
                  name="claimantsCounselEmail"
                  value={values?.claimantsCounselEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantsCounselEmail &&
                  errors.claimantsCounselEmail && (
                    <Typography color="error">
                      {errors.claimantsCounselEmail}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantsCounselAddressForCommunication">
                  Claimant’s Counsel Address for Communication
                </InputLabel>
                <OutlinedInput
                  id="claimantsCounselAddressForCommunication"
                  name="claimantsCounselAddressForCommunication"
                  value={values?.claimantsCounselAddressForCommunication}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.claimantsCounselAddressForCommunication &&
                  errors.claimantsCounselAddressForCommunication && (
                    <Typography color="error">
                      {errors.claimantsCounselAddressForCommunication}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                  variant="outlined"
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default ClaimantDetails;

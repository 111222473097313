import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  forgotPassword,
  forgotPasswordOTPValidation,
  getLoginUser,
  login,
} from "../../api/user_api";
import cookie from "react-cookie";
import { setCookie } from "../../helpers/cookie";
import { setLoginCookies } from "../../helpers/auth";
import { getUsersRoleRouterAction } from "./UserRouters/UserRoleRouteAction";
import { animateCss } from "../../pages/TosterAlert";
import { userFilterColumnAction } from "./Case/caseAction";

export const loginUserAction = createAsyncThunk(
  "login/loginUserAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await login(values);
      if (users.data) {
        setLoginCookies(users);
        animateCss("success", "Login Successfully");
        return users;
      } else if (!users.data) {
        animateCss(
          "error",
          users.message ? users.message : "Invalid User Name Or Password",
          5000
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const forgotPasswordAction = createAsyncThunk(
  "login/forgotPasswordAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await forgotPassword(values);
      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const forgotPasswordOTPValidationAction = createAsyncThunk(
  "login/forgotPasswordOTPValidationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await forgotPasswordOTPValidation(values);
      return users.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const getUserAction = createAsyncThunk(
  "login/loginUserAction",
  async (values, { rejectWithValue, getState, dispatch }) => {
    try {
      const users = await getLoginUser();

      if (users?.data) {
        const setValue = await dispatch(
          userFilterColumnAction(users?.data?.caseFilter)
        );
      }
      const values = users?.data?.userRole;

      dispatch(getUsersRoleRouterAction(values));
      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  partiesAndContactsClaimantDetailsDeleteAction,
  partiesAndContactsRespondentDetailsCreateAction,
  partiesAndContactsRespondentDetailsDeleteAction,
  partiesAndContactsRespondentDetailsUpdateAction,
  singleCaseDetailsAction,
} from "../../../../redux/actions/Case/caseAction";
import { animateCss } from "../../../TosterAlert";
import { MdDelete } from "react-icons/md";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RespondentDetails = ({
  open,
  setOpen,
  expanded,
  setExpanded,
  settings,
  params,

  dispatch,
  handleChange,
}) => {
  const [formValue, setFormValue] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [editForm, setEditForm] = useState(false);

  const rowData = useSelector((state) => state?.case?.editCaseDetails);

  let data = rowData.partiesAndContacts_RespondentDetails
    ? JSON.parse(JSON.parse(rowData.partiesAndContacts_RespondentDetails))
    : [];

  return (
    <>
      <div className="d-flex row p-0 m-0  col-12 mt-2">
        <form xs={12} sm={12} md={12}>
          <Accordion
            onClick={(event) => {
              event.stopPropagation();
              handleChange(2);
              setOpen(true);
            }}
            expanded={expanded === 2 && open === true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  width: "100%",
                  backgroundColor: "white",
                }}
                xs={12}
                sm={12}
                md={12}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="m-0"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleChange(2);
                    setOpen(false);
                  }}
                >
                  Respondent Details
                </Typography>

                {window?.edit && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setEditIndex(null);
                      setExpanded(2);
                      setOpenModel(true);
                    }}
                    disabled={data?.length >= 10}
                    className="justify-content-center"
                  >
                    Add Respondent Details
                  </Button>
                )}

                {/* <div>
                  {editForm && expanded === 2 && open === true && (
                    <Button
                      className="mx-2"
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={(event) => {
                        event.stopPropagation();
                        setEditForm(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {window?.edit && !editForm && (
                    <div>
                      <Button
                        onClick={(event) => {
                          event.stopPropagation();
                          if (window.edit) {
                            setEditForm(true);
                            setExpanded(2);
                            setOpen(true);
                          } else {
                            animateCss(
                              "info",
                              "You are not authorized to update"
                            );
                          }
                        }}
                        type="button"
                        color="primary"
                        variant="contained"
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div> */}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // alignItems: "end",
                      backgroundColor: "white",
                      flexWrap: "wrap",
                      alignItems: "stretch",
                    }}
                  >
                    {openModel && expanded == 2 && (
                      <ModelForm
                        data={data}
                        settings={settings}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        setOpenModel={setOpenModel}
                        openModel={openModel}
                        editIndex={editIndex}
                        setEditIndex={setEditIndex}
                        dispatch={dispatch}
                        params={params}
                      />
                    )}

                    <Box
                      sx={{
                        display: "grid",
                        // px: 2,
                        gridTemplateColumns: {
                          xs: "1fr",
                          sm: "1fr",
                          md: "1fr",
                        },
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                        },
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Box
                        className="d-flex col-12"
                        // style={{
                        //   overflowX: "auto",
                        //   width: "100%",
                        // }}
                      >
                        <table
                          style={{ overflowWrap: "anywhere" }}
                          className="my-2 table table-striped table-bordered"
                        >
                          <thead
                            style={{
                              fontSize: "14px",
                              fontWeight: 800,
                            }}
                          >
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Respondent Number
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Respondent Name
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Respondent Type
                              </th>
                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Respondent Address for Communication
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Respondent Phone Number
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                className="border"
                              >
                                Respondent Email
                              </th>

                              <th
                                scope="col"
                                style={{
                                  fontWeight: "lighter",
                                  width: "65px",
                                }}
                                className="border"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {data?.map((order, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 800,
                                    }}
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="p"
                                      component="div"
                                      className="m-0"
                                      sx={
                                        window?.edit
                                          ? {
                                              color: "primary.main",
                                              cursor: "pointer",
                                            }
                                          : {
                                              color: "secondary.main",
                                              cursor: "pointer",
                                              textDecoration: "none",
                                              fontWeight: 800,
                                            }
                                      }
                                      onClick={(event) => {
                                        event.stopPropagation();

                                        if (window.edit) {
                                          setOpenModel(true);
                                          setEditIndex(index);
                                        } else {
                                          animateCss(
                                            "info",
                                            "You are not authorized to update"
                                          );
                                        }
                                      }}
                                    >
                                      Respondent {index + 1}
                                    </Typography>{" "}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.respondentName}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {settings
                                      ?.find(
                                        (item) =>
                                          item?.categoryName ===
                                          "Respondent Type"
                                      )
                                      ?.Children?.find(
                                        (child) =>
                                          String(child.generalSettingsID) ===
                                          String(order?.respondentType)
                                      )?.categoryName ?? ""}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.respondentAddressForCommunication}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.respondentPhoneNumber}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {order?.respondentEmail}
                                  </td>

                                  <td
                                    className={
                                      data?.length - 1 === index ? "d-flex" : ""
                                    }
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      color="error"
                                      style={{
                                        marginTop: "4%",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();

                                        if (window.delete) {
                                          enqueueSnackbar(
                                            <>
                                              <div>
                                                Are you sure you want to delete
                                                the record for Respondent
                                                Details {index + 1} ?
                                              </div>
                                            </>,
                                            {
                                              variant: "warning",
                                              onClose: () => {},
                                              anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "right",
                                              },
                                              action: (key) => (
                                                <>
                                                  <StyledButton
                                                    onClick={async () => {
                                                      const value = {
                                                        caseID: params,
                                                        index,
                                                      };
                                                      dispatch(
                                                        partiesAndContactsRespondentDetailsDeleteAction(
                                                          value
                                                        )
                                                      )
                                                        .unwrap()
                                                        .then((result) => {
                                                          if (result) {
                                                            dispatch(
                                                              singleCaseDetailsAction(
                                                                params
                                                              )
                                                            );
                                                          }
                                                        })
                                                        .catch((error) => {
                                                          console.error(
                                                            "Action rejected with error:",
                                                            error
                                                          );
                                                        });

                                                      closeSnackbar(key);
                                                    }}
                                                  >
                                                    Confirm
                                                  </StyledButton>
                                                  <StyledButton
                                                    onClick={() => {
                                                      closeSnackbar(key);
                                                    }}
                                                  >
                                                    Cancel
                                                  </StyledButton>
                                                </>
                                              ),
                                              autoHideDuration: null,
                                              style: {
                                                backgroundColor: "#f44336",
                                                width: "600px",
                                                color: "#fff",
                                              },
                                            }
                                          );
                                        } else {
                                          animateCss(
                                            "info",
                                            "You are not authorized to delete"
                                          );
                                        }
                                      }}
                                    >
                                      <MdDelete
                                        style={{
                                          fontSize: "24px",
                                        }}
                                      />
                                    </IconButton>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  </Box>

                  {/* Dynamice Data */}
                </LocalizationProvider>
              </>
            </AccordionDetails>
          </Accordion>
        </form>
      </div>
    </>
  );
};

const ModelForm = ({
  formValue,
  setFormValue,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
  dispatch,
  params,
  data,
}) => {
  const editValue = data?.[editIndex] ?? null;

  const { id } = useParams();

  const initialValues = {
    respondentName: editValue?.respondentName ?? null,
    careOfType: editValue?.careOfType ?? null,
    respondentsFatherMotherHubandGuardianName:
      editValue?.respondentsFatherMotherHubandGuardianName ?? null,
    respondentType: editValue?.respondentType ?? null,
    respondentsLegalRepresentative:
      editValue?.respondentsLegalRepresentative ?? null,
    respondentPhoneNumber: editValue?.respondentPhoneNumber ?? null,
    respondentEmail: editValue?.respondentEmail ?? null,
    respondentAddressForCommunication:
      editValue?.respondentAddressForCommunication ?? null,
    respondentAddressLandmark: editValue?.respondentAddressLandmark ?? null,
    villageTaluk: editValue?.villageTaluk ?? null,
    districtCity: editValue?.districtCity ?? null,
    state: editValue?.state ?? null,
    country: editValue?.country ?? null,
    postalCode: editValue?.postalCode ?? null,
    respondentsCounselName: editValue?.respondentsCounselName ?? null,
    respondentsCounselContactNumber:
      editValue?.respondentsCounselContactNumber ?? null,
    respondentsCounselEmail: editValue?.respondentsCounselEmail ?? null,
    respondentsCounselAddressForCommunication:
      editValue?.respondentsCounselAddressForCommunication ?? null,
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={false}
          onSubmit={async (values, actions) => {
            try {
              const data = {
                respondentName: values?.respondentName ?? null,
                careOfType: values?.careOfType ?? null,
                respondentsFatherMotherHubandGuardianName:
                  values?.respondentsFatherMotherHubandGuardianName ?? null,
                respondentType: values?.respondentType ?? null,
                respondentsLegalRepresentative:
                  values?.respondentsLegalRepresentative ?? null,
                respondentPhoneNumber: values?.respondentPhoneNumber ?? null,
                respondentEmail: values?.respondentEmail ?? null,
                respondentAddressForCommunication:
                  values?.respondentAddressForCommunication ?? null,
                respondentAddressLandmark:
                  values?.respondentAddressLandmark ?? null,
                villageTaluk: values?.villageTaluk ?? null,
                districtCity: values?.districtCity ?? null,
                state: values?.state ?? null,
                country: values?.country ?? null,
                postalCode: values?.postalCode ?? null,
                respondentsCounselName: values?.respondentsCounselName ?? null,
                respondentsCounselContactNumber:
                  values?.respondentsCounselContactNumber ?? null,
                respondentsCounselEmail:
                  values?.respondentsCounselEmail ?? null,
                respondentsCounselAddressForCommunication:
                  values?.respondentsCounselAddressForCommunication ?? null,
                caseID: id,
                editIndex,
              };

              if (editIndex == null) {
                dispatch(partiesAndContactsRespondentDetailsCreateAction(data))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();
                    setOpenModel(false);

                    dispatch(singleCaseDetailsAction(params));
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                dispatch(partiesAndContactsRespondentDetailsUpdateAction(data))
                  .unwrap()
                  .then((result) => {
                    actions.resetForm();
                    setOpenModel(false);

                    dispatch(singleCaseDetailsAction(params));
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            isSubmitting,
          }) => (
            <form
              onChange={(event) => {
                event.stopPropagation();
              }}
              onSubmit={handleSubmit}
            >
              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentName">
                  Respondent Name
                </InputLabel>
                <OutlinedInput
                  id="respondentName"
                  name="respondentName"
                  value={values?.respondentName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentName && errors.respondentName && (
                  <Typography color="error">{errors.respondentName}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="careOfType">Care of Type</InputLabel>
                <Select
                  labelId="careOfType"
                  id="careOfType"
                  name="careOfType"
                  value={values?.careOfType || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Care of Type")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.careOfType && errors.careOfType && (
                  <Typography color="error">{errors.careOfType}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentsFatherMotherHubandGuardianName">
                  Respondent’s Father/Mother/Huband/Guardian Name
                </InputLabel>
                <OutlinedInput
                  id="respondentsFatherMotherHubandGuardianName"
                  name="respondentsFatherMotherHubandGuardianName"
                  value={values?.respondentsFatherMotherHubandGuardianName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentsFatherMotherHubandGuardianName &&
                  errors.respondentsFatherMotherHubandGuardianName && (
                    <Typography color="error">
                      {errors.respondentsFatherMotherHubandGuardianName}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentType">
                  Respondent Type
                </InputLabel>
                <Select
                  labelId="respondentType"
                  id="respondentType"
                  name="respondentType"
                  value={values?.respondentType || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Respondent Type")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.generalSettingsID}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.respondentType && errors.respondentType && (
                  <Typography color="error">{errors.respondentType}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentsLegalRepresentative">
                  Respondent’s Legal Representative
                </InputLabel>
                <OutlinedInput
                  id="respondentsLegalRepresentative"
                  name="respondentsLegalRepresentative"
                  value={values?.respondentsLegalRepresentative}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentsLegalRepresentative &&
                  errors.respondentsLegalRepresentative && (
                    <Typography color="error">
                      {errors.respondentsLegalRepresentative}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentPhoneNumber">
                  Respondent Phone Number
                </InputLabel>
                <OutlinedInput
                  id="respondentPhoneNumber"
                  name="respondentPhoneNumber"
                  value={values?.respondentPhoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentPhoneNumber &&
                  errors.respondentPhoneNumber && (
                    <Typography color="error">
                      {errors.respondentPhoneNumber}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentEmail">
                  Respondent Email
                </InputLabel>
                <OutlinedInput
                  id="respondentEmail"
                  name="respondentEmail"
                  value={values?.respondentEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentEmail && errors.respondentEmail && (
                  <Typography color="error">
                    {errors.respondentEmail}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentAddressForCommunication">
                  Respondent Address for Communication
                </InputLabel>
                <OutlinedInput
                  id="respondentAddressForCommunication"
                  name="respondentAddressForCommunication"
                  value={values?.respondentAddressForCommunication}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentAddressForCommunication &&
                  errors.respondentAddressForCommunication && (
                    <Typography color="error">
                      {errors.respondentAddressForCommunication}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentAddressLandmark">
                  Respondent Address Landmark
                </InputLabel>
                <OutlinedInput
                  id="respondentAddressLandmark"
                  name="respondentAddressLandmark"
                  value={values?.respondentAddressLandmark}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentAddressLandmark &&
                  errors.respondentAddressLandmark && (
                    <Typography color="error">
                      {errors.respondentAddressLandmark}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="villageTaluk">Village/Taluk</InputLabel>
                <OutlinedInput
                  id="villageTaluk"
                  name="villageTaluk"
                  value={values?.villageTaluk}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.villageTaluk && errors.villageTaluk && (
                  <Typography color="error">{errors.villageTaluk}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="districtCity">District/City</InputLabel>
                <OutlinedInput
                  id="districtCity"
                  name="districtCity"
                  value={values?.districtCity}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.districtCity && errors.districtCity && (
                  <Typography color="error">{errors.districtCity}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="state">State</InputLabel>
                <OutlinedInput
                  id="state"
                  name="state"
                  value={values?.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.state && errors.state && (
                  <Typography color="error">{errors.state}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="country">Country</InputLabel>
                <OutlinedInput
                  id="country"
                  name="country"
                  value={values?.country}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.country && errors.country && (
                  <Typography color="error">{errors.country}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="postalCode">Postal Code (PIN)</InputLabel>
                <OutlinedInput
                  id="postalCode"
                  name="postalCode"
                  value={values?.postalCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.postalCode && errors.postalCode && (
                  <Typography color="error">{errors.postalCode}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentsCounselName">
                  Respondent’s Counsel Name
                </InputLabel>
                <OutlinedInput
                  id="respondentsCounselName"
                  name="respondentsCounselName"
                  value={values?.respondentsCounselName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentsCounselName &&
                  errors.respondentsCounselName && (
                    <Typography color="error">
                      {errors.respondentsCounselName}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentsCounselContactNumber">
                  Respondent’s Counsel Contact Number
                </InputLabel>
                <OutlinedInput
                  id="respondentsCounselContactNumber"
                  name="respondentsCounselContactNumber"
                  value={values?.respondentsCounselContactNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentsCounselContactNumber &&
                  errors.respondentsCounselContactNumber && (
                    <Typography color="error">
                      {errors.respondentsCounselContactNumber}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentsCounselEmail">
                  Respondent’s Counsel Email
                </InputLabel>
                <OutlinedInput
                  id="respondentsCounselEmail"
                  name="respondentsCounselEmail"
                  value={values?.respondentsCounselEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentsCounselEmail &&
                  errors.respondentsCounselEmail && (
                    <Typography color="error">
                      {errors.respondentsCounselEmail}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="respondentsCounselAddressForCommunication">
                  Respondent’s Counsel Address for Communication
                </InputLabel>
                <OutlinedInput
                  id="respondentsCounselAddressForCommunication"
                  name="respondentsCounselAddressForCommunication"
                  value={values?.respondentsCounselAddressForCommunication}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.respondentsCounselAddressForCommunication &&
                  errors.respondentsCounselAddressForCommunication && (
                    <Typography color="error">
                      {errors.respondentsCounselAddressForCommunication}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                  variant="outlined"
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default RespondentDetails;

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// material-ui
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { RxHome } from "react-icons/rx";

// project import
import MainCard from "../../components/MainCard";
import { useSelector } from "react-redux";

export default function Breadcrumbs({
  landingPage,
  navigation,
  title,
  ...others
}) {
  const location = useLocation();
  const [main, setMain] = useState();
  const [item, setItem] = useState();

  const navigate = useNavigate();

  let mainContent;
  let itemParant;
  let itemContent;
  let breadcrumbContent = <Typography />;
  let itemTitle = "";

  // set active item state
  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse);
        } else if (
          (collapse.type && collapse.type === "item") ||
          collapse.type === ""
        ) {
          if (location.pathname === collapse.url) {
            setMain(menu);
            setItem(collapse);
          } else if (collapse.url.includes(":Id")) {
            const dynamicRouteRegex = new RegExp(
              `^${collapse.url.replace(/:Id/g, "([A-Za-z0-9_-]+)").replace(/\//g, "\\/")}$`
            );

            if (dynamicRouteRegex.test(location.pathname)) {
              setMain(menu);
              setItem(collapse);
              return true;
            }
          }
        }
        return false;
      });
    }
  };

  useEffect(() => {
    navigation?.items?.map((menu) => {
      if (menu.type && menu.type === "group") {
        getCollapse(menu);
      }
      if (menu.type && menu.type === "none") {
        getCollapse(menu);
      }
      return false;
    });
  });

  // only used for component demo breadcrumbs
  if (location.pathname === "/breadcrumbs") {
    location.pathname = "/dashboard/analytics";
  }

  // collapse item
  if (main && main.type === "collapse") {
    mainContent = (
      <Typography
        component={Link}
        to={document.location.pathname}
        variant="h6"
        sx={{ textDecoration: "none" }}
        color="textSecondary"
      >
        {main.title}
      </Typography>
    );
  }

  if (item && item.type == "" && item.parant) {
    itemParant = (
      <Typography
        // component={Link}

        variant="h6"
        sx={{ textDecoration: "none" }}
        color="textSecondary"
      >
        {item.parant}
      </Typography>
    );
  }

  // items
  if ((item && item.type === "item") || (item && item.type == "")) {
    itemTitle = item.title;
    itemContent = (
      <Typography variant="subtitle1" color="textPrimary">
        {itemTitle}
      </Typography>
    );

    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          border={false}
          sx={{ mb: 3, bgcolor: "transparent" }}
          {...others}
          content={false}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <MuiBreadcrumbs aria-label="breadcrumb">
                <Typography
                  component={Link}
                  to={landingPage}
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: "none" }}
                >
                  <span>
                    <RxHome />
                    &nbsp; Home
                  </span>
                </Typography>
                {mainContent}
                {itemParant}
                {itemContent}
              </MuiBreadcrumbs>
            </Grid>
            {title && (
              <Grid item sx={{ mt: 2 }}>
                <Typography variant="h5">{item.title}</Typography>
              </Grid>
            )}
          </Grid>
        </MainCard>
      );
    }
  }

  //user path check
  const userRoleRouters = useSelector((state) => state.userRoleRouters);
  // const routes = useSelector((state) => state?.userRoleRouters?.landingPage[0]);

  // const userPath = userRoleRouters?.route?.some((userNav) =>
  //   userNav.url.some((urls) => urls?.url === window.location.pathname)
  // );

  // if (!userPath) {
  //   navigate("/404");
  // }

  const currentPath = window.location.pathname;
  const login = useSelector((state) => state?.login || []);

  const userPath = userRoleRouters?.route?.some((userNav) => {
    return userNav.url.some((routeItem) => {
      if (typeof routeItem.url === "string") {
        // const navigationList = navigation?.items?.some((item) =>
        //   item.children?.some((child) =>
        //     child?.children?.some((grandChild) => {
        //       // if (
        //       //   grandChild?.url == routeItem.url ||
        //       //   grandChild?.parantUrl == routeItem.url
        //       // )
        //       //   if (grandChild?.url?.includes(":Id")) {
        //       //     window.edit = routeItem.edit;
        //       //     window.delete = routeItem.delete;
        //       //   } else if (grandChild?.url?.includes("update")) {
        //       //     console.log(grandChild, routeItem, "grandChild");
        //       //     window.edit = routeItem.edit;
        //       //     window.delete = routeItem.delete;
        //       //   } else {
        //       //     window.edit = routeItem.edit;
        //       //     window.delete = routeItem.delete;
        //       //   }
        //       // console.log(
        //       //   grandChild?.url === routeItem.url ||
        //       //     (grandChild?.parantUrl == routeItem.url &&
        //       //       !routeItem.edit &&
        //       //       grandChild.url.includes(":Id")) ||
        //       //     (routeItem.edit && grandChild.url.includes(":View"))
        //       // )
        //       // console.log(toString(grandChild.url) === toString(currentPath))
        //     })
        //   )
        // );

        // console.log(navigationList, routeItem.url, "navigationList");

        // const routeRegex = new RegExp(
        //   `^${routeItem.url.replace("/case/caseDetails", "/case/caseDetails(/([A-Za-z0-9!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>?`~]+))?$")}$`
        // );

        // const dynamicPath = routeItem.url; // Dynamic segment

        // const routeRegex = new RegExp(
        //   `^${routeItem.url.replace(/\/$/, "")}${dynamicPath}(?:/([A-Za-z0-9!@#$%^&*()_+\\-=\\[\\]{};':"\\\\|,.<>?\\\`~]+))?$`
        // );

        const dynamicPath = routeItem.url.replace(/\/$/, "");

        const routeRegex = new RegExp(
          `^${dynamicPath}(?:/([A-Za-z0-9!@#$%^&*()_+\\-=\\[\\]{};':"\\\\|,.<>?\\\`~]+))?$`
        );

        if (routeRegex.test(currentPath)) {
          window.edit = routeItem.edit;
          window.delete = routeItem.delete;
        }
        //  new RegExp(
        //   `^${routeItem.url.replace(/:Id/g, "([A-Za-z0-9_-]+)").replace(/\//g, "\\/")}$`
        // );

        return routeRegex.test(currentPath);
      } else if (
        window.location.pathname === "/resetPassword" &&
        login.data.role.role.toLowerCase() === "admin"
      ) {
        window.edit = routeItem.edit;
        window.delete = routeItem.delete;
        return true;
      }
      return false;
    });
  });

  // const userPath = userRoleRouters?.route?.some((userNav) => {
  //   return userNav.url.some((routeItem) => {
  //     if (typeof routeItem.url === "string") {
  //       // Store permissions globally

  //       window.edit = routeItem.edit;
  //       window.delete = routeItem.delete;

  //       // Directly check if the currentPath matches any allowed route
  //       const isMatch =
  //         currentPath === routeItem.url ||
  //         (routeItem.edit && currentPath.startsWith(`${routeItem.url}/`));

  //       // if (currentPath.startsWith(`${routeItem.url}/`)) {
  //       //   if (!routeItem.edit) {
  //       //     navigate(-1);
  //       //   }
  //       // }

  //       return isMatch;
  //     } else if (
  //       window.location.pathname === "/resetPassword" &&
  //       login.data.role.role.toLowerCase() === "admin"
  //     ) {
  //       window.edit = routeItem.edit;
  //       window.delete = routeItem.delete;
  //       return true;
  //     }
  //     return false;
  //   });
  // });

  useEffect(() => {
    if (!userPath) {
      // navigate("/404");
    }
  }, [userPath, navigate]);

  return breadcrumbContent;
}

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  custom: PropTypes.bool,
  divider: PropTypes.bool,
  heading: PropTypes.string,
  icon: PropTypes.bool,
  icons: PropTypes.bool,
  links: PropTypes.array,
  maxItems: PropTypes.number,
  rightAlign: PropTypes.bool,
  separator: PropTypes.any,
  title: PropTypes.bool,
  titleBottom: PropTypes.bool,
  sx: PropTypes.any,
  others: PropTypes.any,
};

// import PropTypes from "prop-types";
// import { useEffect, useState } from "react";
// import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// // material-ui
// import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
// import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
// import { RxHome } from "react-icons/rx";

// // project import
// import MainCard from "../../components/MainCard";
// import { useSelector } from "react-redux";

// export default function Breadcrumbs({
//   landingPage,
//   navigation,
//   title,
//   ...others
// }) {
//   const location = useLocation();
//   const [main, setMain] = useState();
//   const [item, setItem] = useState();

//   const navigate = useNavigate();

//   let mainContent;
//   let itemParant;
//   let itemContent;
//   let breadcrumbContent = <Typography />;
//   let itemTitle = "";

//   // set active item state
//   const getCollapse = (menu) => {
//     if (menu.children) {
//       menu.children.filter((collapse) => {
//         if (collapse.type && collapse.type === "collapse") {
//           getCollapse(collapse);
//         } else if (
//           (collapse.type && collapse.type === "item") ||
//           collapse.type === ""
//         ) {
//           if (location.pathname === collapse.url) {
//             setMain(menu);
//             setItem(collapse);
//           } else if (collapse.url.includes(":Id")) {
//             const dynamicRouteRegex = new RegExp(
//               `^${collapse.url.replace(/:Id/g, "([A-Za-z0-9_-]+)").replace(/\//g, "\\/")}$`
//             );

//             if (dynamicRouteRegex.test(location.pathname)) {
//               setMain(menu);
//               setItem(collapse);
//               return true;
//             }
//           }
//         }
//         return false;
//       });
//     }
//   };

//   useEffect(() => {
//     navigation?.items?.map((menu) => {
//       if (menu.type && menu.type === "group") {
//         getCollapse(menu);
//       }
//       if (menu.type && menu.type === "none") {
//         getCollapse(menu);
//       }
//       return false;
//     });
//   });

//   // only used for component demo breadcrumbs
//   if (location.pathname === "/breadcrumbs") {
//     location.pathname = "/dashboard/analytics";
//   }

//   // collapse item
//   if (main && main.type === "collapse") {
//     mainContent = (
//       <Typography
//         component={Link}
//         to={document.location.pathname}
//         variant="h6"
//         sx={{ textDecoration: "none" }}
//         color="textSecondary"
//       >
//         {main.title}
//       </Typography>
//     );
//   }

//   if (item && item.type == "" && item.parant) {
//     itemParant = (
//       <Typography
//         // component={Link}

//         variant="h6"
//         sx={{ textDecoration: "none" }}
//         color="textSecondary"
//       >
//         {item.parant}
//       </Typography>
//     );
//   }

//   // items
//   if ((item && item.type === "item") || (item && item.type == "")) {
//     itemTitle = item.title;
//     itemContent = (
//       <Typography variant="subtitle1" color="textPrimary">
//         {itemTitle}
//       </Typography>
//     );

//     // main
//     if (item.breadcrumbs !== false) {
//       breadcrumbContent = (
//         <MainCard
//           border={false}
//           sx={{ mb: 3, bgcolor: "transparent" }}
//           {...others}
//           content={false}
//         >
//           <Grid
//             container
//             direction="column"
//             justifyContent="flex-start"
//             alignItems="flex-start"
//             spacing={1}
//           >
//             <Grid item>
//               <MuiBreadcrumbs aria-label="breadcrumb">
//                 <Typography
//                   component={Link}
//                   to={landingPage}
//                   color="textSecondary"
//                   variant="h6"
//                   sx={{ textDecoration: "none" }}
//                 >
//                   <span>
//                     <RxHome />
//                     &nbsp; Home
//                   </span>
//                 </Typography>
//                 {mainContent}
//                 {itemParant}
//                 {itemContent}
//               </MuiBreadcrumbs>
//             </Grid>
//             {title && (
//               <Grid item sx={{ mt: 2 }}>
//                 <Typography variant="h5">{item.title}</Typography>
//               </Grid>
//             )}
//           </Grid>
//         </MainCard>
//       );
//     }
//   }

//   //user path check
//   const userRoleRouters = useSelector((state) => state.userRoleRouters);
//   // const routes = useSelector((state) => state?.userRoleRouters?.landingPage[0]);

//   // const userPath = userRoleRouters?.route?.some((userNav) =>
//   //   userNav.url.some((urls) => urls?.url === window.location.pathname)
//   // );

//   // if (!userPath) {
//   //   navigate("/404");
//   // }

//   const currentPath = window.location.pathname;
//   const login = useSelector((state) => state?.login || []);

//   // const userPath = userRoleRouters?.route?.some((userNav) => {
//   //   return userNav.url.some((routeItem) => {
//   //     if (typeof routeItem.url === "string") {
//   //       console.log(routeItem.url, userNav, "routeItem.url ");
//   //       window.edit = routeItem.edit;
//   //       window.delete = routeItem.delete;
//   //       const routeRegex = new RegExp(
//   //         `^${routeItem.url.replace("/case/caseDetails", "/case/caseDetails(/([A-Za-z0-9!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>?`~]+))?$")}$`
//   //       );
//   //       console.log(routeRegex.test(currentPath), "check");
//   //       console.log(currentPath, "currentPath");
//   //       console.log(userNav.url, "userNav.url");

//   //       return routeRegex.test(currentPath);
//   //     } else if (
//   //       window.location.pathname == "/resetPassword" &&
//   //       login.data.role.role.toLowerCase() == "admin"
//   //     ) {
//   //       window.edit = routeItem.edit;
//   //       window.delete = routeItem.delete;
//   //       return true;
//   //     }
//   //     return false;
//   //   });
//   // });

//   const userPath = userRoleRouters?.route?.map((userNav) => {
//     return userNav.url.some((routeItem) => {
//       if (typeof routeItem.url === "string") {
//         // Store permissions globally

//         window.edit = routeItem.edit;
//         window.delete = routeItem.delete;

//         const hasEditPermission = userRoleRouters?.route?.some((item) =>
//           item.url?.some(
//             (child) => currentPath.includes(child.url) && child.edit
//           )
//         );
//         const navigationList = navigation?.items?.some((item) =>
//           item.children?.some((child) =>
//             child?.children?.some(
//               (grandChild) =>
//                 console.log(
//                   grandChild?.url === routeItem.url ||
//                     (grandChild?.parantUrl == routeItem.url &&
//                       !routeItem.edit &&
//                       grandChild.url.includes(":Id")) ||
//                     (routeItem.edit && grandChild.url.includes(":View"))
//                 )
//               // console.log(toString(grandChild.url) === toString(currentPath))
//             )
//           )
//         );

//         console.log(navigationList, routeItem);

//         // Directly check if the currentPath matches any allowed route
//         const isMatch =
//           currentPath === routeItem.url ||
//           new RegExp(
//             `^${routeItem.url.replace(/:Id/g, "([A-Za-z0-9_-]+)").replace(/\//g, "\\/")}$`
//           );

//         // (routeItem.edit && currentPath.startsWith(`${routeItem.url}/`));

//         console.log(isMatch, currentPath, hasEditPermission);

//         // if (currentPath.startsWith(`${routeItem.url}/`)) {
//         //   if (!routeItem.edit) {
//         //     navigate(-1);
//         //   }
//         // }

//         return isMatch;
//       } else if (
//         window.location.pathname === "/resetPassword" &&
//         login.data.role.role.toLowerCase() === "admin"
//       ) {
//         window.edit = routeItem.edit;
//         window.delete = routeItem.delete;
//         return true;
//       }
//       return false;
//     });
//   });

//   useEffect(() => {
//     if (!userPath) {
//       navigate("/404");
//     }
//   }, [userPath, navigate]);

//   return breadcrumbContent;
// }

// Breadcrumbs.propTypes = {
//   card: PropTypes.bool,
//   custom: PropTypes.bool,
//   divider: PropTypes.bool,
//   heading: PropTypes.string,
//   icon: PropTypes.bool,
//   icons: PropTypes.bool,
//   links: PropTypes.array,
//   maxItems: PropTypes.number,
//   rightAlign: PropTypes.bool,
//   separator: PropTypes.any,
//   title: PropTypes.bool,
//   titleBottom: PropTypes.bool,
//   sx: PropTypes.any,
//   others: PropTypes.any,
// };

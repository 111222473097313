import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  singleCaseDetailsAction,
  updateCasePrimaryInformationAction,
} from "../../../redux/actions/Case/caseAction";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { animateCss } from "../../TosterAlert";
import { checklistDocumentUplodOptionsAction } from "../../../redux/actions/checklistDocuments/checklistDocumentsAction";
import { caseChecklistDocumentAction } from "../../../redux/actions/Checklist/checklistAction";

const CasePrimaryInformation = () => {
  // const rowData = {};

  const [editPrimaryInformation, setEditPrimaryInformation] = useState(false);

  const useStyles = makeStyles((theme) => ({
    textarea: {
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "5%",
      fontSize: "1rem",
      borderRadius: "4px",
      border: "1px solid #ccc",
      resize: "vertical",
      minHeight: "100px",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  function trackerFormatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  const { id } = useParams();
  const params = { caseID: id };
  const user = useSelector((state) => state.login.data);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);

  const rowData = useSelector((state) => state?.case?.editCaseDetails);
  const caseManager = useSelector((state) => state.users.caseManager);

  return (
    <Card variant="outlined" className=" mt-3" sx={{ maxWidth: "100%" }}>
      <Box sx={{ p: 2 }}>
        {editPrimaryInformation ? (
          <Formik
            initialValues={{
              caseID: !rowData?.caseID ? null : rowData.caseID,
              caseNumber: !rowData?.caseID ? null : rowData.caseNumber,
              caseName: !rowData?.caseID
                ? null
                : rowData?.caseName?.trim() !== ""
                  ? rowData?.caseName
                  : rowData?.Claimant && rowData?.Respondent
                    ? `${rowData.Claimant} Vs ${rowData?.Respondent}`
                    : null,
              caseStatus: !rowData?.caseID
                ? null
                : rowData.caseStatusID.generalSettingsID,
              reOpeningReason: !rowData?.caseID
                ? null
                : rowData.reOpeningReason,
              priorityLevel: !rowData?.caseID ? null : rowData.priorityLevel,
              complexity: !rowData?.caseID ? null : rowData.complexity,
              riskMonetary: !rowData?.caseID ? null : rowData.riskMonetary,
              riskReputation: !rowData?.caseID ? null : rowData.riskReputation,
              riskRegulatory: !rowData?.caseID ? null : rowData.riskRegulatory,
              caseOpenedOn: !rowData?.caseID ? null : rowData.caseOpenedOn,
              caseCloseDate: !rowData?.caseID ? null : rowData.caseCloseDate,
              caseClosingReason: !rowData?.caseID
                ? null
                : rowData.caseClosingReason.generalSettingsID,
              caseClosingDescription: !rowData?.caseID
                ? null
                : rowData.caseClosingDescription,
              caseManager: !rowData?.caseID ? null : rowData.caseManager,
              caseAdministrator: !rowData?.caseID
                ? null
                : rowData.caseAdministrator,
              plaintiff_Claimant_Petitioner_Appellant: !rowData?.caseID
                ? null
                : rowData.Plaintiff_Claimant_Petitioner_Appellant,
              poaLoaName: !rowData?.caseID ? null : rowData.poaLoaName,
              advocateForPlaintiff_Claimant_Petitioner_Appellant:
                !rowData?.caseID
                  ? null
                  : rowData.AdvocateForPlaintiff_Claimant_Petitioner_Appellant,
              vakalatByClaimantCounselFiled: !rowData?.caseID
                ? null
                : rowData.vakalatByClaimantCounselFiled,
              vakalatByClaimantCounselFiledOn: !rowData?.caseID
                ? null
                : rowData.vakalatByClaimantCounselFiledOn,
              defendant_Respondent: !rowData?.caseID
                ? null
                : rowData.Defendant_Respondent,
              legalRepresentativeOfDefendantRespondent: !rowData?.caseID
                ? null
                : rowData.LegalRepresentativeOfDefendantRespondent,
              advocateForDefendantRespondent: !rowData?.caseID
                ? null
                : rowData.AdvocateForDefendantRespondent,
              vakalatByRespondentCounselFiled: !rowData?.caseID
                ? null
                : rowData.vakalatByRespondentCounselFiled,
              vakalatByRespondentCounselFiledOn: !rowData?.caseID
                ? null
                : rowData.vakalatByRespondentCounselFiledOn,
              practiceArea: !rowData?.caseID
                ? null
                : rowData.PracticeArea.generalSettingsID,
              caseTypeID: !rowData?.caseID
                ? null
                : rowData.caseType.generalSettingsID,
              arbitrationStatus: !rowData?.caseID
                ? null
                : rowData.arbitrationStatusID.generalSettingsID,
              arbitrationMatterType: !rowData?.caseID
                ? null
                : rowData?.caseType?.categoryName
                      ?.toLowerCase()
                      .includes("arbitration")
                  ? rowData.arbitrationMatterType
                  : null,
              arbitrationMatterTypeDescription: !rowData?.caseID
                ? null
                : rowData?.caseType?.categoryName
                      ?.toLowerCase()
                      .includes("arbitration")
                  ? rowData.arbitrationMatterTypeDescription
                  : null,
              previousStage: !rowData?.caseID ? null : rowData.previousStage,
              currentStage: !rowData?.caseID ? null : rowData.currentStage,
              nextStage: !rowData?.caseID ? null : rowData.nextStage,
              previousHearingDate: !rowData?.caseID
                ? null
                : rowData.previousHearingDate,
              nextHearingDate: !rowData?.caseID
                ? null
                : rowData.nextHearingDate,
              courtForum: !rowData?.caseID ? null : rowData.CourtForum,
              jurisdictionType: !rowData?.caseID
                ? null
                : rowData.JurisdictionType.generalSettingsID,
              disputeValue: !rowData?.caseID ? null : rowData.DisputeValue,
              causeOfActionOn: !rowData?.caseID
                ? null
                : rowData.CauseOfActionOn,
              IsTheDisputeClaimWithinTheStatuteOfLimitations: !rowData?.caseID
                ? null
                : rowData.IsTheDisputeClaimWithinTheStatuteOfLimitations,
              remarks: !rowData?.caseID ? null : rowData.remarks,
              modeOfCommunications: !rowData?.caseID
                ? null
                : rowData.ModeOfCommunications.generalSettingsID,
              cityTalukVillage: !rowData?.caseID
                ? null
                : rowData.CityTalukVillage,
              district: !rowData?.caseID ? null : rowData.District,
              state: !rowData?.caseID ? null : rowData.state.generalSettingsID,
              country: !rowData?.caseID ? null : rowData.Country,
              contested: !rowData?.caseID ? null : rowData.contested,
              params,
              user,
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              try {
                dispatch(updateCasePrimaryInformationAction(values));
                setEditPrimaryInformation(false);
                setTimeout(() => {
                  dispatch(singleCaseDetailsAction(params));
                  dispatch(caseChecklistDocumentAction(params));
                }, 1000);
              } catch (error) {
                // Handle submission error if needed
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
              resetForm,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          backgroundColor: "white",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="m-0 "
                        >
                          Case Primary Information
                        </Typography>

                        <div>
                          <Button
                            className="mx-2"
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={(event) => {
                              // event.stopPropagation();
                              // resetForm();
                              setEditPrimaryInformation(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button // color="primary"
                            variant="contained"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <Box
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "white",
                            flexWrap: "wrap",
                            alignItems: "stretch",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                CaseNect Number
                              </Typography>

                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="caseID"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.caseID}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Name
                              </Typography>

                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="caseName"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.caseName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Number
                              </Typography>

                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="caseNumber"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.caseNumber}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseStatus"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Status
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="caseStatus"
                                name="caseStatus"
                                value={values?.caseStatus}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) => item.categoryName == "Case Status"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Case Opened On
                              </Typography>

                              <DatePicker
                                id="caseOpenedOn"
                                selected={values?.caseOpenedOn}
                                onChange={(date) => {
                                  setFieldValue("caseOpenedOn", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            {settings
                              ?.find(
                                (item) => item.categoryName == "Case Status"
                              )
                              ?.Children?.find(
                                (child) => child?.categoryName == "Re-Open"
                              )?.generalSettingsID == values.caseStatus && (
                              <>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "center",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: { xs: "100%", sm: "50%", md: "33%" },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={4}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Re-Opening Reason
                                  </Typography>

                                  <OutlinedInput
                                    id="reOpeningReason"
                                    name="reOpeningReason"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={values?.reOpeningReason}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                              </>
                            )}

                            {settings
                              ?.find(
                                (item) => item.categoryName == "Case Status"
                              )
                              ?.Children?.find(
                                (child) => child?.categoryName == "Closed"
                              )?.generalSettingsID == values.caseStatus && (
                              <>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "center",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: { xs: "100%", sm: "50%", md: "33%" },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={4}
                                >
                                  <Typography
                                    variant="body1"
                                    component="label"
                                    sx={{ marginRight: 2, textWrap: "balance" }}
                                  >
                                    Case Closed On
                                  </Typography>

                                  <DatePicker
                                    id="caseCloseDate"
                                    selected={values?.caseCloseDate}
                                    onChange={(date) => {
                                      setFieldValue("caseCloseDate", date);
                                    }}
                                    sx={{
                                      width: "100%",
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    onBlur={handleBlur}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="DD-MM-YYYY"
                                    className="form-control"
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "center",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: { xs: "100%", sm: "50%", md: "33%" },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={4}
                                >
                                  <InputLabel
                                    htmlFor="caseClosingReason"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Case Closing Reason
                                  </InputLabel>

                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="caseClosingReason"
                                    name="caseClosingReason"
                                    value={values?.caseClosingReason}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    {settings
                                      ?.find(
                                        (item) =>
                                          item.categoryName ==
                                          "Case Closing Reason"
                                      )
                                      ?.Children?.map((data, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={data?.generalSettingsID}
                                          >
                                            {data?.categoryName}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </Box>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "center",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: { xs: "100%", sm: "50%", md: "33%" },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={4}
                                >
                                  <InputLabel
                                    htmlFor="caseClosingDescription"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Case Closing Description
                                  </InputLabel>
                                  <OutlinedInput
                                    id="caseClosingDescription"
                                    name="caseClosingDescription"
                                    sx={{
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                      },
                                    }}
                                    value={values?.caseClosingDescription}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                              </>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="priorityLevel"
                                sx={{ textWrap: "balance" }}
                              >
                                Priority Level
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="priorityLevel"
                                name="priorityLevel"
                                value={values?.priorityLevel}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Priority Level"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseManager"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Manager
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="caseManager"
                                name="caseManager"
                                value={values?.caseManager}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {caseManager?.map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data?.id}>
                                      {data?.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseAdministrator"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Administrator
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="caseAdministrator"
                                name="caseAdministrator"
                                value={values?.caseAdministrator}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {caseManager?.map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data?.id}>
                                      {data?.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Plaintiff /Claimant / Petitioner / Appellant
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="plaintiff_Claimant_Petitioner_Appellant"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={
                                  values?.plaintiff_Claimant_Petitioner_Appellant
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Legal Representative of Plaintiff /Claimant /
                                Petitioner / Appellant
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="poaLoaName"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.poaLoaName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Counsel for Plaintiff /Claimant / Petitioner /
                                Appellant
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="advocateForPlaintiff_Claimant_Petitioner_Appellant"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={
                                  values?.advocateForPlaintiff_Claimant_Petitioner_Appellant
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="vakalatByClaimantCounselFiled"
                                sx={{ textWrap: "balance" }}
                              >
                                Vakalat by Claimant Counsel Filed
                              </InputLabel>

                              <Select
                                labelId="vakalatByClaimantCounselFiled"
                                id="vakalatByClaimantCounselFiled"
                                name="vakalatByClaimantCounselFiled"
                                value={
                                  values?.vakalatByClaimantCounselFiled ?? "0"
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value="1">Yes</MenuItem>
                                <MenuItem value="0">No</MenuItem>
                              </Select>
                            </Box>

                            {(values?.vakalatByClaimantCounselFiled ?? "0") ==
                              1 && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "33%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ marginRight: 2, textWrap: "balance" }}
                                >
                                  Vakalat by Claimant Counsel Filed On
                                </Typography>

                                <DatePicker
                                  id="vakalatByClaimantCounselFiledOn"
                                  selected={
                                    values?.vakalatByClaimantCounselFiledOn
                                  }
                                  onChange={(date) => {
                                    setFieldValue(
                                      "vakalatByClaimantCounselFiledOn",
                                      date
                                    );
                                  }}
                                  sx={{
                                    width: "100%",
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                />
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Defendant / Respondent
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="defendant_Respondent"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.defendant_Respondent}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Legal Representative of Defendant / Respondent
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="legalRepresentativeOfDefendantRespondent"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={
                                  values?.legalRepresentativeOfDefendantRespondent
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Counsel for Defendant / Respondent
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="advocateForDefendantRespondent"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.advocateForDefendantRespondent}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="vakalatByRespondentCounselFiled"
                                sx={{ textWrap: "balance" }}
                              >
                                Vakalat by Respondent Counsel Filed
                              </InputLabel>

                              <Select
                                labelId="vakalatByRespondentCounselFiled"
                                id="vakalatByRespondentCounselFiled"
                                name="vakalatByRespondentCounselFiled"
                                value={
                                  values?.vakalatByRespondentCounselFiled ?? "0"
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value="1">Yes</MenuItem>
                                <MenuItem value="0">No</MenuItem>
                              </Select>
                            </Box>

                            {(values?.vakalatByRespondentCounselFiled ?? "0") ==
                              1 && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "33%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ marginRight: 2, textWrap: "balance" }}
                                >
                                  Vakalat by Respondent Counsel Filed On
                                </Typography>

                                <DatePicker
                                  id="vakalatByRespondentCounselFiledOn"
                                  selected={
                                    values?.vakalatByRespondentCounselFiledOn
                                  }
                                  onChange={(date) => {
                                    setFieldValue(
                                      "vakalatByRespondentCounselFiledOn",
                                      date
                                    );
                                  }}
                                  sx={{
                                    width: "100%",
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                />
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Practice Area
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="practiceArea"
                                name="practiceArea"
                                value={values?.practiceArea}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Practice Area"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Type
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="caseTypeID"
                                name="caseTypeID"
                                value={values?.caseTypeID}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) => item.categoryName == "Case Type"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="arbitrationStatus"
                                sx={{ textWrap: "balance" }}
                              >
                                Arbitration Status
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="arbitrationStatus"
                                name="arbitrationStatus"
                                value={values?.arbitrationStatus}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Arbitration Status"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="arbitrationMatterType"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Sub Type
                              </InputLabel>

                              <Select
                                labelId="arbitrationMatterType"
                                id="arbitrationMatterType"
                                name="arbitrationMatterType"
                                value={values?.arbitrationMatterType || null}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName === "Case Sub Type"
                                  )
                                  ?.Children?.map((data, index) => (
                                    <MenuItem
                                      key={index}
                                      value={data?.generalSettingsID}
                                    >
                                      {data?.categoryName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Box>

                            {settings
                              ?.find(
                                (item) => item.categoryName == "Case Sub Type"
                              )
                              ?.Children?.find(
                                (child) => child?.categoryName == "Other"
                              )?.generalSettingsID ==
                              values.arbitrationMatterType && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "33%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <InputLabel
                                  htmlFor="arbitrationMatterTypeDescription"
                                  sx={{ textWrap: "balance" }}
                                >
                                  Arbitration Matter Description
                                </InputLabel>
                                <OutlinedInput
                                  id="arbitrationMatterTypeDescription"
                                  name="arbitrationMatterTypeDescription"
                                  sx={{
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                    textWrap: "balance",
                                  }}
                                  value={
                                    values?.arbitrationMatterTypeDescription ||
                                    null
                                  } // Ensure a default value
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Previous Stage
                              </InputLabel>

                              <Select
                                labelId="previousStage"
                                id="previousStage"
                                name="previousStage"
                                value={values?.previousStage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName ==
                                      "Case Primary Stage Status"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Current Stage
                              </InputLabel>

                              <Select
                                labelId="currentStage"
                                id="currentStage"
                                name="currentStage"
                                value={values?.currentStage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName ==
                                      "Case Primary Stage Status"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Next Stage
                              </InputLabel>

                              <Select
                                labelId="nextStage"
                                id="nextStage"
                                name="nextStage"
                                value={values?.nextStage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName ==
                                      "Case Primary Stage Status"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Previous Hearing Date
                              </Typography>

                              <DatePicker
                                id="previousHearingDate"
                                selected={values?.previousHearingDate}
                                onChange={(date) => {
                                  setFieldValue("previousHearingDate", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Next Hearing Date
                              </Typography>

                              <DatePicker
                                id="nextHearingDate"
                                selected={values?.nextHearingDate}
                                onChange={(date) => {
                                  setFieldValue("nextHearingDate", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Court/Forum
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="courtForum"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.courtForum}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Jurisdiction Type
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="jurisdictionType"
                                name="jurisdictionType"
                                value={values?.jurisdictionType}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Jurisdiction Type"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Dispute Value
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="disputeValue"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.disputeValue}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Cause of Action On
                              </Typography>

                              <DatePicker
                                id="causeOfActionOn"
                                selected={values?.causeOfActionOn}
                                onChange={(date) => {
                                  setFieldValue("causeOfActionOn", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Is the dispute/claim within the Statute of
                                Limitations?
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="IsTheDisputeClaimWithinTheStatuteOfLimitations"
                                name="IsTheDisputeClaimWithinTheStatuteOfLimitations"
                                value={
                                  values?.IsTheDisputeClaimWithinTheStatuteOfLimitations
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Description
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="remarks"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.remarks}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Mode of Communications
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="modeOfCommunications"
                                name="modeOfCommunications"
                                value={values?.modeOfCommunications}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Document Service"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                City / Taluk / Village
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="cityTalukVillage"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.cityTalukVillage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                District
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="district"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.district}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="state"
                                sx={{ textWrap: "balance" }}
                              >
                                State
                              </InputLabel>

                              <Select
                                labelId="state"
                                id="state"
                                name="state"
                                value={values?.state}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find((item) => item.categoryName == "State")
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Country
                              </InputLabel>
                              <OutlinedInput
                                id="country"
                                name="country"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.country}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="contested"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Contested
                              </InputLabel>

                              <Select
                                labelId="contested"
                                id="contested"
                                name="contested"
                                value={values?.contested}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                // htmlFor="complexity"
                                sx={{ textWrap: "balance" }}
                              >
                                Complexity
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="complexity"
                                name="complexity"
                                value={values?.complexity}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) => item.categoryName == "Complexity"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="riskMonetary"
                                sx={{ textWrap: "balance" }}
                              >
                                Risk – Monetary
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="riskMonetary"
                                name="riskMonetary"
                                value={values?.riskMonetary}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Risk – Monetary"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="riskReputation"
                                sx={{ textWrap: "balance" }}
                              >
                                Risk – Reputation
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="riskReputation"
                                name="riskReputation"
                                value={values?.riskReputation}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Risk – Reputation"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="riskRegulatory"
                                sx={{ textWrap: "balance" }}
                              >
                                Risk - Regulatory
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="riskRegulatory"
                                name="riskRegulatory"
                                value={values?.riskRegulatory}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Risk – Regulatory"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>
                          </LocalizationProvider>
                        </Box>
                      </>
                    </AccordionDetails>
                  </Accordion>
                </form>
              );
            }}
          </Formik>
        ) : (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="m-0 "
                  >
                    Case Primary Information
                  </Typography>

                  {window?.edit && (
                    <div>
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={(event) => {
                          event.stopPropagation();
                          if (window.edit) {
                            event.stopPropagation();
                            // resetForm();
                            setEditPrimaryInformation(true);
                          } else {
                            animateCss(
                              "info",
                              "You are not authorized to update"
                            );
                          }
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="CaseNect Number"
                        secondary={rowData?.caseID}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Name"
                        secondary={
                          rowData?.caseName?.trim() !== ""
                            ? rowData?.caseName
                            : rowData?.Claimant && rowData?.Respondent
                              ? `${rowData.Claimant} Vs ${rowData?.Respondent}`
                              : null
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Number"
                        secondary={rowData?.caseNumber}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Status"
                        secondary={
                          <span
                            className={`${
                              rowData?.caseStatusID?.categoryName === "Open"
                                ? "text-success"
                                : rowData?.caseStatusID?.categoryName ===
                                    "On Hold"
                                  ? "text-warning"
                                  : rowData?.caseStatusID?.categoryName ===
                                      "Closed"
                                    ? "text-danger"
                                    : null
                            } fw-semibold`}
                          >
                            {rowData?.caseStatusID?.categoryName ??
                              rowData?.caseStatus}
                          </span>
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Opened On"
                        secondary={
                          rowData?.caseOpenedOn
                            ? trackerFormatDate(new Date(rowData?.caseOpenedOn))
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  {settings
                    ?.find((item) => item.categoryName == "Case Status")
                    ?.Children?.find(
                      (child) => child?.categoryName == "Re-Open"
                    )?.generalSettingsID == rowData.caseStatus && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Re-Opening Reason"
                            secondary={rowData?.reOpeningReason}
                          />
                        </List>
                      </Grid>
                    </>
                  )}

                  {settings
                    ?.find((item) => item.categoryName == "Case Status")
                    ?.Children?.find((child) => child?.categoryName == "Closed")
                    ?.generalSettingsID == rowData.caseStatus && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Case Closed On"
                            secondary={
                              rowData?.caseCloseDate
                                ? trackerFormatDate(
                                    new Date(rowData?.caseCloseDate)
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Case Closing Reason"
                            secondary={rowData?.caseClosingReason?.categoryName}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Case Closing Description"
                            secondary={
                              <span>{rowData?.caseClosingDescription}</span>
                            }
                          />
                        </List>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Priority Level"
                        secondary={
                          <span
                            className={`${
                              rowData?.priorityLevelID?.categoryName === "Low"
                                ? "text-success"
                                : rowData?.priorityLevelID?.categoryName ===
                                    "Medium"
                                  ? "text-warning"
                                  : rowData?.priorityLevelID?.categoryName ===
                                      "High"
                                    ? "text-danger"
                                    : null
                            }  fw-semibold`}
                          >
                            {rowData?.priorityLevelID?.categoryName}
                          </span>
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Manager"
                        secondary={
                          caseManager?.find(
                            (item) => item.id == rowData?.caseManager
                          )?.name
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Administrator"
                        secondary={
                          caseManager?.find(
                            (item) => item.id == rowData?.caseAdministrator
                          )?.name
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Plaintiff /Claimant / Petitioner / Appellant"
                        secondary={
                          rowData?.Plaintiff_Claimant_Petitioner_Appellant
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Legal Representative of Plaintiff /Claimant / Petitioner / Appellant"
                        secondary={rowData?.poaLoaName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Counsel for Plaintiff /Claimant / Petitioner / Appellant"
                        secondary={
                          rowData?.AdvocateForPlaintiff_Claimant_Petitioner_Appellant
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Vakalat by Claimant Counsel Filed"
                        secondary={
                          rowData?.vakalatByClaimantCounselFiled == true
                            ? "Yes"
                            : "No"
                        }
                      />
                    </List>
                  </Grid>
                  {(rowData?.vakalatByClaimantCounselFiled ?? "0") == 1 && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Vakalat by Claimant Counsel Filed On"
                          secondary={
                            rowData?.vakalatByClaimantCounselFiledOn
                              ? trackerFormatDate(
                                  new Date(
                                    rowData?.vakalatByClaimantCounselFiledOn
                                  )
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Defendant / Respondent"
                        secondary={rowData?.Defendant_Respondent}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Legal Representative of Defendant / Respondent"
                        secondary={
                          rowData?.LegalRepresentativeOfDefendantRespondent
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Counsel for Defendant / Respondent"
                        secondary={rowData?.AdvocateForDefendantRespondent}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Vakalat by Respondent Counsel Filed"
                        secondary={
                          rowData?.vakalatByRespondentCounselFiled == true
                            ? "Yes"
                            : "No"
                        }
                      />
                    </List>
                  </Grid>
                  {(rowData?.vakalatByRespondentCounselFiled ?? "0") == 1 && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Vakalat by Respondent Counsel Filed On"
                          secondary={
                            rowData?.vakalatByRespondentCounselFiledOn
                              ? trackerFormatDate(
                                  new Date(
                                    rowData?.vakalatByRespondentCounselFiledOn
                                  )
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Practice Area"
                        secondary={rowData?.PracticeArea?.categoryName}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Type"
                        secondary={rowData?.caseType?.categoryName}
                      />
                    </List>
                  </Grid>
                  {rowData?.caseType?.categoryName
                    ?.toLowerCase()
                    .includes("arbitration") && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitration Status"
                            secondary={
                              rowData?.arbitrationStatusID.categoryName
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Case Sub Type"
                            secondary={
                              rowData?.arbitrationMatterTypeID?.categoryName
                            }
                          />
                        </List>
                      </Grid>
                      {rowData?.arbitrationMatterTypeID?.categoryName ==
                        "Other" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Arbitration Matter Description"
                              secondary={
                                rowData?.arbitrationMatterTypeDescription
                              }
                            />
                          </List>
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Previous Stage"
                        secondary={rowData?.previousStageID?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Current Stage"
                        secondary={rowData?.currentStageID?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Next Stage"
                        secondary={rowData?.nextStageID?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Previous Hearing Date"
                        secondary={
                          rowData?.previousHearingDate
                            ? trackerFormatDate(
                                new Date(rowData?.previousHearingDate)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Next Hearing Date"
                        secondary={
                          rowData?.nextHearingDate
                            ? trackerFormatDate(
                                new Date(rowData?.nextHearingDate)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Court/Forum"
                        secondary={rowData?.CourtForum}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Jurisdiction Type"
                        secondary={rowData?.JurisdictionType?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Dispute Value"
                        secondary={rowData?.DisputeValue}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Cause of Action On"
                        secondary={
                          rowData?.CauseOfActionOn
                            ? trackerFormatDate(
                                new Date(rowData?.CauseOfActionOn)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Is the dispute/claim within the Statute of Limitations?"
                        secondary={
                          rowData?.IsTheDisputeClaimWithinTheStatuteOfLimitations
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Description"
                        secondary={rowData?.remarks}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Mode of Communications"
                        secondary={rowData?.ModeOfCommunications?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="City / Taluk / Village"
                        secondary={rowData?.CityTalukVillage}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="District"
                        secondary={rowData?.District}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="State"
                        secondary={rowData?.state?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Country"
                        secondary={rowData?.Country}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Contested"
                        secondary={rowData?.contested}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Complexity"
                        secondary={
                          <span
                            className={`${
                              rowData?.complexityID?.categoryName === "Low"
                                ? "text-success"
                                : rowData?.complexityID?.categoryName ===
                                    "Medium"
                                  ? "text-warning"
                                  : rowData?.complexityID?.categoryName ===
                                      "High"
                                    ? "text-danger"
                                    : null
                            }  fw-semibold`}
                          >
                            {rowData?.complexityID?.categoryName}
                          </span>
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Risk - Monetary"
                        secondary={
                          <span
                            className={`${
                              rowData?.riskMonetaryID?.categoryName === "Low"
                                ? "text-success"
                                : rowData?.riskMonetaryID?.categoryName ===
                                    "Medium"
                                  ? "text-warning"
                                  : rowData?.riskMonetaryID?.categoryName ===
                                      "High"
                                    ? "text-danger"
                                    : null
                            }  fw-semibold`}
                          >
                            {rowData?.riskMonetaryID?.categoryName}
                          </span>
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Risk - Reputation"
                        secondary={
                          <span
                            className={`${
                              rowData?.riskReputationID?.categoryName === "Low"
                                ? "text-success"
                                : rowData?.riskReputationID?.categoryName ===
                                    "Medium"
                                  ? "text-warning"
                                  : rowData?.riskReputationID?.categoryName ===
                                      "High"
                                    ? "text-danger"
                                    : null
                            }  fw-semibold`}
                          >
                            {rowData?.riskReputationID?.categoryName}
                          </span>
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Risk - Regulatory"
                        secondary={
                          <span
                            className={`${
                              rowData?.riskRegulatoryID?.categoryName === "Low"
                                ? "text-success"
                                : rowData?.riskRegulatoryID?.categoryName ===
                                    "Medium"
                                  ? "text-warning"
                                  : rowData?.riskRegulatoryID?.categoryName ===
                                      "High"
                                    ? "text-danger"
                                    : null
                            }  fw-semibold`}
                          >
                            {rowData?.riskRegulatoryID?.categoryName}
                          </span>
                        }
                      />
                    </List>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
    </Card>
  );
};

export default CasePrimaryInformation;

// material-ui
import { useTheme } from "@mui/material/styles";
import { getCookie } from "../../helpers/cookie";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  const isLogggedIn = getCookie("accessToken");

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Mantis" width="100" />
     *
     */ //
    <>
      {isLogggedIn !== undefined ? (
        <img
          width="95%"
          height="40"
          src={require("../../assets/images/logo/CASENECT_Logo.png")}
          alt=""
        />
      ) : (
        <img
          width="95%"
          height="40"
          src={require("../../assets/images/logo/CASENECT_Logo_login .png")}
          alt=""
        />
      )}
    </>
  );
};

export default Logo;

// project import
import NavCard from "./NavCard";
import Navigation from "./Navigation";
import SimpleBar from "../../../../components/third-party/SimpleBar";
import { color } from "framer-motion";

// ==============================|| DRAWER CONTENT ||============================== //

export default function DrawerContent() {
  return (
    <>
      <SimpleBar
        sx={{
          backgroundColor: "#1677ff",
          "& .simplebar-content": {
            display: "flex",
            flexDirection: "column",
            color: "white",
          },
        }}
      >
        <Navigation />
        {/* <NavCard /> */}
      </SimpleBar>
    </>
  );
}
